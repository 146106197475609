import React, {useState, useEffect, useRef, useContext, PureComponent} from 'react';
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import BattleRoundCountdown from './BattleRoundCountdown';
import SquadAddModal from './SquadAddModal';
import SendMessageModal from './SendMessageModal';
import SelectSquadUpgradeModal from './SelectSquadUpgradeModal';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ToastContainer, toast } from 'react-toastify';
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip";
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size';
import epochDates from "./epochDates";
import ClipLoader from "react-spinners/ClipLoader";
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

let NUM_POINTS_FOR_NO_RACCOON_WIN = 50;

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}


const GET_RACCOON_DETAILS = gql`
  query GetRaccoonDetails($raccoonNumber: Int!) {
    raccoonDetails(raccoonNumber: $raccoonNumber) {
      id
      raccoon_metadata_json
      rank
      tierLeaderboardRank
      squadNum
      battle_points
      holdingPoints
    }
  }
`;

const GET_RACCOON_SQUADS = gql`
  query GetRaccoonSquads($raccoonNumber: Int!) {
    raccoonSquads(raccoonNumber: $raccoonNumber) {
      id
      squad_raccoon_is_in
      squad_raccoon_leads
      raccoons_in_squad_im_in
      raccoons_in_squad_i_lead
      my_raccoon_num
    }
  }
`;

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_RACCOON_EPOCH_BONUSES = gql`
  query GetRaccoonEpochBonuses($raccoonNumber: Int, $startEpoch: Int) {
    raccoonEpochBonuses(raccoonNumber: $raccoonNumber, startEpoch: $startEpoch) {
      raccoon_index
      epoch_bonuses
    }
  }
`;

const GET_RACCOON_BATTLE_POINT_HISTORIES = gql`
  query GetRaccoonBattlePointHistories($raccoonNumber: Int, $startEpoch: Int) {
    raccoonBattlePointHistories(raccoonNumber: $raccoonNumber, startEpoch: $startEpoch) {
      raccoon_index
      history_results
    }
  }
`;

const GET_RACCOON_SQUAD_POINT_HISTORIES = gql`
  query GetRaccoonSquadPointHistories($raccoonNumber: Int, $startEpoch: Int) {
    raccoonSquadPointHistories(raccoonNumber: $raccoonNumber, startEpoch: $startEpoch) {
      raccoon_index
      squad_battle_points
    }
  }
`;

const GET_RACCOON_LEADERBOARD_RANK = gql`
  query GetRaccoonLeaderboardRank($raccoonIndex: Int!) {
    raccoonLeaderboardRank(raccoonIndex: $raccoonIndex) {
      rank
    }
  }
`;

const SQUAD_REMOVE_MY_RACCOON = gql`
  mutation SquadRemoveMyRaccoon($squadNum: Int!, $raccoonNum: Int!, $myWalletAddresses: String!, $isLeaveRequest: String ) {
    squadRemoveMyRaccoon( squadNum: $squadNum, raccoonNum: $raccoonNum, myWalletAddresses: $myWalletAddresses, isLeaveRequest: $isLeaveRequest ) {
      result
      logEntries
      squads
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const GET_SQUAD_BATTLE_MATCHUP = gql`
  query GetSquadBattleMatchup($raccoonIndex: Int!) {
    squadBattleMatchup(raccoonIndex: $raccoonIndex) {
      epoch
      officerIndex
      otherSquadOfficerIndex
      memberRaccoons
      otherSquadMemberRaccoons
      battlePoints
      otherSquadBattlePoints
      numSquadConsumableUpgrades
      numOtherSquadConsumableUpgrades
      squadRaccoonUpgrades
      otherSquadRaccoonUpgrades
      racPairings
    }
  }
`;

const GET_AM_I_IN_SQUAD = gql`
  query GetAmIInSquad($raccoonNumber: Int!) {
    amIInSquad(raccoonNumber: $raccoonNumber) {
      result
    }
  }
`;

const GET_SQUAD_UPGRADES = gql`
  query GetSquadUpgrades($raccoonNumber: Int!, $forOtherSquad: String) {
    squadUpgrades(raccoonNumber: $raccoonNumber, forOtherSquad: $forOtherSquad) {
      result
      upgrades
      forOtherSquad
    }
  }
`;

const GET_OTHER_SQUAD_UPGRADES = gql`
  query GetOtherSquadUpgrades($raccoonNumber: Int!, $forOtherSquad: String) {
    otherSquadUpgrades(raccoonNumber: $raccoonNumber, forOtherSquad: $forOtherSquad) {
      result
      upgrades
      forOtherSquad
    }
  }
`;

const RANDOM_OFFICER_IDS = [8358, 5906, 8845, 9347, 4341, 7229, 6343, 5969, 5754, 7793];

const RANDOM_CAPTAIN_IDS = [8200, 7882, 5607, 8358, 9577, 1833, 6159, 8500];

const RANDOM_NON_OFFICER_IDS = [8605, 9107, 7711, 8891, 9280, 7314, 7947, 6153, 8769, 1638, 9818, 6261, 8592, 7822, 6778, 5001, 8645, 6222, 5800, 4460, 2721, 9935, 5073, 3620, 9548, 8312, 9071, 6874, 9863, 7372, 2003, 1981, 1929, 1877, 1828, 1693, 1652];




let ITEM_COUNT = 10000;
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};
let raccoonsMap = {};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};


const RaccoonPage = ({isMobile}, v2, v3) => {
  
  const { width, height } = useWindowSize();
  const [squadLinesWidth, setSquadLinesWidth] = useState(0);
  const [squadLinesHeight, setSquadLinesHeight] = useState(0);

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);

  const [squadRaccoonIsIn, setSquadRaccoonIsIn] = useState(null);
  const [squadRaccoonLeads, setSquadRacconLeads] = useState(null);
  const [raccoonsInSquadRaccoonIsIn, setRaccoonsInSquadRaccoonIsIn] = useState(null);
  const [raccoonsInSquadRaccoonLeads, setRaccoonsInSquadRaccoonLeads] = useState(null);

  const [squadBattleMatchup, setSquadBattleMatchup] = useState(null);

  const [hideRemoveButton, setHideRemoveButton] = useState(false);

  const [expandedSquadBattlePointsRows, setExpandedSquadBattlePointsRows] = useState([]);

  const [iAmInThisSquad, setIAmInThisSquad] = useState(false);

  const [squadUpgrades, setSquadUpgrades] = useState(null);
  const [otherSquadSquadUpgrades, setOtherSquadSquadUpgrades] = useState(null);

  const infiniteLoaderRef = useRef(null);
  const listRef = useRef(null);
  const [listScrollOffset, setListScrollOffset] = useState(null);
  const [lastIntervalTime, setLastIntervalTime] = useState(Date.now());  
  const [searchRaccoonTextGlobal, setSearchRaccoonTextGlobal] = useState("");
  const [searchRaccoonIndex, setSearchRaccoonIndex] = useState(null);

  // TODO
  const infiniteLoaderRefTierLeaderboard = useRef(null);
  const listRefTierLeaderboard = useRef(null);
  const [listScrollOffsetTierLeaderboard, setListScrollOffsetTierLeaderboard] = useState(null);
  const [lastIntervalTimeTierLeaderboard, setLastIntervalTimeTierLeaderboard] = useState(Date.now());  
  const [searchRaccoonTextGlobalTierLeaderboard, setSearchRaccoonTextGlobalTierLeaderboard] = useState("");
  const [searchRaccoonIndexTierLeaderboard, setSearchRaccoonIndexTierLeaderboard] = useState(null);

  const [showSquadAddModal, setShowSquadAddModal] = useState(false);
  const [isJoinRequest, setIsJoinRequest] = useState(false);

  const [showSendMessageModal, setShowSendMessageModal] = useState(false);

  const [shouldHideAddButton, setShouldHideAddButton] = useState(false);

  const [showSquadApplyUpgradeModal, setShowSquadApplyUpgradeModal] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, team, refetchRaccoonsSquads, globalLucid } = useContext(GlobalStateContext);

  const [expandSquadFAQ, setExpandSquadFAQ] = useState(false);
  const [expandSquadFAQ2, setExpandSquadFAQ2] = useState(false);

  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);

  const [getSquadBattleMatchup, { loading: loadingGetSquadBattleMatchup, error: errorGetSquadBattleMatchup, data: dataGetSquadBattleMatchup, refetch: refetchGetSquadBattleMatchup }] = useLazyQuery(GET_SQUAD_BATTLE_MATCHUP);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [getAmIInSquad, { loading: loadingGetAmIInSquad, error: errorGetAmIInSquad, data: dataGetAmIInSquad, refetch: refetchGetAmIInSquad }] = useLazyQuery(GET_AM_I_IN_SQUAD);

  const [getSquadUpgrades, { loading: loadingGetSquadUpgrades, error: errorGetSquadUpgrades, data: dataGetSquadUpgrades, refetch: refetchGetSquadUpgrades }] = useLazyQuery(GET_SQUAD_UPGRADES);

  const [getOtherSquadUpgrades, { loading: loadingGetOtherSquadUpgrades, error: errorGetOtherSquadUpgrades, data: dataGetOtherSquadUpgrades, refetch: refetchGetOtherSquadUpgrades }] = useLazyQuery(GET_OTHER_SQUAD_UPGRADES);


  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          // setMailboxId(response.setReadMessagesTime.mailboxId);
          getAmIInSquad({variables: {raccoonNumber: parseInt(raccoonNum)}});
          getSquadUpgrades({variables: {raccoonNumber: parseInt(raccoonNum), forOtherSquad: 'false'}});
        }
    }
  });

  const localRefreshWallet = async (parms) => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet();
    }
  }, [state.api]);


  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
        let newWidth = event[0].contentBoxSize[0].inlineSize;
        let newHeight = event[0].contentBoxSize[0].blockSize;
        setSquadLinesWidth(newWidth);
        setSquadLinesHeight(newHeight);
    });

    let elem = document.getElementById("squadLines");
    if (elem) {
      resizeObserver.observe(elem);
    }
  });


useEffect(() => {
  getSquadBattleMatchup({variables: {raccoonIndex: parseInt(raccoonNum)}});
}, []);


useEffect(() => {
  if (dataGetSquadBattleMatchup && dataGetSquadBattleMatchup.squadBattleMatchup) {
    setSquadBattleMatchup(dataGetSquadBattleMatchup.squadBattleMatchup);
    if (dataGetSquadBattleMatchup.squadBattleMatchup.otherSquadOfficerIndex) {
      getOtherSquadUpgrades({variables: {raccoonNumber: parseInt(dataGetSquadBattleMatchup.squadBattleMatchup.otherSquadOfficerIndex), forOtherSquad: 'true'}});
    }
  }
}, [dataGetSquadBattleMatchup]);

  const search = useLocation().search;
  const raccoonNum = new URLSearchParams(search).get("r");

  const [getRaccoonLeaderboardRank, { loading: loadingGetRaccoonLeaderboardRank, error: errorGetRaccoonLeaderboardRank, data: dataGetRaccoonLeaderboarRank, refetch: refetchGetRaccoonLeaderboardRank }] = useLazyQuery(GET_RACCOON_LEADERBOARD_RANK);
  const { data, loading, error } = useQuery(GET_RACCOON_DETAILS, {
    variables: { raccoonNumber: parseInt(raccoonNum) }
  });
  const { data: dataRaccoonEpochBonuses, loading: loadingRaccoonEpochBonuses, error: errorRaccoonEpochBonuses } = useQuery(GET_RACCOON_EPOCH_BONUSES, {
    variables: { raccoonNumber: parseInt(raccoonNum) }
  });
  const { data: dataRaccoonBattlePointHistories, loading: loadingRaccoonBattlePointHistories, error: errorRaccoonBattlePointHistories } = useQuery(GET_RACCOON_BATTLE_POINT_HISTORIES, {
    variables: { raccoonNumber: parseInt(raccoonNum) }
  });
  const { data: dataRaccoonSquadPointHistories, loading: loadingRaccoonSquadPointHistories, error: errorRaccoonSquadPointHistories } = useQuery(GET_RACCOON_SQUAD_POINT_HISTORIES, {
    variables: { raccoonNumber: parseInt(raccoonNum) }
  });
  
  const { data: dataRaccoonSquads, loading: loadingRaccoonSquads, error: errorRaccoonSquads } = useQuery(GET_RACCOON_SQUADS, {
    variables: { raccoonNumber: parseInt(raccoonNum) }
  });



  useEffect(() => {
    if (dataRaccoonSquads && dataRaccoonSquads.raccoonSquads) {
      if (dataRaccoonSquads.raccoonSquads.squad_raccoon_is_in) {
        setSquadRaccoonIsIn(JSON.parse(dataRaccoonSquads.raccoonSquads.squad_raccoon_is_in));
      } 
      if (dataRaccoonSquads.raccoonSquads.squad_raccoon_leads) {
        setSquadRacconLeads(JSON.parse(dataRaccoonSquads.raccoonSquads.squad_raccoon_leads));
      }
      if (dataRaccoonSquads.raccoonSquads.raccoons_in_squad_im_in) {
        setRaccoonsInSquadRaccoonIsIn(JSON.parse(dataRaccoonSquads.raccoonSquads.raccoons_in_squad_im_in));
      }       
      if (dataRaccoonSquads.raccoonSquads.raccoons_in_squad_i_lead) {
        setRaccoonsInSquadRaccoonLeads(JSON.parse(dataRaccoonSquads.raccoonSquads.raccoons_in_squad_i_lead));
      }             
    }
  }, [dataRaccoonSquads])


  useEffect(() => {
    if (dataGetAmIInSquad && dataGetAmIInSquad.amIInSquad) {
      if (dataGetAmIInSquad.amIInSquad.result === 'true') {
        setIAmInThisSquad(true);
      }
    }
  }, [dataGetAmIInSquad]);


  function sortSquadUpgrades(a, b) {
    if (a.increaseMaxRollAmount < b.increaseMaxRollAmount) {
      return 1;
    } else if (a.increaseMaxRollAmount > b.increaseMaxRollAmount) {
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    console.log('dataGetSquadUpgrades are', dataGetSquadUpgrades);
    if (dataGetSquadUpgrades && dataGetSquadUpgrades.squadUpgrades) {
      if (dataGetSquadUpgrades.squadUpgrades.result === 'success' && dataGetSquadUpgrades.squadUpgrades.upgrades) {
        let upgrades = JSON.parse(dataGetSquadUpgrades.squadUpgrades.upgrades);
        upgrades = upgrades.sort(sortSquadUpgrades);
        setSquadUpgrades(upgrades);
      }
    }
  }, [dataGetSquadUpgrades]);

  useEffect(() => {
    if (dataGetOtherSquadUpgrades && dataGetOtherSquadUpgrades.otherSquadUpgrades) {
      if (dataGetOtherSquadUpgrades.otherSquadUpgrades.result === 'success' && dataGetOtherSquadUpgrades.otherSquadUpgrades.upgrades) {
        let upgrades = JSON.parse(dataGetOtherSquadUpgrades.otherSquadUpgrades.upgrades);
        upgrades = upgrades.sort(sortSquadUpgrades);
        setOtherSquadSquadUpgrades(upgrades);
      }
    }
  }, [dataGetOtherSquadUpgrades]);

  const [squadRemoveMyRaccoon, { data: dataSquadRemoveMyRaccoon, loading: loadingSquadRemoveMyRaccoon, error: errorSquadRemoveMyRaccoon }] = useMutation(SQUAD_REMOVE_MY_RACCOON, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideRemoveButton(false)
        }, 400);
        if (response && response.squadRemoveMyRaccoon && response.squadRemoveMyRaccoon.result) {
          if (response.squadRemoveMyRaccoon.result.indexOf('fail') >= 0 || response.squadRemoveMyRaccoon.result.indexOf('Fail') >= 0 ) {
            if (response.squadRemoveMyRaccoon.result === 'fail - raccoons in different wallets') {
              setToastColor('#e08705');
              setTimeout(() => {
                toast("Wait until ceasefire period to remove Raccoons from different wallets");
              }, 200);
            } else {
              setToastColor('#e08705');
              setTimeout(() => {
                toast("Error removing raccoon");
              }, 200);
            }
          } else if (response.squadRemoveMyRaccoon.result === 'success') {
            setTimeout(() => {
              if (response.squadRemoveMyRaccoon && response.squadRemoveMyRaccoon.squads) {
                let newSquads = JSON.parse(response.squadRemoveMyRaccoon.squads);
                if (newSquads && newSquads[0]) {
                  if (newSquads[0].racNum === parseInt(raccoonNum)) {
                      setSquadRaccoonIsIn(newSquads[0].squadImIn);
                      setSquadRacconLeads(newSquads[0].squadILead);
                      setRaccoonsInSquadRaccoonIsIn(newSquads[0].raccoonsInSquadImIn);
                      setRaccoonsInSquadRaccoonLeads(newSquads[0].raccoonsInSquadILead);
                  } else {
                    setSquadRaccoonIsIn(null);
                    setSquadRacconLeads(null);
                    setRaccoonsInSquadRaccoonIsIn(null);
                    setRaccoonsInSquadRaccoonLeads(null);
                  }
                } else {
                  setSquadRaccoonIsIn(null);
                  setSquadRacconLeads(null);
                  setRaccoonsInSquadRaccoonIsIn(null);
                  setRaccoonsInSquadRaccoonLeads(null);
                }

                setToastColor('#14711f');
                setTimeout(() => {
                  toast(`Successfully removed Raccoon`);
                }, 200);
              }
              refetchRaccoonsSquads();
            }, 200);
          }
        }
    }
  });

  let epochBonuses = null;
  if (dataRaccoonEpochBonuses && dataRaccoonEpochBonuses.raccoonEpochBonuses) {
    epochBonuses = JSON.parse(dataRaccoonEpochBonuses.raccoonEpochBonuses.epoch_bonuses);
  }
  let epochsWithBonuses = null;
  if (epochBonuses) {
    epochsWithBonuses = Object.keys(epochBonuses).sort((a, b) => { return b - a });
  }

  let battlePointHistories = null;
  if (dataRaccoonBattlePointHistories && dataRaccoonBattlePointHistories.raccoonBattlePointHistories) {
    battlePointHistories = JSON.parse(dataRaccoonBattlePointHistories.raccoonBattlePointHistories.history_results);
  }
  let epochsWithBattlePointHistories = null;
  if (battlePointHistories) {
    epochsWithBattlePointHistories = Object.keys(battlePointHistories).sort((a, b) => { return b - a });
  }

  let squadPointHistories = null;
  if (dataRaccoonSquadPointHistories && dataRaccoonSquadPointHistories.raccoonSquadPointHistories) {
    squadPointHistories = JSON.parse(dataRaccoonSquadPointHistories.raccoonSquadPointHistories.squad_battle_points);
  }


  const usedSquadUpgradeCallback = async () => {
    if (window && window.location) {
      window.location.reload();
    }
  }

  let raccoon = null;
  if (data && data.raccoonDetails) {
    raccoon = data.raccoonDetails;
  }

  let newTotalSquadPoints = 0;
  let totalBattlePoints = 0;
  if (battlePointHistories) {
    console.log('consider bph', battlePointHistories);
    for (var epoch in battlePointHistories) {
      if (battlePointHistories.hasOwnProperty(epoch)) {
        if (battlePointHistories[epoch][0]) {
          if (battlePointHistories[epoch][0].raccoonPointsAfterBonuses) {
            totalBattlePoints = battlePointHistories[epoch][0].raccoonPointsAfterBonuses;
            if (battlePointHistories[epoch][0].squadPointTotalAsOfThisEpoch) {
              newTotalSquadPoints = battlePointHistories[epoch][0].squadPointTotalAsOfThisEpoch;
            } else {
              newTotalSquadPoints = 0;
            }
          }
        }
      }
    }
  }

  let totalSquadPoints = 0;
  if (squadPointHistories) {
    console.log('squadPointHistories are', squadPointHistories);
    for (var epoch in squadPointHistories) {
      if (squadPointHistories.hasOwnProperty(epoch)) {
        if (squadPointHistories[epoch].squadPointsAfterBonuses) {
          if (squadPointHistories[epoch].squadPointsAfterBonuses > totalSquadPoints) {
            totalSquadPoints = squadPointHistories[epoch].squadPointsAfterBonuses;
          }
        }
      }
    }
  }

  let totalHoldingPoints = 0;
  if (raccoon) {
    totalHoldingPoints = raccoon.holdingPoints;
  }


  let isOfficer = false;
  let raccoonMetadata = null;
  if (raccoon && raccoon.raccoon_metadata_json) {
    raccoonMetadata = JSON.parse(raccoon.raccoon_metadata_json);
    if (raccoonMetadata && raccoonMetadata.attributes && raccoonMetadata.attributes.Profession) {
      if (raccoonMetadata.attributes.Profession === 'Captain' || raccoonMetadata.attributes.Profession === 'General') {
        isOfficer = true;
      }
    }
  }

  let raccoonUpgradesInWallet = null;


  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }


  let iOwnThisRaccoon = false;
  if (raccoonsInWallet && raccoonsInWallet.length > 0) {
    raccoonsInWallet.forEach(rac => {
      let racNum = rac.assetNameAscii.substring(8, rac.assetNameAscii.length);
      if (racNum === raccoonNum) {
        iOwnThisRaccoon = true;
      }
    });
  }

 
  const removeFromSquad = (squadNum, squadMember, isLeaveRequest) => {
    setHideRemoveButton(true);
    squadRemoveMyRaccoon({variables: {squadNum: parseInt(squadNum), raccoonNum: parseInt(squadMember.raccoonNum), myWalletAddresses: 'testAddresses', isLeaveRequest: isLeaveRequest ? 'true' : 'false'}});
  }

  const updateOfficerSquadInfo = (squadInfo) => {
    if (squadInfo.racNum === parseInt(raccoonNum)) {
      if (squadInfo.squadImIn) {
        setSquadRaccoonIsIn(squadInfo.squadImIn);
      } 
      if (squadInfo.squadILead) {
        setSquadRacconLeads(squadInfo.squadILead);
      }
      if (squadInfo.raccoonsInSquadImIn) {
        setRaccoonsInSquadRaccoonIsIn(squadInfo.raccoonsInSquadImIn);
      }       
      if (squadInfo.raccoonsInSquadILead) {
        setRaccoonsInSquadRaccoonLeads(squadInfo.raccoonsInSquadILead);
      }   
    }
  }

  const hideAddButtonOnRaccoonPage = (shouldHide) => {
    setShouldHideAddButton(shouldHide);
  }
  
  
  const renderBattlePointsRow = () => {
    return (
      <div key={'blah'} style={{width: '100%'}}> 
        <div className="global_leaderboard_table_row">
          <div className="global_leaderboard_table_header_cell" style={{width: "4%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="global_leaderboard_table_header_cell_text">some info</div>
          </div>
          <div className="global_leaderboard_table_header_cell" style={{width: "30%", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px'}}>
            some data
          </div>                
          <div className="global_leaderboard_table_header_cell" style={{width: "36%", display: 'flex', justifyContent: 'center'}}>
            <div className="global_leaderboard_table_header_cell_text">More data</div>
          </div>                
          <div className="global_leaderboard_table_header_cell" style={{width: "30%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="global_leaderboard_table_header_cell_text">data here</div>
          </div>                                                
        </div>
        <div className="global_leaderboard_table_row_border_wrapper">
        <div className="global_leaderboard_table_row_border" /></div>
      </div>
    );
  }

  if (!raccoon || !raccoonMetadata) {
    return <div />;
  }

  let quarterWidth = squadLinesWidth / 4;
  let imageWidth = (squadLinesWidth / 4) * 0.85;

  if (isMobile) {
    imageWidth = "70%";
  }


  // let fourthSlotEmpty = Math.floor(Math.random() * 2) + 1;
  let fourthSlotEmpty = 2;


  let squadRaccoons = null;
  if (isOfficer && squadRaccoonLeads) {
    squadRaccoons = raccoonsInSquadRaccoonLeads;
  } else if (!isOfficer && squadRaccoonIsIn) {
    squadRaccoons = raccoonsInSquadRaccoonIsIn;
  }


  const renderSquad = (squad, raccoonsInSquad) => {
    if (!raccoonsInSquad) {
      return <div />
    }

    let squadLeader = null;
    let squadMembers = [];
    raccoonsInSquad.forEach(rac => {
      if (rac.type === 'leader') {
        squadLeader = rac;
      } else {
        squadMembers.push(rac);
      }
    });

    let squadFull = false;
    if (squadMembers && squadMembers.length === 4) {
      squadFull = true;
    }

    return (
      <div className="squad_diagram">
        {!isMobile &&
          <div className="squad_diagram_title">
            SQUAD #{squad.squadNum}
          </div>        
        }


        {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && iOwnThisRaccoon && !isOfficer && (
          <div className="squad_diagram_title">
            <div className="leave_squad_button" onClick={() => removeFromSquad(squad.squadNum, {raccoonNum}, true)}>
              Leave Squad
            </div>
          </div>
        )}

        {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !squadFull && !shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer && (
          <div className="squad_diagram_title">
            <div className="leave_squad_button" onClick={() => {
              setIsJoinRequest(true);
              setShowSquadAddModal(true);
            }}>
              Join Squad
            </div>
          </div>
        )}
        
        <div className="squad_diagram_title">
          <a href={`/raccoon?r=${squadLeader.raccoonNum}`} target="_blank" className={!isMobile ? "squad_diagram_leader_img" : "squad_diagram_leader_img_mobile"}>
            <Tooltip placement="bottom" content={<div className="raccoon_name_tooltip">Raccoon #{squadLeader.raccoonNum}</div>}>
              <img  className="squad_diagram_leader_img_img" src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadLeader.raccoonNum}.png?cb=${Date.now()}`} />
            </Tooltip>
          </a>
        </div>
        
        {!isMobile &&
          <div className="squad_diagram_lines_container">
            <img src="squad_lines.gif" className="squad_diagram_lines_img" id="squadLines" />
          </div>
        }
        
        <div className={!isMobile ? "squad_diagram_title" : "squad_diagram_title_mobile"}>
          <div className={!isMobile ? "squad_diagram_members_row" : "squad_diagram_members_row_mobile"}>
            {squadMembers && squadMembers[0] &&
              <div style={{
                width: `${imageWidth}px`,
                position: !isMobile ? 'absolute' : 'relative',
                left: (width*0.1) - imageWidth/4
              }} 
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                <a href={`/raccoon?r=${squadMembers[0].raccoonNum}`} target="_blank" className={isMobile ? "squad_image_anchor" : undefined}>
                  {!isMobile &&
                    <Tooltip placement="top" content={<div className="raccoon_name_tooltip">Raccoon #{squadMembers[0].raccoonNum}</div>}>
                      <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[0].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                    </Tooltip>                  
                  }
                  {isMobile &&
                    <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[0].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                  }
                </a>
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && iOwnThisRaccoon && isOfficer &&
                  <div className="rdets_squad_remove_container">
                    {!hideRemoveButton &&
                      <div className="rdets_squad_remove_text" onClick={() => removeFromSquad(squad.squadNum, squadMembers[0])}>
                        Remove
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {(!squadMembers || !squadMembers[0]) &&
              <div style ={{
                backgroundColor: '#210e09',
                width: isMobile ? '45vw' : `${imageWidth}px`,
                height: isMobile ? '45vw' : `${imageWidth+5}px`,
                minWidth: isMobile ? '45vw' : undefined,
                minHeight: isMobile ? '45vw' : undefined,
                maxWidth: isMobile ? '45vw' : undefined,
                maxHeight: isMobile ? '45vw' : undefined,
                marginTop: isMobile ? '50px' : undefined,
                position: isMobile? 'relative' : 'absolute',
                border: '3px solid #efdbb8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: !isMobile ? (width*0.1) - imageWidth/4 : undefined,
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div className="connect_button" onClick={() => {
                    setIsJoinRequest(false);
                    setShowSquadAddModal(true);
                  }}>
                    ADD / INVITE
                  </div>
                }
                {!isMobile && shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer && (
                  <div className="squad_diagram_title">
                    <div className="leave_squad_button" onClick={() => {
                      setIsJoinRequest(true);
                      setShowSquadAddModal(true);
                    }}>
                      Join Squad
                    </div>
                  </div>
                )}
                {!isMobile && shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }
              </div>            
            }
            {squadMembers && squadMembers[1] &&
              <div  style={{
                width: `${imageWidth}px`,
                position: !isMobile ? 'absolute' : 'relative',
                left: !isMobile ? (width*0.1) + (width*0.225) - imageWidth/4 : undefined
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                <a href={`/raccoon?r=${squadMembers[1].raccoonNum}`} target="_blank" className={isMobile ? "squad_image_anchor" : undefined}>
                  {!isMobile &&
                    <Tooltip placement="top" content={<div className="raccoon_name_tooltip">Raccoon #{squadMembers[1].raccoonNum}</div>}>
                      <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[1].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                    </Tooltip>                  
                  }
                  {isMobile &&
                    <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[1].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                  }
                </a>
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && iOwnThisRaccoon && isOfficer &&
                  <div className="rdets_squad_remove_container">
                    {!hideRemoveButton &&
                      <div className="rdets_squad_remove_text" onClick={() => removeFromSquad(squad.squadNum, squadMembers[1])}>
                        Remove
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {(!squadMembers || !squadMembers[1]) &&
              <div style ={{
                backgroundColor: '#210e09',
                width: isMobile ? '45vw' : `${imageWidth}px`,
                height: isMobile ? '45vw' : `${imageWidth+5}px`,
                minWidth: isMobile ? '45vw' : undefined,
                minHeight: isMobile ? '45vw' : undefined,
                maxWidth: isMobile ? '45vw' : undefined,
                maxHeight: isMobile ? '45vw' : undefined,
                marginTop: isMobile ? '50px' : undefined,
                position: isMobile? 'relative' : 'absolute',
                border: '3px solid #efdbb8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: !isMobile ? (width*0.1) + (width*0.225) - imageWidth/4 : undefined
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div className="connect_button" onClick={() => {
                    setIsJoinRequest(false);
                    setShowSquadAddModal(true);
                  }}>
                    ADD / INVITE
                  </div>
                }
                {!isMobile && shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer && (
                  <div className="squad_diagram_title">
                    <div className="leave_squad_button" onClick={() => {
                      setIsJoinRequest(true);
                      setShowSquadAddModal(true);
                    }}>
                      Join Squad
                    </div>
                  </div>
                )}
                {!isMobile && shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
              </div>            
            }            
            {squadMembers && squadMembers[2] &&
              <div style={{
                width: `${imageWidth}px`,
                position: !isMobile ? 'absolute' : 'relative',
                left: (width*0.1) + (width*0.475) - imageWidth/4
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                <a href={`/raccoon?r=${squadMembers[2].raccoonNum}`} target="_blank" className={isMobile ? "squad_image_anchor" : undefined}>
                {!isMobile &&
                  <Tooltip placement="top" content={<div className="raccoon_name_tooltip">Raccoon #{squadMembers[2].raccoonNum}</div>}>
                    <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[2].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                  </Tooltip>  
                }
                {isMobile &&
                  <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[2].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                }
                </a>
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && iOwnThisRaccoon && isOfficer &&
                  <div className="rdets_squad_remove_container">
                    {!hideRemoveButton &&
                      <div className="rdets_squad_remove_text" onClick={() => removeFromSquad(squad.squadNum, squadMembers[2])}>
                        Remove
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {(!squadMembers || !squadMembers[2]) &&
              <div style ={{
                backgroundColor: '#210e09',
                width: isMobile ? '45vw' : `${imageWidth}px`,
                height: isMobile ? '45vw' : `${imageWidth+5}px`,
                minWidth: isMobile ? '45vw' : undefined,
                minHeight: isMobile ? '45vw' : undefined,
                maxWidth: isMobile ? '45vw' : undefined,
                maxHeight: isMobile ? '45vw' : undefined,
                marginTop: isMobile ? '50px' : undefined,
                position: isMobile? 'relative' : 'absolute',
                border: '3px solid #efdbb8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: !isMobile ? (width*0.1) + (width*0.475) - imageWidth/4 : undefined
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div className="connect_button" onClick={() => {
                    setIsJoinRequest(false);
                    setShowSquadAddModal(true);
                  }}>
                    ADD / INVITE
                  </div>
                }
                {!isMobile && shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer && (
                  <div className="squad_diagram_title">
                    <div className="leave_squad_button" onClick={() => {
                      setIsJoinRequest(true);
                      setShowSquadAddModal(true);
                    }}>
                      Join Squad
                    </div>
                  </div>
                )}
                {!isMobile && shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
              </div>            
            }                
            {squadMembers && squadMembers[3] &&
              <div style={{
                width: `${imageWidth}px`,
                position: !isMobile ? 'absolute' : 'relative',
                left: !isMobile ? (width*0.1) + (width*0.725) - imageWidth/4 : undefined
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                <a href={`/raccoon?r=${squadMembers[3].raccoonNum}`} target="_blank" className={isMobile ? "squad_image_anchor" : undefined} >
                {!isMobile &&
                  <Tooltip placement="top" content={<div className="raccoon_name_tooltip">Raccoon #{squadMembers[3].raccoonNum}</div>}>
                    <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[3].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                  </Tooltip>                
                }
                {isMobile &&
                  <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadMembers[3].raccoonNum}.png?cb=${Date.now()}`} className={!isMobile ? "squad_diagram_member_img" : "squad_diagram_member_img_mobile"} />
                }
                </a>
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE)&& iOwnThisRaccoon && isOfficer &&
                  <div className="rdets_squad_remove_container">
                    {!hideRemoveButton &&
                      <div className="rdets_squad_remove_text" onClick={() => removeFromSquad(squad.squadNum, squadMembers[3])}>
                        Remove
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {(!squadMembers || !squadMembers[3]) &&
              <div style ={{
                backgroundColor: '#210e09',
                width: isMobile ? '45vw' : `${imageWidth}px`,
                height: isMobile ? '45vw' : `${imageWidth+5}px`,
                minWidth: isMobile ? '45vw' : undefined,
                minHeight: isMobile ? '45vw' : undefined,
                maxWidth: isMobile ? '45vw' : undefined,
                maxHeight: isMobile ? '45vw' : undefined,
                marginTop: isMobile ? '50px' : undefined,
                position: isMobile? 'relative' : 'absolute',
                border: '3px solid #efdbb8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: (width*0.1) + (width*0.725) - imageWidth/4
              }}
              className={isMobile ? 'squad_member_container_mobile' : 'squad_member_container'}
              >
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div className="connect_button" onClick={() => {
                    setIsJoinRequest(false);
                    setShowSquadAddModal(true);
                  }}>
                    ADD / INVITE
                  </div>
                }
                {!isMobile && shouldHideAddButton && iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
                {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !isMobile && !shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer && (
                  <div className="squad_diagram_title">
                    <div className="leave_squad_button" onClick={() => {
                      setIsJoinRequest(true);
                      setShowSquadAddModal(true);
                    }}>
                      Join Squad
                    </div>
                  </div>
                )}
                {!isMobile && shouldHideAddButton && raccoonsInWallet && raccoonsInWallet.length > 0 && !iOwnThisRaccoon && isOfficer &&
                  <div style={{}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }                
              </div>            
            }   

          </div>
        </div>
      </div>   
    )
  }

  const renderEpochBonusesRow = (epoch, epochBonuses, battlePointHistories) => {
    let pointsForThisEpoch = '';
    if (battlePointHistories && battlePointHistories[epoch] && battlePointHistories[epoch][0]) {
      pointsForThisEpoch = battlePointHistories[epoch][0].raccoonPointsAfterBonuses;
    }

    let raccoonPointsAdded = '';
    if (battlePointHistories && battlePointHistories[epoch] && battlePointHistories[epoch][0]) {
      raccoonPointsAdded = battlePointHistories[epoch][0].raccoonPointsAdded;
    }
    

    let bpmAtThisEpoch = 1.0;
    if (battlePointHistories && battlePointHistories[epoch] && battlePointHistories[epoch][0]) {
      bpmAtThisEpoch = battlePointHistories[epoch][0].bpmAtSnapshot;
    }

    if (!epochBonuses) {
      return <div key={`bonusesFor${epoch}`} />
    }

    if (epoch && parseInt(epoch) === 396) {
      return <div key={`bonusesFor${epoch}`} />
    }

    

    let bonuses = epochBonuses[epoch];

    let highestPartnerBonus = null;
    let numBPBonuses = 0;
    if (bonuses) {
      bonuses.forEach(bonus => {
        if (bonus.bonusType === 'partnerProjectPolicyId') {
          if (!highestPartnerBonus) {
            highestPartnerBonus = bonus;
          } else if (bonus.bonusBPMBonus > highestPartnerBonus.bonusBPMBonus) {
            highestPartnerBonus = bonus;
          }
        }
        if (bonus.bonusType === 'upgradeNFT' && bonus.bonusConstantBonus) {
          numBPBonuses++;
        }
      });
    }

    let didConsumableUpgrade = false;
    let numBattlePointBonusesDone = 0;


    let numBPUpgradePoints = 0;

    return (
      <div key={`bonusesFor${epoch}`} style={{width: '100%'}}> 
        <div className="default_table_row">
          <div className="default_table_cell" style={{width: "18%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="default_table_cell_text">
              <div className="default_table_text_row" >{epoch}</div>
              {!isMobile && <div className="default_table_text_row">{epochDates[epoch]}</div>}
            </div>
          </div>
          <div className="default_table_cell" style={{width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px'}}>
          <div className="default_table_cell_text">{(bpmAtThisEpoch*100).toFixed(0)}</div>
          </div>                
          <div className="default_table_cell" style={{width: "42%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="default_table_cell_text">
              {(!bonuses || bonuses.length < 1) && 
                <div className="default_table_text_row">base points</div>
              } 
              {(bonuses && bonuses.length > 0) && bonuses.map((bonus, i) => {
                if (i < 25) {
                  let skip = false;

                  if (bonus.bonusType === 'upgradeNFT' && !bonus.bonusConstantBonus) {
                    if (didConsumableUpgrade) {
                      skip = true;
                    } else {
                      didConsumableUpgrade = true;
                    }
                  }

                  if (!skip) {
                    let bonusDescription = '';
                    let bpmBonus = bonus.bonusBPMBonus;
  
                    if (bonus.bonusType === 'handTrait' || bonus.bonusType === 'wardrobeTrait' || bonus.bonusType === 'headTrait' || bonus.bonusType === 'mouthTrait' || bonus.bonusType === 'eyewearTrait' || bonus.bonusType === 'eyeTrait' || bonus.bonusType === 'partnerProjectPolicyId') {
                      if (bpmBonus === 10) bpmBonus = 1.1;
                      if (bpmBonus === 15) bpmBonus = 1.15;
                      if (bpmBonus === 20) bpmBonus = 1.2;
                    }
                    
                    if (bonus.bonusType === 'handTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusHandTrait} Trait)`;
                    if (bonus.bonusType === 'wardrobeTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusWardrobeTrait} Trait)`;
                    if (bonus.bonusType === 'headTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusHeadTrait} Trait)`;
                    if (bonus.bonusType === 'mouthTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusMouthTrait} Trait)`;
                    if (bonus.bonusType === 'eyewearTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusEyewearTrait} Trait)`;
                    if (bonus.bonusType === 'eyeTrait') bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (${bonus.bonusEyeTrait} Trait)`;
  
                    if (bonus.bonusType === 'partnerProjectPolicyId' && bonus.partnerProjectAssetFingerprint === highestPartnerBonus.partnerProjectAssetFingerprint) {
                      bonusDescription = `x ${parseFloat(bpmBonus).toFixed(2)} (Partner Project Bonus)`;
                    }
  
                    if (bonus.bonusType === 'upgradeNFT' && !bonus.bonusConstantBonus) {
                      if (bonus.bonusAssetName) {
                          bonusDescription = `x ${parseFloat(bonus.bonusBPMBonus).toFixed(2)} (${bonus.bonusAssetName}) `;
                      } else if (bonus.bonusUpgradeType) {
                          bonusDescription = `x ${parseFloat(bonus.bonusBPMBonus).toFixed(2)} (${bonus.bonusUpgradeType}) `;
                      }
                    }
  
                    if (bonus.bonusType === 'syndicateBonus') {
                      bonusDescription = `+ ${parseInt(bonus.bonusConstantBonus)} (Syndicate Bonus)`;
                    }
  
                    if (bonus.bonusType === 'squadBonus') {
                      let squadBonusMultiplier = parseFloat(bonus.bonusBPMBonus).toFixed(2);
                      bonusDescription = `x ${squadBonusMultiplier} (Squad Bonus)`
                    }
  
                    return <div key={`bonus_${i}`} className="default_table_text_row">{bonusDescription}</div>
                  } else {
                    return <div key={`bonus_${i}`} />
                  }
                  
                }
              })}
              {(bonuses && bonuses.length > 0) && bonuses.map((bonus, i) => {
                if (i < 17) {
                  let bonusDescription = '';
                  let bpmBonus = bonus.bonusBPMBonus;


                  if (bonus.bonusType === 'upgradeNFT' && bonus.bonusConstantBonus) {
                    if (bonus.bonusAssetName) {
                        bonusDescription = `+ ${parseInt(bonus.bonusConstantBonus)} (${bonus.bonusAssetName}) `;
                    } else if (bonus.bonusUpgradeType) {
                        bonusDescription = `+ ${parseInt(bonus.bonusConstantBonus)} (${bonus.bonusUpgradeType}) `;
                    }
                  }

                  if (bonus.bonusType === 'trashCan') bonusDescription = `+ ${parseFloat(bonus.bonusConstantBonus)} (Trash Can)`;

                  if (bonus.bonusType === 'syndicateWarBonus') bonusDescription = `+ ${parseFloat(bonus.bonusConstantBonus).toFixed(2)} (Syndicate War Bonus)`;
                  
                  if (bonus.bonusType === 'holdingPoolBonus' && parseFloat(bonus.bonusConstantBonus) > 0.0) bonusDescription = `+ ${parseFloat(bonus.bonusConstantBonus).toFixed(2)} (Holding Pool Bonus)`;

                  let skip = false;
                  if (parseInt(epoch) === 427 && bonus.bonusType === 'upgradeNFT' && bonus.bonusConstantBonus) {
                    if (numBattlePointBonusesDone >= (numBPBonuses / 2)) {
                      skip = true;
                    }
                    numBattlePointBonusesDone++;
                  }

                  if (parseInt(epoch) === 431 && bonus.bonusType === 'upgradeNFT' && bonus.bonusConstantBonus) {
                    if (bonus.added_time.indexOf('T18') >= 0) {
                      skip = true;
                    }
                  }
                  
                  if (!skip) {
                    return <div key={`bonus_${i}`} className="default_table_text_row">{bonusDescription}</div>
                  } else {
                    return <div key={`bonus_${i}`}  />
                  }
                  
                }
              })}
            </div>
          </div>                
          <div className="default_table_cell" style={{width: "20%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="default_table_cell_text">{raccoonPointsAdded.toFixed(2)} ({pointsForThisEpoch.toFixed(2)})</div>
          </div>                                                
        </div>
        <div className="default_table_row_border_wrapper">
          <div className="default_table_row_border" />
        </div>
      </div>
    );
  }


  const renderSquadBattlePointsRow = (history) => {

    let rowExpanded = false;
    expandedSquadBattlePointsRows.forEach(row => {
      if (row.epoch === history.epoch) {
        if (row.expanded) {
          rowExpanded = true;
        }
      }
    });
    
    let officerThumbWidth = width / 17;
    let squadThumbWidth = width / 17;


    let mySquadUpgrades = history.mySquadUpgrades ? JSON.parse(history.mySquadUpgrades) : null;
    let otherSquadUpgrades = history.otherSquadRaccoonsUpgrades ? JSON.parse(history.otherSquadRaccoonsUpgrades) : 0;

    let numMySquadUpgrades = mySquadUpgrades ? mySquadUpgrades.length : 0;
    let numOtherSquadUpgrades = otherSquadUpgrades ? otherSquadUpgrades.length : 0;

    if (numMySquadUpgrades > 5) {
      numMySquadUpgrades = 5;
    }
    if (numOtherSquadUpgrades > 5) {
      numOtherSquadUpgrades = 5;
    }

    let numMyRollsWon = 0;
    let numTheirRollsWon = 0;

    if (parseInt(history.myRoll1) > parseInt(history.theirRoll1)) numMyRollsWon++;
    else if (parseInt(history.theirRoll1) > parseInt(history.myRoll1)) numTheirRollsWon++;
    if (parseInt(history.myRoll2) > parseInt(history.theirRoll2)) numMyRollsWon++;
    else if (parseInt(history.theirRoll2) > parseInt(history.myRoll2)) numTheirRollsWon++;
    if (parseInt(history.myRoll3) > parseInt(history.theirRoll3)) numMyRollsWon++;
    else if (parseInt(history.theirRoll3) > parseInt(history.myRoll3)) numTheirRollsWon++;
    if (parseInt(history.myRoll4) > parseInt(history.theirRoll4)) numMyRollsWon++;
    else if (parseInt(history.theirRoll4) > parseInt(history.myRoll4)) numTheirRollsWon++;
    if (parseInt(history.myRoll5) > parseInt(history.theirRoll5)) numMyRollsWon++;
    else if (parseInt(history.theirRoll5) > parseInt(history.myRoll5)) numTheirRollsWon++;
    if (parseInt(history.myRoll6) > parseInt(history.theirRoll6)) numMyRollsWon++;
    else if (parseInt(history.theirRoll6) > parseInt(history.myRoll6)) numTheirRollsWon++;
    if (parseInt(history.myRoll7) > parseInt(history.theirRoll7)) numMyRollsWon++;
    else if (parseInt(history.theirRoll7) > parseInt(history.myRoll7)) numTheirRollsWon++;
    if (parseInt(history.myRoll8) > parseInt(history.theirRoll8)) numMyRollsWon++;
    else if (parseInt(history.theirRoll8) > parseInt(history.myRoll8)) numTheirRollsWon++;
    if (parseInt(history.myRoll9) > parseInt(history.theirRoll9)) numMyRollsWon++;
    else if (parseInt(history.theirRoll9) > parseInt(history.myRoll9)) numTheirRollsWon++;
    if (parseInt(history.myRoll10) > parseInt(history.theirRoll10)) numMyRollsWon++;
    else if (parseInt(history.theirRoll10) > parseInt(history.myRoll10)) numTheirRollsWon++;

    let mySquadActiveUpgrade = null;
    if (history.mySquadActiveUpgrade) {
      mySquadActiveUpgrade = JSON.parse(history.mySquadActiveUpgrade);
    }

    let otherSquadActiveUpgrade = null;
    if (history.otherSquadActiveUpgrade) {
      otherSquadActiveUpgrade = JSON.parse(history.otherSquadActiveUpgrade);
    }


    return (
      <div key={`bonusesFor${history.epoch}`} style={{width: '100%'}}> 
        <div className="default_table_row">
          <div className="default_table_cell" style={{width: "8%", display: 'flex', justifyContent: 'flex-start'}}>
            <div className="default_table_cell_text">
              <div className="default_table_text_row" >{history.epoch}</div>
            </div>
          </div>
          <div className="default_table_cell" style={{width: "41%", display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center', flex: 1, paddingBottom: '20px'}}>
              <a href={`/raccoon?r=${history.officerIndex}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.officerIndex}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: 1}}>
              {history.memberRaccoons[0] && <a href={`/raccoon?r=${history.memberRaccoons[0]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.memberRaccoons[0]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}}/></a>}
              {history.memberRaccoons[1] && <a href={`/raccoon?r=${history.memberRaccoons[1]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.memberRaccoons[1]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
              {history.memberRaccoons[2] && <a href={`/raccoon?r=${history.memberRaccoons[2]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.memberRaccoons[2]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
              {history.memberRaccoons[3] && <a href={`/raccoon?r=${history.memberRaccoons[3]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.memberRaccoons[3]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
            </div>
          </div>                
          <div className="default_table_cell" style={{width: "41%", display: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <div style={{display: 'flex', flex: 1, paddingBottom: '20px', justifyContent: 'center'}}>
              <a href={`/raccoon?r=${history.otherSquadOfficerIndex}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.otherSquadOfficerIndex}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: 1}}>
              {history.otherSquadMemberRaccoons[0] && <a href={`/raccoon?r=${history.otherSquadMemberRaccoons[0]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.otherSquadMemberRaccoons[0]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}}/></a>}
              {history.otherSquadMemberRaccoons[1] && <a href={`/raccoon?r=${history.otherSquadMemberRaccoons[1]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.otherSquadMemberRaccoons[1]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
              {history.otherSquadMemberRaccoons[2] && <a href={`/raccoon?r=${history.otherSquadMemberRaccoons[2]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.otherSquadMemberRaccoons[2]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
              {history.otherSquadMemberRaccoons[3] && <a href={`/raccoon?r=${history.otherSquadMemberRaccoons[3]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${history.otherSquadMemberRaccoons[3]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${squadThumbWidth}px`, margin: '0px 3px'}} /></a>}
            </div>
          </div>                
          <div className="default_table_cell" style={{width: "10%", display: 'flex', justifyContent: 'flex-end'}}>
            <div className="default_table_cell_text">{history.squadPointsAdded} ({history.squadPointsAfterBonuses})</div>
          </div>                                                
        </div>
        {!rowExpanded &&
          <div className="squad_battle_points_expand_row">
            <div 
              onClick={() => {
                let expandedSquadBattlePointsRowsNew = expandedSquadBattlePointsRows;
                let foundEpoch = false;
                expandedSquadBattlePointsRowsNew.forEach(row => {
                  if (row.epoch === history.epoch) {
                    foundEpoch = true;
                    if (row.expanded) {
                      row.expanded = false;
                    } else {
                      row.expanded = true;
                    }
                  }
                });
                if (!foundEpoch) {
                  expandedSquadBattlePointsRowsNew.push({
                    epoch: history.epoch,
                    expanded: true
                  });
                }
                setExpandedSquadBattlePointsRows([...expandedSquadBattlePointsRowsNew]);
              }}
              style={{cursor: 'pointer'}}
            >
              show details {rowExpanded}
            </div>
          </div>        
        }
        {rowExpanded &&
          <div className="squad_battle_points_expand_row">
            <div 
              onClick={() => {
                let expandedSquadBattlePointsRowsNew = expandedSquadBattlePointsRows;
                let foundEpoch = false;
                expandedSquadBattlePointsRowsNew.forEach(row => {
                  if (row.epoch === history.epoch) {
                    foundEpoch = true;
                    if (row.expanded) {
                      row.expanded = false;
                    } else {
                      row.expanded = true;
                    }
                  }
                });
                if (!foundEpoch) {
                  expandedSquadBattlePointsRowsNew.push({
                    epoch: history.epoch,
                    expanded: false
                  });
                }
                setExpandedSquadBattlePointsRows([...expandedSquadBattlePointsRowsNew]);
              }}
              style={{cursor: 'pointer'}}
            >
              hide details  {rowExpanded}
            </div>
          </div>          
        }
        {rowExpanded &&
          <div className="squad_battle_points_expand_row_expanded">
            <div style={{display: 'flex', flexDirection: 'row', flex: 1, width: '100%'}}>
              <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', alignItems: 'flex-start', fontSize: '15px'}}>
                <div style={{marginBottom: '5px', color: '#e08705'}}>Initial max roll: 100</div>
                {history.mySquadOfficerProfession === 'General' && <div style={{marginBottom: '5px'}}>General in squad: +25 added to possible max roll</div>}
                {history.mySquadOfficerProfession !== 'General' && <div style={{marginBottom: '5px'}}>No General in squad (Generals get +25 added to possible max roll)</div>}
                <div style={{marginBottom: '5px'}}># Consumable upgrades: {numMySquadUpgrades} ({numMySquadUpgrades} x 15 added to possible max roll)</div>
                {mySquadActiveUpgrade && <div style={{marginBottom: '5px'}}>{mySquadActiveUpgrade.upgradeName}: {mySquadActiveUpgrade.increaseMaxRollAmount} added to possible max roll</div>}
                {!mySquadActiveUpgrade && <div style={{marginBottom: '5px'}}>No Squad Potion Applied</div>}
                <div style={{marginBottom: '15px', color: '#e08705'}}>Max roll: {history.myMaxRoll}</div>
              </div>
              <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', alignItems: 'flex-end', fontSize: '15px'}}>
                <div style={{marginBottom: '5px', color: '#e08705'}}>Initial max roll: 100</div>
                {history.otherSquadOfficerProfession === 'General' && <div style={{marginBottom: '5px'}}>General in squad: 25 points added to max roll</div>}
                {history.otherSquadOfficerProfession !== 'General' && <div style={{marginBottom: '5px'}}>No General in squad (Generals get 25 points added to max roll)</div>}
                <div style={{marginBottom: '5px'}}># consumable upgrades: {numOtherSquadUpgrades} ({numOtherSquadUpgrades} x 15 points added to max roll)</div>
                {otherSquadActiveUpgrade && <div style={{marginBottom: '5px'}}>{otherSquadActiveUpgrade.upgradeName}: {otherSquadActiveUpgrade.increaseMaxRollAmount} added to possible max roll</div>}
                {!otherSquadActiveUpgrade && <div style={{marginBottom: '5px'}}>No Squad Potion Applied</div>}
                <div style={{marginBottom: '15px', color: '#e08705'}}>Max roll: {history.theirMaxRoll}</div>
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', marginTop: '25px', color: '#e08705'}}>Roll 1</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll1) > parseInt(history.theirRoll1) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll1) < parseInt(history.theirRoll1) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll1) === parseInt(history.theirRoll1) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll1})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll1})</span> 
                {parseInt(history.theirRoll1) > parseInt(history.myRoll1) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll1) < parseInt(history.myRoll1) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll1) === parseInt(history.myRoll1) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>

            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 2</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll2) > parseInt(history.theirRoll2) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll2) < parseInt(history.theirRoll2) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll2) === parseInt(history.theirRoll2) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll2})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll2})</span> 
                {parseInt(history.theirRoll2) > parseInt(history.myRoll2) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll2) < parseInt(history.myRoll2) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll2) === parseInt(history.myRoll2) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 3</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll3) > parseInt(history.theirRoll3) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll3) < parseInt(history.theirRoll3) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll3) === parseInt(history.theirRoll3) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll3})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll3})</span> 
                {parseInt(history.theirRoll3) > parseInt(history.myRoll3) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll3) < parseInt(history.myRoll3) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll3) === parseInt(history.myRoll3) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 4</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll4) > parseInt(history.theirRoll4) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll4) < parseInt(history.theirRoll4) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll4) === parseInt(history.theirRoll4) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll4})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll4})</span> 
                {parseInt(history.theirRoll4) > parseInt(history.myRoll4) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll4) < parseInt(history.myRoll4) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll4) === parseInt(history.myRoll4) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 5</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll5) > parseInt(history.theirRoll5) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll5) < parseInt(history.theirRoll5) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll5) === parseInt(history.theirRoll5) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll5})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll5})</span> 
                {parseInt(history.theirRoll5) > parseInt(history.myRoll5) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll5) < parseInt(history.myRoll5) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll5) === parseInt(history.myRoll5) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>            


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 6</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll6) > parseInt(history.theirRoll6) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll6) < parseInt(history.theirRoll6) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll6) === parseInt(history.theirRoll6) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll6})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll6})</span> 
                {parseInt(history.theirRoll6) > parseInt(history.myRoll6) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll6) < parseInt(history.myRoll6) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll6) === parseInt(history.myRoll6) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 7</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll7) > parseInt(history.theirRoll7) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll7) < parseInt(history.theirRoll7) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll7) === parseInt(history.theirRoll7) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll7})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll7})</span> 
                {parseInt(history.theirRoll7) > parseInt(history.myRoll7) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll7) < parseInt(history.myRoll7) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll7) === parseInt(history.myRoll7) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 8</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll8) > parseInt(history.theirRoll8) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll8) < parseInt(history.theirRoll8) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll8) === parseInt(history.theirRoll8) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll8})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll8})</span> 
                {parseInt(history.theirRoll8) > parseInt(history.myRoll8) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll8) < parseInt(history.myRoll8) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll8) === parseInt(history.myRoll8) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 9</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll9) > parseInt(history.theirRoll9) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll9) < parseInt(history.theirRoll9) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll9) === parseInt(history.theirRoll9) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll9})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll9})</span> 
                {parseInt(history.theirRoll9) > parseInt(history.myRoll9) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll9) < parseInt(history.myRoll9) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll9) === parseInt(history.myRoll9) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', color: '#e08705'}}>Roll 10</div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-end'}}>
                {parseInt(history.myRoll10) > parseInt(history.theirRoll10) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.myRoll10) < parseInt(history.theirRoll10) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.myRoll10) === parseInt(history.theirRoll10) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
                <span style={{margin: '0px 8px 0px 2px'}}>({history.myRoll10})</span> 
              </div>
              <span style={{margin: '0px 12px 0px 12px'}}>vs</span> 
              <div style={{display: 'flex', flexDirection: 'row', width: '150px', justifyContent: 'flex-start'}}>
                <span style={{margin: '0px 2px 0px 8px'}}>({history.theirRoll10})</span> 
                {parseInt(history.theirRoll10) > parseInt(history.myRoll10) && <span style={{margin: '0px 2px', color: '#0F0'}}>WIN</span>}
                {parseInt(history.theirRoll10) < parseInt(history.myRoll10) && <span style={{margin: '0px 2px', color: '#F00'}}>LOSE</span>}
                {parseInt(history.theirRoll10) === parseInt(history.myRoll10) && <span style={{margin: '0px 2px', color: '#e08705'}}>TIE</span>}
              </div>
            </div>


            <div style={{display: 'flex', flexDirection: 'row', flex: 1, width: '100%', marginTop: '25px'}}>
              <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', alignItems: 'flex-start', fontSize: '15px'}}>
                <div style={{marginBottom: '10px'}}># rolls won: {numMyRollsWon}</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>Base = 20 points earned for each winning roll above</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>+ {history.numT3sMySquad} T3 Raccoons = {parseInt(history.numT3sMySquad) * 3} extra points per winning roll (3 points per T3 Raccoon)</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>+ {history.numT2sMySquad} T2 Raccoons = {parseInt(history.numT2sMySquad) * 2} extra points per winning roll (2 points per T2 Raccoon)</div>
                <div style={{marginBottom: '15px', fontSize: '13px'}}>+ {history.numT1sMySquad} T1 Raccoons = {parseInt(history.numT1sMySquad) * 1} extra points per winning roll (1 point per T1 Raccoon)</div>
                <div style={{marginBottom: '5px', color: '#e08705'}}>Points earned: {numMyRollsWon} x (20 + {parseInt(history.numT3sMySquad) * 3} + {parseInt(history.numT2sMySquad) * 2} + {parseInt(history.numT1sMySquad) * 1}) = {history.squadPointsAdded}</div>
              </div>
              <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', alignItems: 'flex-end', fontSize: '15px'}}>
                <div style={{marginBottom: '10px'}}># rolls won: {numTheirRollsWon}</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>Base = 20 points earned for each winning roll above</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>+ {history.numT3sOtherSquad} T3 Raccoons = {parseInt(history.numT3sOtherSquad) * 3} extra points per winning roll (3 points per T3 Raccoon)</div>
                <div style={{marginBottom: '5px', fontSize: '13px'}}>+ {history.numT2sOtherSquad} T2 Raccoons = {parseInt(history.numT2sOtherSquad) * 2} extra points per winning roll (2 points per T2 Raccoon)</div>
                <div style={{marginBottom: '15px', fontSize: '13px'}}>+ {history.numT1sOtherSquad} T1 Raccoons = {parseInt(history.numT1sOtherSquad) * 1} extra points per winning roll (1 point per T1 Raccoon)</div>
                <div style={{marginBottom: '5px', color: '#e08705'}}>Points earned: {numTheirRollsWon} x (20 + {parseInt(history.numT3sOtherSquad) * 3} + {parseInt(history.numT2sOtherSquad) * 2} + {parseInt(history.numT1sOtherSquad) * 1}) = {history.otherSquadPointsAdded}</div>
              </div>
            </div>



          </div>        
        }        
        <div className="default_table_row_border_wrapper">
          <div className="default_table_row_border" />
        </div>
      </div>
    );
  }






  const renderNewSquadBattlePointsRow = (history, raccoonsInSquad) => {

    if (!raccoonsInSquad) return;

    let squadLeader = null;
    let squadMembers = [];
    raccoonsInSquad.forEach(rac => {
      if (rac.type === 'leader') {
        squadLeader = rac;
      } else {
        squadMembers.push(rac);
      }
    });


    let battleResults = null;
    if (history && history.battleResults) {
      battleResults = JSON.parse(history.battleResults);
    }

    let rowExpanded = false;
    expandedSquadBattlePointsRows.forEach(row => {
      if (row.epoch === history.epoch) {
        if (row.expanded) {
          rowExpanded = true;
        }
      }
    });

    let racPairings = [];
    if (history && history.racPairings) {
      racPairings = JSON.parse(history.racPairings);
    }

    let leftIndex = 0;
    let rightIndex = 1;
    if (racPairings && racPairings[0]) {
      if (racPairings[0][1] === squadLeader.raccoonNum) {
        leftIndex = 1;
        rightIndex = 0;
      }
    }


    let officerThumbWidth = width / 6;
    let squadThumbWidth = width / 7;

    let mySquadUpgrades = history.mySquadUpgrades ? JSON.parse(history.mySquadUpgrades) : [];
    let otherSquadUpgrades = history.otherSquadRaccoonsUpgrades ? JSON.parse(history.otherSquadRaccoonsUpgrades) : [];

    let mySquadSentSupplies = history.mySquadSentSupplies;
    let otherSquadSentSupplies = history.otherSquadSentSupplies;

    let thisSquadUpgrade = null;
    let imgSrc = null;
    let maxRollBoost = 0;
    let maxRollBoostEpochs = 0;
    thisSquadUpgrade = history.mySquadActiveUpgrade ? JSON.parse(history.mySquadActiveUpgrade) : null;
    if (thisSquadUpgrade && thisSquadUpgrade.upgradeName.indexOf('Green Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
      maxRollBoost = 'increase power level by 10';
      maxRollBoostEpochs = 1;
    } else if (thisSquadUpgrade && thisSquadUpgrade.upgradeName.indexOf('Blue Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
      maxRollBoost = 'increase power level by 20';
      maxRollBoostEpochs = 1;
    } else if (thisSquadUpgrade && thisSquadUpgrade.upgradeName.indexOf('Red Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
      maxRollBoost = 'increase power level by 30';
      maxRollBoostEpochs = 1;
    } else if (thisSquadUpgrade && thisSquadUpgrade.upgradeName.indexOf('Purple Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
      maxRollBoost = 'increase power level by 40';
      maxRollBoostEpochs = 1;
    }


    let otherSquadUpgrade = null;
    let otherSquadUpgradeImgSrc = null;
    let otherSquadUpgradeMaxRollBoost = 0;
    let otherSquadUpgradeMaxRollBoostEpochs = 0;
    otherSquadUpgrade = history.otherSquadActiveUpgrade ? JSON.parse(history.otherSquadActiveUpgrade) : null;
    if (otherSquadUpgrade && otherSquadUpgrade.upgradeName.indexOf('Green Squad Potion') > -1) {
      otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
      otherSquadUpgradeMaxRollBoost = 'increase power level by 10';
      otherSquadUpgradeMaxRollBoostEpochs = 1;
    } else if (otherSquadUpgrade && otherSquadUpgrade.upgradeName.indexOf('Blue Squad Potion') > -1) {
      otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
      otherSquadUpgradeMaxRollBoost = 'increase power level by 20';
      otherSquadUpgradeMaxRollBoostEpochs = 1;
    } else if (otherSquadUpgrade && otherSquadUpgrade.upgradeName.indexOf('Red Squad Potion') > -1) {
      otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
      otherSquadUpgradeMaxRollBoost = 'increase power level by 30';
      otherSquadUpgradeMaxRollBoostEpochs = 1;
    } else if (otherSquadUpgrade && otherSquadUpgrade.upgradeName.indexOf('Purple Squad Potion') > -1) {
      otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
      otherSquadUpgradeMaxRollBoost = 'increase power level by 40';
      otherSquadUpgradeMaxRollBoostEpochs = 1;
    }

    let squadPotionPowerLevelBoost = 0;
    if (thisSquadUpgrade) {
      if (thisSquadUpgrade.upgradeName === 'Green Squad Potion') {
        squadPotionPowerLevelBoost = 10;
      } else if (thisSquadUpgrade.upgradeName === 'Blue Squad Potion') {
        squadPotionPowerLevelBoost = 20;
      } else if (thisSquadUpgrade.upgradeName === 'Red Squad Potion') {
        squadPotionPowerLevelBoost = 30;
      } else if (thisSquadUpgrade.upgradeName === 'Purple Squad Potion') {
        squadPotionPowerLevelBoost = 40;
      }
    }

    let otherSquadPotionPowerLevelBoost = 0;
    if (otherSquadUpgrade) {
      if (otherSquadUpgrade.upgradeName === 'Green Squad Potion') {
        otherSquadPotionPowerLevelBoost = 10;
      } else if (otherSquadUpgrade.upgradeName === 'Blue Squad Potion') {
        otherSquadPotionPowerLevelBoost = 20;
      } else if (otherSquadUpgrade.upgradeName === 'Red Squad Potion') {
        otherSquadPotionPowerLevelBoost = 30;
      } else if (otherSquadUpgrade.upgradeName === 'Purple Squad Potion') {
        otherSquadPotionPowerLevelBoost = 40;
      }
    }

    let numMySquadPointsPerWin = 50;
    numMySquadPointsPerWin += history.numT3sMySquad * 30;
    numMySquadPointsPerWin += history.numT2sMySquad * 20;
    numMySquadPointsPerWin += history.numT1sMySquad * 10;

    let numOtherSquadPointsPerWin = 50;
    numOtherSquadPointsPerWin += history.numT3sOtherSquad * 30;
    numOtherSquadPointsPerWin += history.numT2sOtherSquad * 20;
    numOtherSquadPointsPerWin += history.numT1sOtherSquad * 10;

    return (
      <div key={`bonusesFor${history.epoch}`} style={{width: '100%', marginBottom: '80px'}}> 
        <div style={{fontSize: '18px', marginTop: '20px', marginLeft: '10px', color: '#e08706'}}>EPOCH {history.epoch}</div>
        <div className="default_table_row" style={{alignItems: 'flex-start'}}>
          <div className="default_table_cell" style={{width: "12%", display: 'flex', justifyContent: 'center'}}>
            <div className="default_table_cell_text">
              <div style={{textAlign: 'center', fontSize: '16px'}}>
                <div>
                  SENT SUPPLIES ?
                </div>
                <div style={{marginTop: '5px'}}>
                  {mySquadSentSupplies === 'true' && <div style={{color: '#0F0'}}>YES</div>}
                  {mySquadSentSupplies !== 'true' && <div style={{color: '#F00'}}>NO</div>}
                </div>
                <div style={{marginTop: '5px'}}>
                  {mySquadSentSupplies === 'true' && <div style={{fontSize: '14px'}}>(+20 Power Level)</div>}
                  {mySquadSentSupplies !== 'true' && <div style={{fontSize: '14px'}}>&nbsp;</div>}
                </div>
              </div>
              <div className="default_table_text_row" style={{alignItems: 'center', textAlign: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', paddingRight: '8px', marginTop: '50px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {thisSquadUpgrade && (
                    <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                      <img src={imgSrc} className="squad_upgrade_image" style={{width: 'auto', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px'}} />
                      <div style={{marginTop: '10px'}}>
                        {thisSquadUpgrade.upgradeName} Applied
                      </div>
                      <div style={{marginTop: '10px'}}>
                        +{squadPotionPowerLevelBoost} Power Level to all Raccoons
                      </div>
                    </div>
                  )}
                  {!thisSquadUpgrade && (
                    <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                      <div className="squad_upgrade_image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '10px', width: 'auto', maxWidth: '110px', maxHeight: '110px', minWidth: '110px', minHeight: '110px'}}>
                        No Squad Potion Applied
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="default_table_cell" style={{width: "25%", display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', flex: 1, paddingBottom: '20px'}}>
              {/*<a href={`/raccoon?r=${racPairings[0][0]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPairings[0][0]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>*/}
              {racPairings && racPairings.map((racPair, idx) => {
                if (racPair[leftIndex] === 0) {
                  return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div href={`/raccoon?r=${racPair[leftIndex]}`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-4px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}>
                          No Raccoon
                        </div>                     
                      </div>
                  )
                }
                return (
                  <div key={`rpz_${idx}_0`} >
                    {racPair[leftIndex] && (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div style={{textDecoration: 'none', position: 'relative'}}>
                          <a href={`/raccoon?r=${racPair[leftIndex]}`} target="_blank">
                            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPair[leftIndex]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-5px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}/>
                            {renderRaccoonUpgrades(racPair[leftIndex], mySquadUpgrades)}
                          </a>
                        </div>                  
                      </div>
                    )}
                  </div>
                );
              })}         
            </div>
            {/*
            <div className="default_table_cell_text" style={{marginTop: '20px'}}>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>{numMySquadUpgrades} consumable upgrades applied</div>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>on individual raccoons</div>
            </div>
            */}
          </div>   
          <div className="default_table_cell" style={{width: "26%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <div style={{textAlign: 'center', fontSize: '15px', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', paddingRight: '8px'}}>
              {racPairings && racPairings.map((racPair, idx) => {
                let battleResult = battleResults[idx];

                let leftRaccoon = leftIndex === 0 ? battleResult.racAIndex : battleResult.racBIndex;
                let rightRaccoon = leftIndex === 0 ? battleResult.racBIndex : battleResult.racAIndex;

                let leftPowerLevel = leftIndex === 0 ? battleResult.racAPowerLevel : battleResult.racBPowerLevel;
                let rightPowerLevel = leftIndex === 0 ? battleResult.racBPowerLevel : battleResult.racAPowerLevel;

                let leftResultingAttack = leftIndex === 0 ? battleResult.racARoll : battleResult.racBRoll;
                let rightResultingAttack = leftIndex === 0 ? battleResult.racBRoll : battleResult.racARoll;

                if (!leftRaccoon) {
                  leftResultingAttack = 0;
                }

                if (!rightRaccoon) {
                  rightResultingAttack = 0;
                }

                let leftColor = '#e08705';
                if (leftResultingAttack > rightResultingAttack) {
                  leftColor = '#0F0';
                }
                if (leftResultingAttack < rightResultingAttack) {
                  leftColor = '#F00';
                }

                let rightColor = '#e08705';
                if (rightResultingAttack > leftResultingAttack) {
                  rightColor = '#0F0';
                }
                if (rightResultingAttack < leftResultingAttack) {
                  rightColor = '#F00';
                }


                return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`,
                          marginBottom: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'row'
                        }}
                      >
                        <div>
                          <div>
                            Power Level
                          </div>
                          <div style={{marginBottom: '20px'}}>
                            {leftPowerLevel}
                          </div>
                          <div>
                            Resulting Attack
                          </div>
                          <div style={{color: leftColor}}>
                            {leftResultingAttack}
                          </div>                          
                        </div>                        
                        <div>
                          <img src="https://rswebpublic2.s3.amazonaws.com/swords.png" style={{width: '40px', margin: '0px 20px'}} />
                        </div>
                        <div>
                          <div>
                            Power Level
                          </div>
                          <div style={{marginBottom: '15px'}}>
                            {rightPowerLevel}
                          </div>
                          <div>
                            Resulting Attack
                          </div>
                          <div style={{color: rightColor}}>
                            {rightResultingAttack}
                          </div>                          
                        </div>
                      </div>
                );
              })}         
            </div>
          </div>                         
          <div className="default_table_cell" style={{width: "25%", display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', flex: 1, paddingBottom: '20px'}}>
              {/*<a href={`/raccoon?r=${squadBattleMatchup.otherSquadOfficerIndex}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadBattleMatchup.otherSquadOfficerIndex}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>*/}
              {racPairings && racPairings.map((racPair, idx) => {
                if (racPair[rightIndex] === 0) {
                  return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div href={`/raccoon?r=${racPair[rightIndex]}`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-4px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}>
                          No Raccoon
                        </div>                  
                      </div>
                  );
                }
                return (
                  <div key={`rpa_${idx}_0`} >
                    {racPair[rightIndex] && (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '20px'
                        }}
                      >
                        <div style={{textDecoration: 'none', position: 'relative'}}>
                          <a href={`/raccoon?r=${racPair[rightIndex]}`} target="_blank">
                            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPair[rightIndex]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px'}}/>
                            {renderRaccoonUpgrades(racPair[rightIndex], otherSquadUpgrades)}
                          </a>
                        </div>                  
                      </div>
                    )}
                  </div>
                );
              })}    
            </div>
            {/*
            <div className="default_table_cell_text" style={{marginTop: '20px', }}>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>{numOtherSquadUpgrades} consumable upgrades applied</div>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>on individual raccoons</div>
            </div>
            */}
          </div>   
          <div className="default_table_cell" style={{width: "12%", display: 'flex', justifyContent: 'center'}}>
            <div className="default_table_cell_text">
              <div style={{textAlign: 'center', fontSize: '16px'}}>
                <div>
                  SENT SUPPLIES?
                </div>
                <div style={{marginTop: '5px'}}>
                  {otherSquadSentSupplies === 'true' && <div style={{color: '#0F0'}}>YES</div>}
                  {otherSquadSentSupplies !== 'true' && <div style={{color: '#F00'}}>NO</div>}
                </div>
                <div style={{marginTop: '5px'}}>
                  {otherSquadSentSupplies === 'true' && <div style={{fontSize: '14px'}}>(+20 Power Level)</div>}
                  {otherSquadSentSupplies !== 'true' && <div style={{fontSize: '14px'}}>&nbsp;</div>}
                </div>
              </div>
              <div className="default_table_text_row" style={{alignItems: 'center', textAlign: 'center', flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', paddingRight: '8px', marginTop: '50px'}}>
                {otherSquadUpgrade && (
                  <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                    <img src={otherSquadUpgradeImgSrc} className="squad_upgrade_image" style={{width: 'auto', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px'}}/>
                    <div style={{marginTop: '10px'}}>
                      {otherSquadUpgrade.upgradeName} Applied
                    </div>
                    <div style={{marginTop: '10px'}}>
                        +{otherSquadPotionPowerLevelBoost} Power Level to all Raccoons
                      </div>
                  </div>
                )}
                {!otherSquadUpgrade && (
                  <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                    <div className="squad_upgrade_image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '10px', width: 'auto', maxWidth: '100px', maxHeight: '100px', minWidth: '100px', minHeight: '100px'}}>
                      No Squad Potion Applied
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>          
        </div>


        <div style={{display: 'flex', flexDirection: 'row', padding: '0% 5%', width: '100%', color: '#e08706'}}>
          <div 
            key={`rp_total_left`} 
            style={{
              marginBottom: '30px',
              textAlign: 'center',
              padding: '0px',
              border: '0px solid #e08706',
              width: '40%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'         
            }}
          >
              <div style={{marginBottom: '5px'}}># of WINS</div>
              <div>{history.numMySquadWins}</div>
              <div style={{marginTop: '30px', marginBottom: '5px'}}>MINIMUM POINTS PER WIN</div>
              <div>50</div>
              <div style={{marginTop: '15px', marginBottom: '5px'}}>+{history.numT3sMySquad * 30}</div>
              <div style={{}}>({history.numT3sMySquad} T3s in Squad)</div>
              <div style={{marginTop: '8px', marginBottom: '5px'}}>+{history.numT2sMySquad * 20}</div>
              <div style={{}}>({history.numT2sMySquad} T2s in Squad)</div>              
              <div style={{marginTop: '8px', marginBottom: '5px'}}>+{history.numT1sMySquad * 10}</div>
              <div style={{}}>({history.numT1sMySquad} T1s in Squad)</div>    
              <div style={{marginTop: '35px', marginBottom: '5px'}}>FINAL # POINTS PER WIN</div>
              <div>{numMySquadPointsPerWin}</div>    

              <div style={{marginBottom: '5px', marginTop: '55px'}}># of WINS vs "No Raccoon"</div>
              <div>{history.numMySquadNoRaccoonWins} x {NUM_POINTS_FOR_NO_RACCOON_WIN} POINTS</div>
              

          </div>                   
          <div style={{display: 'flex', width: '20%', textAlign: 'center'}}>
            &nbsp;
          </div>
          <div 
            key={`rp_total_right`} 
            style={{
              marginBottom: '30px',
              textAlign: 'center',
              padding: '0px',
              border: '0px solid #e08706',
              width: '40%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
              <div style={{marginBottom: '5px'}}># of WINS</div>
              <div>{history.numOtherSquadWins}</div>
              <div style={{marginTop: '30px', marginBottom: '5px'}}>MINIMUM POINTS PER WIN</div>
              <div>50</div>
              <div style={{marginTop: '15px', marginBottom: '5px'}}>+{history.numT3sOtherSquad * 30}</div>
              <div style={{}}>({history.numT3sOtherSquad} T3s in Squad)</div>
              <div style={{marginTop: '8px', marginBottom: '5px'}}>+{history.numT2sOtherSquad * 20}</div>
              <div style={{}}>({history.numT2sOtherSquad} T2s in Squad)</div>              
              <div style={{marginTop: '8px', marginBottom: '5px'}}>+{history.numT1sOtherSquad * 10}</div>
              <div style={{}}>({history.numT1sOtherSquad} T1s in Squad)</div>    
              <div style={{marginTop: '35px', marginBottom: '5px'}}>FINAL # POINTS PER WIN</div>
              <div>{numOtherSquadPointsPerWin}</div>    

              <div style={{marginBottom: '5px', marginTop: '55px'}}># of WINS vs "No Raccoon"</div>
              <div>{history.numOtherSquadNoRaccoonWins} x {NUM_POINTS_FOR_NO_RACCOON_WIN} POINTS</div>                                  
          </div> 
        </div>
        
        <br />
        <br />



        <div style={{display: 'flex', flexDirection: 'row', padding: '0% 5%', width: '100%', color: '#e08706'}}>
          <div 
            key={`rp_total_left`} 
            style={{
              marginBottom: '30px',
              textAlign: 'center',
              padding: '20px',
              border: '1px solid #e08706',
              width: '40%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'         
            }}
          >
              <div style={{marginBottom: '10px'}}>TOTAL POINTS EARNED</div>
              <div>{history.numMySquadWins} x {numMySquadPointsPerWin} + {history.numMySquadNoRaccoonWins} x {NUM_POINTS_FOR_NO_RACCOON_WIN} = {history.squadPointsAdded}</div>
          </div>                   
          <div style={{display: 'flex', width: '20%', textAlign: 'center'}}>
            &nbsp;
          </div>
          <div 
            key={`rp_total_right`} 
            style={{
              marginBottom: '30px',
              textAlign: 'center',
              padding: '20px',
              border: '1px solid #e08706',
              width: '40%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
              <div style={{marginBottom: '5px'}}>TOTAL POINTS EARNED</div>
              <div>{history.numOtherSquadWins} x {numOtherSquadPointsPerWin} + {history.numOtherSquadNoRaccoonWins} x {NUM_POINTS_FOR_NO_RACCOON_WIN} = {history.otherSquadPointsAdded}</div>
          </div> 
        </div>
        
        <br />
        <br />     
                
      </div>
    );    



  }




  const renderRaccoonUpgrades = (raccoonNum, squadUpgrades) => {
    let upgrade = null;
    if (squadUpgrades && squadUpgrades.length > 0) {
      for (let su of squadUpgrades) {
        if (su.raccoonNum === raccoonNum) {
          upgrade = su;
        }
      }
    }

    if (!upgrade) {
      return <></>;
    }

    let url = null;
    let bonusRoll = 0;

    if (upgrade) {
      switch (upgrade.upgradeType) {
        case 'Milk':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif';
          bonusRoll = 20;
          break;
        case 'Canteen':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
          bonusRoll = 30;
          break;
        case 'Vegebite':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif';
          bonusRoll = 40;
          break;
        case 'Baked Beans':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif';
          bonusRoll = 50;
          break;
        case 'PotionX':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif';  
          bonusRoll = 60;
          break;
        case 'Gummy Raccoon':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif';
          bonusRoll = 80;
          break;
        case 'Mushrooms':
          url = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif';          
          bonusRoll = 100;
          break;
        default: 
          break;
      }
    }

    return (
      <div 
        style={{
          position: 'absolute',
          top: '0px',
          right: '-45px',
        }}
      >
        <Tooltip 
          placement="top" 
          content={
            <div style={{width: '250px', fontSize: '14px'}}>
              <div>{upgrade.upgradeType}: +{bonusRoll} power level</div>
            </div>
          }
        >
          <img src={url} style={{height: '40px'}} />
        </Tooltip>
      </div>
    )
  }


  const renderSquadFAQ = (whichFAQ) => {
    return (
      <div className={`squad_diagram_title rdets_squad_remove_text`} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px'}}>
        <div style={{color: '#efdbb8', marginBottom: '15px', fontSize: '22px'}}>How do Squad Battles work?</div>  
        <div style={{color: '#efdbb8', marginBottom: '15px', fontSize: '20px'}}>Step 1 - 1v1 Raccoon Battles</div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>Raccoons are paired 1v1 against each other. Officers are paired first, then the rest of a squad's Raccoons are ordered from largest Raccoon # to smallest Raccoon # (ie Tier 1 Raccoons, then Tier 2, then Tier3) and paired with a Raccoon in their opponent's squad which is also ordered in this way. Large squads will be paired with other Large squads whenever possible. Small (1 raccoon) squads will likewise be paired against each other.</div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>Each Raccoon has a power level of 100 by default. Captain Raccoons have a power level of 125 and General Raccoons have a power level of 150.</div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>If a Raccoon has a consumable upgrade applied to it for the current epoch, it receives a boost to their power level according to the list below:
          <url>
            <li>Milk: +20 power</li>
            <li>Canteen: +30 power</li>
            <li>Vegebite: +40 power</li>
            <li>Baked Beans: +50 power</li>
            <li>PotionX: +60 power</li>
            <li>Gummy Raccoon: +80</li>
            <li>Mushrooms: +100</li>
          </url>
        </div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>Squad Potions are another mechanism to increase the power level for all of the Raccoons in a squad. The list below describes the effect of each Squad Potion:
          <url>
            <li>Green Squad Potion: +10 power to all Raccoons in squad</li>
            <li>Blue Squad Potion: +20 power to all Raccoons in squad</li>
            <li>Red Squad Potion: +30 power to all Raccoons in squad</li>
            <li>Purple Squad Potion: +40 power to all Raccoons in squad</li>
          </url>
        </div> 
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>One final way to boost the power level of your Raccoons is by sending them supplies. Simply click 'My NFTs' from the pulldown at the top right of this page, then click 'My Squads', and click the large 'Send Supplies' button at the top of the page. Do this once per Epoch to increase the power level of all of your Raccoons in all squads by 20.</div> 
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>Finally, a random number is chosen between half of the power level value, and the full power level of that Raccoon for each of the 2 Raccoons in the 1v1 matchup. The higher number wins.</div>  
        <div style={{color: '#efdbb8', marginBottom: '60px', fontWeight: 'normal', fontSize: '18px'}}>For example, a Captain Raccoon has a power level of 125 by default and a Milk upgrade attached (+20), yielding a power level of 145. A General Raccoon has a default power level of 150 and Mushrooms applied (+100), and a Purple Squad Potion applied (+40), and the owner has Sent Supplies (+20), generating a power level of 310. The Captain Raccoon 'rolls' a random number between 73 and 145, yielding (for example) 80... The General Raccoon 'rolls' a random number between 155 and 310, yielding (for example) 240. Since the General Raccoon has a higher random number, it wins this battle. </div>  
        
        <div style={{color: '#efdbb8', marginBottom: '15px', fontSize: '20px'}}>Step 2 - Adding up the wins</div>  
        <div style={{color: '#efdbb8', marginBottom: '60px', fontWeight: 'normal', fontSize: '18px'}}>We count how many of these 1v1 Matchups you win. If you have a full squad (5 Raccoons) and win every 1v1 Matchup, this number will be 5. Note, if you have 5 Raccoons and your opponent has 3 Raccoons, your 2 extra Raccoons will win by default.</div>                

        <div style={{color: '#efdbb8', marginBottom: '15px', fontSize: '20px'}}>Step 3 - Calculating your final Squad Battle Points</div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>We simply take the number of 1v1 matchups you won from Step 2 and multiply it by 50. This is the # of Squad Battle Points that each of the Raccoons in the squad get for the current epoch. If you won 3 out of 5 1v1 Matchups, each of your Raccoons will get 150 Squad Battle Points for the epoch (see below for how to boost this number).</div>                              
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>However, for every T3 Raccoon in the squad, you get an extra 30 points for each 1v1 Matchup victory. You get an extra 20 points for each T2 Raccoon in the squad, and an extra 10 points for every T1 Raccoon in the squad.</div>  
        <div style={{color: '#efdbb8', marginBottom: '30px', fontWeight: 'normal', fontSize: '18px'}}>If any of this is unclear, simply scroll down to the 'Squad Battle Points' section and observe how this is all calculated for the most recent epoch that your Raccoons fought in (available at the end of the first epoch of each battle round).</div>                              
      </div>      
    )
  }


  const renderNewSquadBattleMatchup = (raccoonsInSquad) => {

    let squadLeader = null;
    let squadMembers = [];
    raccoonsInSquad.forEach(rac => {
      if (rac.type === 'leader') {
        squadLeader = rac;
      } else {
        squadMembers.push(rac);
      }
    });


    let racPairings = [];
    if (squadBattleMatchup && squadBattleMatchup.racPairings) {
      racPairings = JSON.parse(squadBattleMatchup.racPairings);
    }
    

    let leftIndex = 0;
    let rightIndex = 1;
    if (racPairings && racPairings[0]) {
      if (racPairings[0][1] === squadLeader.raccoonNum) {
        leftIndex = 1;
        rightIndex = 0;
      }
    }


    let officerThumbWidth = width / 6;
    let squadThumbWidth = width / 7;

    let mySquadUpgrades = squadBattleMatchup.squadRaccoonUpgrades ? JSON.parse(squadBattleMatchup.squadRaccoonUpgrades) : [];
    let otherSquadUpgrades = squadBattleMatchup.otherSquadRaccoonUpgrades ? JSON.parse(squadBattleMatchup.otherSquadRaccoonUpgrades) : [];

    let thisSquadUpgrade = null;
    let imgSrc = null;
    let maxRollBoost = 0;
    let maxRollBoostEpochs = 0;
    if (squadUpgrades && squadUpgrades[0]) {
      thisSquadUpgrade = squadUpgrades[0];
      if (thisSquadUpgrade.upgradeName.indexOf('Green Squad Potion') > -1) {
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
        maxRollBoost = 'increase power level by 10';
        maxRollBoostEpochs = 1;
      } else if (thisSquadUpgrade.upgradeName.indexOf('Blue Squad Potion') > -1) {
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
        maxRollBoost = 'increase power level by 20';
        maxRollBoostEpochs = 1;
      } else if (thisSquadUpgrade.upgradeName.indexOf('Red Squad Potion') > -1) {
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
        maxRollBoost = 'increase power level by 30';
        maxRollBoostEpochs = 1;
      } else if (thisSquadUpgrade.upgradeName.indexOf('Purple Squad Potion') > -1) {
        imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
        maxRollBoost = 'increase power level by 40';
        maxRollBoostEpochs = 1;
      }
    }
    

    let otherSquadUpgrade = null;
    let otherSquadUpgradeImgSrc = null;
    let otherSquadUpgradeMaxRollBoost = 0;
    let otherSquadUpgradeMaxRollBoostEpochs = 0;
    if (otherSquadSquadUpgrades && otherSquadSquadUpgrades[0]) {
      otherSquadUpgrade = otherSquadSquadUpgrades[0];
      if (otherSquadUpgrade.upgradeName.indexOf('Green Squad Potion') > -1) {
        otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
        otherSquadUpgradeMaxRollBoost = 'increase power level by 10';
        otherSquadUpgradeMaxRollBoostEpochs = 1;
      } else if (otherSquadUpgrade.upgradeName.indexOf('Blue Squad Potion') > -1) {
        otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
        otherSquadUpgradeMaxRollBoost = 'increase power level by 20';
        otherSquadUpgradeMaxRollBoostEpochs = 1;
      } else if (otherSquadUpgrade.upgradeName.indexOf('Red Squad Potion') > -1) {
        otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
        otherSquadUpgradeMaxRollBoost = 'increase power level by 30';
        otherSquadUpgradeMaxRollBoostEpochs = 1;
      } else if (otherSquadUpgrade.upgradeName.indexOf('Purple Squad Potion') > -1) {
        otherSquadUpgradeImgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
        otherSquadUpgradeMaxRollBoost = 'increase power level by 40';
        otherSquadUpgradeMaxRollBoostEpochs = 1;
      }
    }

    let squadPotionPowerLevelBoost = 0;
    if (thisSquadUpgrade) {
      if (squadUpgrades[0].upgradeName === 'Green Squad Potion') {
        squadPotionPowerLevelBoost = 10;
      } else if (squadUpgrades[0].upgradeName === 'Blue Squad Potion') {
        squadPotionPowerLevelBoost = 20;
      } else if (squadUpgrades[0].upgradeName === 'Red Squad Potion') {
        squadPotionPowerLevelBoost = 30;
      } else if (squadUpgrades[0].upgradeName === 'Purple Squad Potion') {
        squadPotionPowerLevelBoost = 40;
      }
    }

    return (
      <div key={`bonusesFor${squadBattleMatchup.epoch}`} style={{width: '100%'}}> 
        <div className="default_table_row" style={{alignItems: 'flex-start'}}>
          <div className="default_table_cell" style={{width: "12%", display: 'flex', justifyContent: 'center'}}>
            <div className="default_table_cell_text">
              <div className="default_table_text_row" style={{alignItems: 'center', textAlign: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', paddingRight: '8px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {thisSquadUpgrade && (
                    <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                      <img src={imgSrc} className="squad_upgrade_image" style={{width: 'auto'}} />
                      <div style={{marginTop: '10px'}}>
                        {squadUpgrades[0].upgradeName} Applied
                      </div>
                      <div style={{marginTop: '10px'}}>
                        +{squadPotionPowerLevelBoost} Power Level to all Raccoons
                      </div>
                    </div>
                  )}
                  {!thisSquadUpgrade && (
                    <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                      <div className="squad_upgrade_image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '10px', width: 'auto'}}>
                        No Squad Potion Applied
                      </div>
                    </div>
                  )}
                </div>
                {!COMPUTE_IN_PROGRESS && iAmInThisSquad && (
                    <div className="squad_diagram_title">
                      {!thisSquadUpgrade && (
                        <div className="leave_squad_button" onClick={() => {
                          setShowSquadApplyUpgradeModal(true);
                        }}>
                          Apply Squad Potion
                        </div>
                      )}
                      {thisSquadUpgrade && (
                        <div className="leave_squad_button" onClick={() => {
                          setShowSquadApplyUpgradeModal(true);
                        }}>
                          Replace Squad Potion
                        </div>
                      )}
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="default_table_cell" style={{width: "34%", display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', flex: 1, paddingBottom: '20px'}}>
              {/*<a href={`/raccoon?r=${racPairings[0][0]}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPairings[0][0]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>*/}
              {racPairings && racPairings.map((racPair, idx) => {
                if (racPair[leftIndex] === 0) {
                  return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div href={`/raccoon?r=${racPair[leftIndex]}`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-4px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}>
                          No Raccoon
                        </div>                     
                      </div>
                  )
                }
                return (
                  <div key={`rpz_${idx}_0`} >
                    {racPair[leftIndex] && (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div style={{textDecoration: 'none', position: 'relative'}}>
                          <a href={`/raccoon?r=${racPair[leftIndex]}`} target="_blank">
                            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPair[leftIndex]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-5px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}/>
                            {renderRaccoonUpgrades(racPair[leftIndex], mySquadUpgrades)}
                          </a>
                        </div>                  
                      </div>
                    )}
                  </div>
                );
              })}              
            </div>
            {/*
            <div className="default_table_cell_text" style={{marginTop: '20px'}}>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>{numMySquadUpgrades} consumable upgrades applied</div>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>on individual raccoons</div>
            </div>
            */}
          </div>   
          <div className="default_table_cell" style={{width: "8%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <div style={{alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', paddingRight: '8px'}}>
              {racPairings && racPairings.map((racPair, idx) => {
                return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`,
                          marginBottom: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img src="https://rswebpublic2.s3.amazonaws.com/swords.png" style={{width: '40px'}} />
                      </div>
                );
              })}        
            </div>
          </div>                         
          <div className="default_table_cell" style={{width: "34%", display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', flex: 1, paddingBottom: '20px'}}>
              {/*<a href={`/raccoon?r=${squadBattleMatchup.otherSquadOfficerIndex}`} style={{textDecoration: 'none'}}><img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${squadBattleMatchup.otherSquadOfficerIndex}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${officerThumbWidth}px`}} /></a>*/}
              {racPairings && racPairings.map((racPair, idx) => {
                if (racPair[rightIndex] === 0) {
                  return (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '30px'
                        }}
                      >
                        <div href={`/raccoon?r=${racPair[rightIndex]}`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{height: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px', marginTop: '-4px', display: 'flex', alignItems: 'center', justifyContent: 'centers', textAlign: 'center'}}>
                          No Raccoon
                        </div>                  
                      </div>
                  );
                }
                return (
                  <div key={`rpa_${idx}_0`} >
                    {racPair[rightIndex] && (
                      <div 
                        key={`rp_${idx}_0`} 
                        style={{
                          marginBottom: '20px'
                        }}
                      >
                        <div style={{textDecoration: 'none', position: 'relative'}}>
                          <a href={`/raccoon?r=${racPair[rightIndex]}`} target="_blank">
                            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racPair[rightIndex]}.png`} className={!isMobile ? "squad_battle_points_nft" : "squad_battle_points_nft"} style={{width: `${idx === 0 ? officerThumbWidth : squadThumbWidth}px`, margin: '0px 3px'}}/>
                            {renderRaccoonUpgrades(racPair[rightIndex], otherSquadUpgrades)}
                          </a>
                        </div>                  
                      </div>
                    )}
                  </div>
                );
              })}    
            </div>
            {/*
            <div className="default_table_cell_text" style={{marginTop: '20px', }}>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>{numOtherSquadUpgrades} consumable upgrades applied</div>
              <div className="default_table_text_row" style={{alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>on individual raccoons</div>
            </div>
            */}
          </div>   
          <div className="default_table_cell" style={{width: "12%", display: 'flex', justifyContent: 'center'}}>
            <div className="default_table_cell_text">
              <div className="default_table_text_row" style={{alignItems: 'center', textAlign: 'center', flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', paddingRight: '8px'}}>
                {otherSquadUpgrade && (
                  <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                    <img src={otherSquadUpgradeImgSrc} className="squad_upgrade_image" style={{width: 'auto'}}/>
                    <div style={{marginTop: '10px'}}>
                      {otherSquadUpgrade.upgradeName} Applied
                    </div>
                    
                  </div>
                )}
                {!otherSquadUpgrade && (
                  <div className="default_table_text_row" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '15px'}}>
                    <div className="squad_upgrade_image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '10px', width: 'auto'}}>
                      No Squad Potion Applied
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>          
        </div>
        <div onClick={() => setExpandSquadFAQ(!expandSquadFAQ)} style={{width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
          <div className={`squad_diagram_title rdets_squad_remove_text`} style={{marginBottom: '15px'}}>
            Click here to read the Squad Battle FAQ
          </div>
          {expandSquadFAQ && renderSquadFAQ(1)}
        </div>
      </div>
    );
  }

  
  let mySquadLabel = 'THIS SQUAD';
  if (iAmInThisSquad) {
    mySquadLabel = 'MY SQUAD';
  }

  let theirSquadLabel = 'OTHER SQUAD';
  if (iAmInThisSquad) {
    theirSquadLabel = 'THEIR SQUAD';
  }

  return (
    <>
      <div className="home_wrapper" style={{marginBottom: '130px'}}>

        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />
        
        <div className="logo_container">
          <img src="rs_logo_banner.png" className={!isMobile ? "logo_banner_raccoon_details" : "logo_banner_raccoon_details_mobile"} onClick={() => window.location = "/"} /><br/>
        </div>

        <div className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"} style={{marginTop: isMobile ? '50px' : undefined}}>
          <div className="leaderboard_title_row">
              <div className={!isMobile ? "global_leaderboard_title_text" : "global_leaderboard_title_text_mobile"} style={{fontSize: isMobile ? '30px' : undefined}}>
                  RACCOON #{raccoonMetadata.Index} DETAILS
              </div>      
              {raccoonsInWallet && raccoonsInWallet.length > 0 &&
               <div className="rdets_send_message_container" onClick={() => {
                  setShowSendMessageModal(true);
                }}>
                    Send Message
                </div>
              }
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>

        <div className={!isMobile ? "rdets_top_box": "rdets_top_box_mobile"}>
          <div className={!isMobile ? "rdets_top_box_left_cell" : "rdets_top_box_left_cell"}>
            <div className={!isMobile ? "rdets_top_box_left_inner" : "rdets_top_box_left_inner_mobile"}>
              <img src="racdetailstopgraphic.gif" className="rdets_large_img_decoration" />
              <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${raccoonNum}.png?cb=${Date.now()}`} className="rdets_large_img" />
              <img src="racdetailsbottomgraphic.gif" className="rdets_large_img_decoration" />
            </div>
          </div>
          <div className={!isMobile ? "rdets_top_box_right_cell" : "rdets_top_box_right_cell_mobile"}>
            <div className={!isMobile ? "rdets_points_row" : "rdets_points_row_mobile"} style={{marginTop: '30px', display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center'}}>
              <div className={!isMobile ? "rdets_total_pts_box_container" : "rdets_total_pts_box_container_mobile"}>
                <div className="rdets_total_pts_box">
                  <div className="rdets_total_pts_box_inner">
                    <div className="rdets_total_pts_label">
                      BATTLE POINTS
                    </div>
                    <div className="rdets_total_pts_value">
                      {raccoon.battle_points ? raccoon.battle_points.toFixed(0) : 0}
                    </div>
                  </div>
                  {!isMobile &&(
                    <Tooltip placement="top" content={<div className="holding_points_tooltip" style={{width: '300px'}}>Includes Raccoon Points + Squad Points + Holing Pool Points</div>}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '-30px', top: '-30px', color: '#e08705', fontSize: '12px', backgroundColor: '#413e38', borderRadius: '50%', width: '21px', height: '21px', padding: '6px'}}>
                        <div style={{}}>?</div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>


            <div style={{marginTop: '30px', display: 'flex', flexDirection: isMobile ? 'column' : 'row', flex: 1, width: '100%', justifyContent: 'center'}}>
              <div className={!isMobile ? "rdets_holding_pts_box_container" : "rdets_holding_pts_box_container_mobile"}>
                  <div className="rdets_holding_pts_box">
                    <div className="rdets_holding_pts_box_inner">
                      <div className="rdets_holding_pts_label">
                        RACCOON POINTS
                      </div>
                      <div className="rdets_holding_pts_value">
                        {totalBattlePoints.toFixed(0)}
                      </div>
                    </div>
                  </div>
                  {!isMobile &&(
                    <Tooltip placement="top" content={<div className="wider_tooltip">These points are earned by the individual Raccoon based on winning trait selections and applying consumables</div>}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: -8, top: -8, color: '#000', fontSize: '12px', backgroundColor: '#e08705', borderRadius: '50%', width: '21px', height: '21px', padding: '6px'}}>
                        <div style={{}}>?</div>
                      </div>
                    </Tooltip>
                  )}
              </div>

              <div style={{display: 'flex', flex: 0.05, alignItems: 'center', justifyContent: 'center', color: '#e08705', fontSize: 30, margin: isMobile ? '10px' : '0px'}}>
                    +
              </div>

              <div className={!isMobile ? "rdets_holding_pts_box_container" : "rdets_holding_pts_box_container_mobile"}>
                  <div className="rdets_holding_pts_box">
                    <div className="rdets_holding_pts_box_inner">
                      <div className="rdets_holding_pts_label">
                        SQUAD POINTS
                      </div>
                      <div className="rdets_holding_pts_value">
                        {newTotalSquadPoints.toFixed(0)}
                      </div>
                    </div>
                  </div>
                  {!isMobile &&(
                    <Tooltip placement="top" content={<div className="wider_tooltip">These points are earned by winning squad battles (details below)</div>}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: -8, top: -8, color: '#000', fontSize: '12px', backgroundColor: '#e08705', borderRadius: '50%', width: '21px', height: '21px', padding: '6px'}}>
                        <div style={{}}>?</div>
                      </div>
                    </Tooltip>
                  )}
              </div>

              <div style={{display: 'flex', flex: 0.05, alignItems: 'center', justifyContent: 'center', color: '#e08705', fontSize: 30, margin: isMobile ? '10px' : '0px'}}>
                    +
              </div>

              <div className={!isMobile ? "rdets_holding_pts_box_container" : "rdets_holding_pts_box_container_mobile"}>
                  <div className="rdets_holding_pts_box">
                    <div className="rdets_holding_pts_box_inner">
                      <div className="rdets_holding_pts_label">
                        HOLDING POOL
                      </div>
                      <div className="rdets_holding_pts_value">
                        {totalHoldingPoints}
                      </div>
                    </div>
                  </div>
                  {!isMobile &&(
                    <Tooltip placement="top" content={<div className="holding_points_tooltip">These points will be added to the Raccoon's Battle Points if the Raccoon remains in the wallet</div>}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: -8, top: -8, color: '#000', fontSize: '12px', backgroundColor: '#e08705', borderRadius: '50%', width: '21px', height: '21px', padding: '6px'}}>
                        <div style={{}}>?</div>
                      </div>
                    </Tooltip>
                  )}
              </div>
            </div>


            <div className={!isMobile ? "rdets_traits_box" : "rdets_traits_box_mobile"}>
              <div className="rdets_traits_row">
                <div className="rdets_traits_title">
                  TRAITS
                </div>
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Leaderboard Rank
                  </div>
                  <div className="rdets_traits_value">
                    {raccoon.rank}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Tier Leaderboard Rank
                  </div>
                  <div className="rdets_traits_value">
                    {raccoon.tierLeaderboardRank}
                  </div>
                </div>  
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Syndicate
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Syndicate}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Genus
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Genus}
                  </div>
                </div>                
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Left Hand
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes['Left Hand']}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Right Hand
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes['Right Hand']}
                  </div>
                </div>                
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Wardrobe
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Wardrobe}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Head
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Head}
                  </div>
                </div>                
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Eyes
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Eyes}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Eyewear
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Eyewear}
                  </div>
                </div>                
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Mouth
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Mouth ? raccoonMetadata.attributes.Mouth : 'None'}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Nails
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Nails}
                  </div>
                </div>                
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Profession
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Profession}
                  </div>
                </div>  
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Base Point Multiplier
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes['Base Point Multiplier']}
                  </div>
                </div>              
              </div>

              <div className="rdets_traits_row">
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Ring
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Ring ? raccoonMetadata.attributes.Ring : "None"}
                  </div>
                </div>
                <div className="rdets_traits_field">
                  <div className="rdets_traits_label">
                    Wings
                  </div>
                  <div className="rdets_traits_value">
                    {raccoonMetadata.attributes.Wings ? raccoonMetadata.attributes.Wings : "None"}
                  </div>
                </div>                
              </div>              

            </div>
          </div>
        </div>
        

        <div className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"}>
          <div className="leaderboard_title_row">
            {!isMobile &&
              <div className="global_leaderboard_title_text">
                POINTS THIS BATTLE ROUND
              </div>      
            }
            {isMobile &&
              <div className="global_leaderboard_title_text" style={{fontSize: '34px', justifyContent: 'center', display: 'flex', width: '100%'}} >
                POINTS THIS ROUND
              </div>      
            }              
          </div>
        </div>

        <div className={!isMobile ? "black_box_light_border" : "black_box_light_border_mobile"}>
          <div className={!isMobile ? "black_box_light_border_inner" : "black_box_light_border_inner_mobile"}>
            <div className="global_leaderboard_table">
              <div className="global_leaderboard_table_header">
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "18%", display: 'flex', justifyContent: 'flex-start'}}>
                    EPOCH
                  </div>
                }
                {isMobile &&
                  <div className="regular_table_header" style={{width: "16%", display: 'flex', justifyContent: 'flex-start'}}>
                    EPOCH
                  </div>
                }
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "20%", display: 'flex', justifyContent: 'center'}}>
                    POINT BASE
                  </div>                 
                }
                {isMobile &&
                  <div className="regular_table_header" style={{width: "25%", display: 'flex', justifyContent: 'center'}}>
                    POINT BASE
                  </div>                 
                }                               
                {!isMobile && 
                  <div className="regular_table_header" style={{width: "42%", display: 'flex', justifyContent: 'flex-start'}}>
                    BOOSTS
                  </div>  
                }
                {isMobile && 
                  <div className="regular_table_header" style={{width: "31%", display: 'flex', justifyContent: 'flex-start'}}>
                    BOOSTS
                  </div>  
                }
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "20%", display: 'flex', justifyContent: 'flex-end'}}>
                    POINTS (TOTAL)
                  </div>                                                                
                }              
                {isMobile &&
                  <div className="regular_table_header" style={{width: "28%", display: 'flex', justifyContent: 'flex-end'}}>
                    POINTS (TOTAL)
                  </div>                                                                
                }   
              </div>
            </div>
            <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
            {epochsWithBattlePointHistories && epochsWithBattlePointHistories.map(epoch => {
              return renderEpochBonusesRow(epoch, epochBonuses, battlePointHistories)
            })}
            {/*!team && (
              <div style={{width: '100%', color: '#efddb7', fontSize: '30px', textAlign: 'center', marginTop: '30px', marginBottom: isMobile ? '30px' : undefined}}>
                coming soon
              </div>
            )*/}
          </div>
        </div>


        <div className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"}>
          <div className="leaderboard_title_row">
            {!isMobile &&
              <div className="global_leaderboard_title_text" >
                SQUAD
              </div>      
            }            
            {isMobile &&
              <div className="global_leaderboard_title_text" style={{fontSize: '34px', justifyContent: 'center', display: 'flex', width: '100%'}} >
                <>
                  <span>SQUAD</span>
                  {isOfficer && squadRaccoonLeads && (<span>#{squadRaccoonLeads.squadNum}</span>)} 
                  {!isOfficer && squadRaccoonIsIn && (<span>#{squadRaccoonIsIn.squadNum}</span>)}
                </>
              </div>      
            }
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>

        {isOfficer && !squadRaccoonLeads && 
          <div className="rdets_not_a_squad_member_or_leader">Raccoon #{raccoonNum} does not lead a squad.</div>
        }
        {!isOfficer && !squadRaccoonIsIn && (
          <>
            <div className="rdets_not_a_squad_member_or_leader">Raccoon #{raccoonNum} is not in a squad.</div>
            {iOwnThisRaccoon && <div className="rdets_not_a_squad_member_or_leader">To join a squad, click the Raccoon Syndicate logo above to return to the homepage. Then, click a Captain or General from the leaderboard and click the 'Join Squad' button.</div>}
          </>
        )}

        {!isMobile && isOfficer && squadRaccoonLeads && renderSquad(squadRaccoonLeads, raccoonsInSquadRaccoonLeads)}
        {!isMobile && !isOfficer && squadRaccoonIsIn && renderSquad(squadRaccoonIsIn, raccoonsInSquadRaccoonIsIn)}
        
        {isMobile && isOfficer && squadRaccoonLeads && renderSquad(squadRaccoonLeads, raccoonsInSquadRaccoonLeads)}
        {isMobile && !isOfficer && squadRaccoonIsIn && renderSquad(squadRaccoonIsIn, raccoonsInSquadRaccoonIsIn)}



        <div 
          className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"}
          style={{
            marginTop: (squadRaccoonLeads || squadRaccoonIsIn) ? '460px' : undefined
          }}
        >
          <div className="leaderboard_title_row">
            {!isMobile &&
              <div className="global_leaderboard_title_text">
                UPCOMING SQUAD BATTLE MATCHUP
              </div>      
            }
            {isMobile &&
              <div className="global_leaderboard_title_text" style={{fontSize: '34px', justifyContent: 'center', display: 'flex', width: '100%'}} >
                UPCOMING SQUAD BATTLE MATCHUP
              </div>      
            }              
          </div>
        </div>
     


        <div className={!isMobile ? "black_box_light_border" : "black_box_light_border_mobile"}>
          <div className={!isMobile ? "black_box_light_border_inner" : "black_box_light_border_inner_mobile"}>
          {(!isOfficer && !squadRaccoonIsIn) && !squadBattleMatchup && (
              <div className="global_leaderboard_table" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#efdbb8'}}>
                Raccoon #{raccoonNum} is not in a squad.
              </div>
            )}
            {(squadRaccoonLeads || squadRaccoonIsIn) && !squadBattleMatchup && (
              <div className="global_leaderboard_table" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#efdbb8'}}>
                Squad matchup will be available when the next Battle Round starts
              </div>
            )}
            {squadBattleMatchup && (
                <div className="global_leaderboard_table" style={{flexDirection: 'column'}}>
                  <div className="global_leaderboard_table_header">
                    {!isMobile &&
                      <div className="regular_table_header" style={{width: "12%", display: 'flex', textAlign: 'center', justifyContent: 'flex-start'}}>
                        MY SQUAD
                      </div>
                    }
                    {isMobile &&
                      <div className="regular_table_header" style={{width: "12%", display: 'flex', textAlign: 'center', justifyContent: 'flex-start'}}>
                        MY SQUAD
                      </div>
                    }
                    {!isMobile &&
                      <div className="regular_table_header" style={{width: "34%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        {mySquadLabel}
                        {squadBattleMatchup && <div style={{marginLeft: '10px'}}>({squadBattleMatchup.battlePoints} points so far)</div>}
                      </div>                 
                    }
                    {isMobile &&
                      <div className="regular_table_header" style={{width: "34%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        {mySquadLabel}
                        {squadBattleMatchup && <div>({squadBattleMatchup.battlePoints} points so far)</div>}
                      </div>                 
                    }                               
                    {!isMobile &&
                      <div className="regular_table_header" style={{width: "8%", display: 'flex', justifyContent: 'flex-start'}}>
                        &nbsp;
                      </div>
                    }
                    {isMobile &&
                      <div className="regular_table_header" style={{width: "8%", display: 'flex', justifyContent: 'flex-start'}}>
                        &nbsp;
                      </div>
                    } 
                    {!isMobile && 
                      <div className="regular_table_header" style={{width: "34%", display: 'flex', flexDirection: 'column', alignItems: 'center',  justifyContent: 'center'}}>
                        {theirSquadLabel}
                        {squadBattleMatchup && <div style={{marginLeft: '10px'}}>({squadBattleMatchup.otherSquadBattlePoints} points so far)</div>}
                      </div>  
                    }
                    {isMobile && 
                      <div className="regular_table_header" style={{width: "34%", display: 'flex', flexDirection: 'column', alignItems: 'center',  justifyContent: 'center'}}>
                        {theirSquadLabel}
                        {squadBattleMatchup && <div style={{marginLeft: '10px'}}>({squadBattleMatchup.otherSquadBattlePoints} points so far)</div>}
                      </div>  
                    }
                    {!isMobile &&
                      <div className="regular_table_header" style={{width: "12%", display: 'flex', textAlign: 'center', justifyContent: 'flex-start'}}>
                        THEIR SQUAD
                      </div>
                    }
                    {isMobile &&
                      <div className="regular_table_header" style={{width: "12%", display: 'flex', textAlign: 'center', justifyContent: 'flex-start'}}>
                        THEIR SQUAD
                      </div>
                    }                    
                </div>
              <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
                {squadBattleMatchup && renderNewSquadBattleMatchup(squadRaccoons)}
            </div>
            )}
          </div>
        </div>
        





        <div 
          className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"}
          style={{
            marginTop: (squadRaccoonLeads || squadRaccoonIsIn) ? '550px' : undefined
          }}
        >
          <div className="leaderboard_title_row">
            {!isMobile &&
              <div className="global_leaderboard_title_text">
                SQUAD BATTLE POINTS
              </div>      
            }
            {isMobile &&
              <div className="global_leaderboard_title_text" style={{fontSize: '34px', justifyContent: 'center', display: 'flex', width: '100%'}} >
                SQUAD BATTLE POINTS
              </div>      
            }              
          </div>
        </div>



{/*
        <div className={!isMobile ? "black_box_light_border" : "black_box_light_border_mobile"}>
          <div className={!isMobile ? "black_box_light_border_inner" : "black_box_light_border_inner_mobile"}>
            <div className="global_leaderboard_table" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#efdbb8'}}>
              available at the beginning of epoch 423
            </div>
          </div>
        </div>
*/}


        <div className={!isMobile ? "black_box_light_border" : "black_box_light_border_mobile"}>
          <div className={!isMobile ? "black_box_light_border_inner" : "black_box_light_border_inner_mobile"}>
            <div className="global_leaderboard_table">
              <div className="global_leaderboard_table_header">
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "8%", display: 'flex', justifyContent: 'flex-start'}}>
                    THIS SQUAD
                  </div>
                }
                {isMobile &&
                  <div className="regular_table_header" style={{width: "8%", display: 'flex', justifyContent: 'flex-start'}}>
                    THIS SQUAD
                  </div>
                }
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "41%", display: 'flex', justifyContent: 'center'}}>
                    THIS SQUAD
                  </div>                 
                }
                {isMobile &&
                  <div className="regular_table_header" style={{width: "41%", display: 'flex', justifyContent: 'center'}}>
                    THIS SQUAD
                  </div>                 
                }                               
                {!isMobile && 
                  <div className="regular_table_header" style={{width: "41%", display: 'flex', justifyContent: 'center'}}>
                    OTHER SQUAD
                  </div>  
                }
                {isMobile && 
                  <div className="regular_table_header" style={{width: "41%", display: 'flex', justifyContent: 'center'}}>
                    OTHER SQUAD
                  </div>  
                }
                {!isMobile &&
                  <div className="regular_table_header" style={{width: "10%", display: 'flex', justifyContent: 'flex-end'}}>
                    OTHER SQUAD
                  </div>                                                                
                }              
                {isMobile &&
                  <div className="regular_table_header" style={{width: "10%", display: 'flex', justifyContent: 'flex-end'}}>
                    OTHER SQUAD
                  </div>                                                                
                }   
              </div>
            </div>
            <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
            {squadPointHistories && squadPointHistories.map(history => {
              return renderNewSquadBattlePointsRow(history, squadRaccoons)
            })}

          <div onClick={() => setExpandSquadFAQ2(!expandSquadFAQ2)} style={{width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <div className={`squad_diagram_title rdets_squad_remove_text`} style={{marginBottom: '15px', marginTop: '20px'}}>
              Click here to read the Squad Battle FAQ
            </div>
            {expandSquadFAQ2 && renderSquadFAQ(2)}
          </div>   

          </div>
          
        </div>



        <SquadAddModal show={showSquadAddModal} isJoinRequest={isJoinRequest} officerRaccoonNum={raccoonNum} squadRaccoonLeads={squadRaccoonLeads} updateOfficerSquadInfo={updateOfficerSquadInfo} hideAddButtonOnRaccoonPage={hideAddButtonOnRaccoonPage}
          close={() => {
            setIsJoinRequest(false);
            setShowSquadAddModal(false);
          }}
        />

        <SendMessageModal show={showSendMessageModal} destRaccoonNum={raccoonNum} raccoonsInWallet={raccoonsInWallet}
          close={() => {
            setShowSendMessageModal(false);
          }}
        />

        <SelectSquadUpgradeModal 
          show={showSquadApplyUpgradeModal} 
          source="raccoonPageSelectSquadUpgrade" 
          includeSquadPotions={true} 
          raccoonNum={raccoonNum} 
          buttonLabel="Apply"
          parentSetToastColor={setToastColor}
          usedSquadUpgradeCallback={usedSquadUpgradeCallback}
          close={() => {
            setShowSquadApplyUpgradeModal(false);
          }}
        />        


        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={6000} />

      </div>
    </>
  );
};


export default RaccoonPage;

