import React, {useState, useContext, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import {AUTH_TOKEN} from '../constants';
import { useOnHoverOutside } from "../hooks/useOnHoverOutside";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const options = [
  'one', 'two', 'three'
];
const defaultOption = options[0];

const Footer = ({isMobile}) => {
  const { state } = useContext(GlobalStateContext);

  const location = useLocation();

  if (location && location.pathname.includes("/messages")) {
    return <div />
  }

  return (
      <div className="flex justify-between nowrap footer_container">
        <div className="footer_row">
          <div className="footer_left_cell" style={{paddingLeft: isMobile ? '10px' : undefined}}>
            <div className="footer_link_container">
              <a className="footer_link" href="https://game1.raccoonsyndicate.com">Leaderboard Game Home</a>
            </div>
            <div className="footer_link_container">
              <a className="footer_link" href="/faq">Leaderboard Game FAQ</a>
            </div>
            <div className="footer_link_container">
              <a className="footer_link" href="https://raccoonsyndicate.com">Raccoon Syndicate Home</a>
            </div>
            <div className="footer_link_container">
              <a className="footer_link" target="_blank" href="https://rswebpublic2.s3.amazonaws.com/wppdf/RaccoonSyndicate_Whitepaper_v1.1.2.pdf">Raccoon Syndicate Whitepaper</a>
            </div>            
          </div>
          <div className="footer_center_cell">
            {!isMobile && (
              <a href="https://game1.raccoonsyndicate.com" ><img src="https://rswebpublic2.s3.amazonaws.com/gamegfx/rslogo2.png" className="footer_logo_button" /></a>
            )}
          </div>
          <div className="footer_right_cell" style={{justifyContent: isMobile ? 'center' : undefined}}>
            {!isMobile && (
              <div className="footer_right_inner">
                <div className="footer_social_container"><a href="https://twitter.com/raccoonsyndicat" target="_new"><img className="footer_social_image" src="https://rswebpublic.s3.amazonaws.com/images/twitterlogo.png" /></a></div>
                <div className="footer_social_container"><a href="https://discord.gg/j5ZW5uDBx3" target="_new"><img className="footer_social_image" src="https://rswebpublic.s3.amazonaws.com/images/discordlogo.png" /></a></div>
              </div>
            )}
            {isMobile && (
              <a href="https://game1.raccoonsyndicate.com" ><img src="https://rswebpublic2.s3.amazonaws.com/gamegfx/rslogo2.png" className="footer_logo_button" style={{marginTop: isMobile ? '-20px' : undefined}}/></a>
            )}
          </div>
        </div>
        <div className="footer_cc">
          Raccoon Syndicate 2023 | ALL Rights Reserved
        </div>
      </div>
  )
};

export default Footer;
