import React from 'react';
import CreateLink from './CreateLink';
import Header from './Header';
import Footer from './Footer';
import LinkList from './LinkList';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from './Login';
import Search from './Search';
import Home from './Home';
import RandomNumGenerator from './RandomNumGenerator';
import RaccoonPage from './RaccoonPage';
import MyNFTs from './MyNFTs';
import Forge from './Forge';
import ForgeAdv from './ForgeAdv';
import BulkUse from './BulkUse';
import BulkAttach from './BulkAttach';
import BulkDetach from './BulkDetach';
import Spin from './Spin';
import TransferInventory from './TransferInventory';
import FAQ from './FAQ';
// import Krenk from './Krenk';
import MessagesPage from './MessagesPage';
import MessagePage from './MessagePage';
import mobile from 'is-mobile';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

const App = () => {
  // let isMobileOld = mobile();

  const windowWidth = useWindowWidth();

  let isMobile = false;
  if (windowWidth < 900) {
    isMobile = true;
  }

  // if (isMobile) {
    // return <div style={{color: '#fff'}}>Available on Desktop only for now. Mobile coming very soon.</div>
  // }

  return (
    <div className="center w100">
      <Header isMobile={isMobile} />
      <div>
        <Routes>
          <Route
            path="/create"
            element={<CreateLink isMobile={isMobile}/>}
          />
          <Route path="/raccoon" element={<RaccoonPage isMobile={isMobile}/>}/>
          <Route path="/mynfts" element={<MyNFTs isMobile={isMobile}/>}/>
          <Route path="/forge" element={<Forge isMobile={isMobile}/>}/>
          <Route path="/forgeadv" element={<ForgeAdv isMobile={isMobile}/>}/>
          <Route path="/bulkuse" element={<BulkUse isMobile={isMobile}/>}/>
          <Route path="/bulkattach" element={<BulkAttach isMobile={isMobile}/>}/>
          <Route path="/bulkdetach" element={<BulkDetach isMobile={isMobile}/>}/>
          <Route path="/spin" element={<Spin isMobile={isMobile}/>}/>
          <Route path="/transferinventory" element={<TransferInventory isMobile={isMobile}/>}/>
          <Route path="/messages" element={<MessagesPage isMobile={isMobile}/>}/>
          <Route path="/faq" element={<FAQ isMobile={isMobile}/>}/>
          {/*<Route path="/krenk" element={<Krenk isMobile={isMobile}/>}/>*/}
          <Route
            path="/"
            element={<Home isMobile={isMobile} />}
          />                        
        </Routes>
      </div>
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
