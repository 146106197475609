import React, {useState, useEffect, useContext, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import {AUTH_TOKEN} from '../constants';
import { useOnHoverOutside } from "../hooks/useOnHoverOutside";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';

const myWallets = ['stake1u9w3dt4zw038lk87wcjlduqecwn3h5n7vd0ar97890ykrscya9zms', 'stake1u88rs565mlmlrkz4z8ssv39jrgddaujglgtu5vcreg9872cmzxvx6'] // stakepool, namiwallet

const options = [
  'one', 'two', 'three'
];
const defaultOption = options[0];

const Header = ({isMobile}) => {
  const navigate = useNavigate();
  const { state, numUnreadConversations, connectNami, connectEternl, connectTyphon, connectFlint, connectGero, disconnectWallet, activeWallet, globalLucid } = useContext(GlobalStateContext);

  const authToken = localStorage.getItem(AUTH_TOKEN);

  let raccoonsInWallet = null;
  let raccoonUpgradesInWallet = null;

  if (state && state.raccoonsInWallet) {
    raccoonsInWallet = state.raccoonsInWallet;
  }
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }

  const location = useLocation();

  let messagesPage = false;
  if (location && location.pathname.includes("/messages")) {
    messagesPage = true;
  }

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  const dropdownNotConnectedRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownNotConnectedOpen, setMenuDropDownNotConnectedOpen] = useState(false);
  
  const [walletAddress, setWalletAddrress] = useState(null);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };
  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook


  // Function to close dropdown
  const closeHoverMenuNotConnected = () => {
    setMenuDropDownNotConnectedOpen(false);
  };
  useOnHoverOutside(dropdownNotConnectedRef, closeHoverMenuNotConnected); // Call the hook
  

  const setWalletAddr = async () => {
    if (globalLucid) {
      if (globalLucid && globalLucid.wallet) {
        let addr = await globalLucid.wallet.rewardAddress();
        setWalletAddrress(addr);
      }
    }
  }
  
  useEffect(() => {
    setWalletAddr();
  }, [globalLucid, activeWallet]);


  const Menu = () => {
    let haveNami = window && window.cardano && window.cardano.nami ? true : true;
    let haveEternl = window && window.cardano && window.cardano.eternl ? true : true;
    let haveTyphon = window && window.cardano && window.cardano.typhon ? true : true;
    let haveFlint = window && window.cardano && window.cardano.flint ? true : true;
    let haveGero = window && window.cardano && window.cardano.gerowallet ? true : true;

    return (
      <>
          <div className="wallet-dropdown-menu">
              <div className="wallet-dropdown-menu-inner">
                  <div className="wallet-dropdown-option"><a href={`/mynfts`} className="wallet-dropdown-option-text">My NFTs</a></div>
                  {/*myWallets && walletAddress && myWallets.includes(walletAddress) && (
                    <div className="wallet-dropdown-option"><a href={`/forge`} className="wallet-dropdown-option-text">Forge</a></div>
                  )*/}
                  <div className="wallet-dropdown-option"><a href={`/forge`} className="wallet-dropdown-option-text">Forge</a></div>
                  <div className="wallet-dropdown-option"><a href={`/forgeadv`} className="wallet-dropdown-option-text">Forge (Advanced)</a></div>
                  {myWallets && walletAddress && myWallets.includes(walletAddress) && (
                    <div className="wallet-dropdown-option"><a href={`/bulkuse`} className="wallet-dropdown-option-text">Bulk Use</a></div>
                  )}
                  {/*myWallets && walletAddress && myWallets.includes(walletAddress) && (
                    <div className="wallet-dropdown-option"><a href={`/bulkattach`} className="wallet-dropdown-option-text">Bulk Attach</a></div>
                  )*/}
                  <div className="wallet-dropdown-option"><a href={`/bulkattach`} className="wallet-dropdown-option-text">Bulk Attach</a></div>
                  {/*myWallets && walletAddress && myWallets.includes(walletAddress) && (
                    <div className="wallet-dropdown-option"><a href={`/bulkdetach`} className="wallet-dropdown-option-text">Bulk Detach</a></div>
                  )*/}
                  <div className="wallet-dropdown-option"><a href={`/bulkdetach`} className="wallet-dropdown-option-text">Bulk Detach</a></div>
                  <div className="wallet-dropdown-option"><a href={`/messages`} className="wallet-dropdown-option-text">Messages</a></div>
                  <div className="wallet-dropdown-option"><a href={`/spin`} className="wallet-dropdown-option-text">Spin</a></div>
                  <div className="wallet-dropdown-option"><a href={`/transferinventory`} className="wallet-dropdown-option-text">Transfer Inventory</a></div>
                  {haveNami && (
                    <div className="wallet-dropdown-option-wallet">
                      <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();connectNami();}}>
                        <div className="wallet-dropdown-wallet-row">
                          <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/nami.svg" />
                          <div className="wallet-dropdown-wallet-name">Nami</div>
                          {activeWallet === 'Nami' && <img className="wallet-dropdown-wallet-icon" src="./green-checkbox.png" />}
                        </div>
                      </a>
                    </div>
                  )}
                  {haveEternl && (
                    <div className="wallet-dropdown-option-wallet">
                      <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();connectEternl();}}>
                        <div className="wallet-dropdown-wallet-row">
                          <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/eternl.webp" />
                          <div className="wallet-dropdown-wallet-name">Eternl</div>
                          {activeWallet === 'Eternl' && <img className="wallet-dropdown-wallet-icon" src="./green-checkbox.png" />}
                        </div>
                      </a>
                    </div>
                  )}
                  {haveTyphon && (
                    <div className="wallet-dropdown-option-wallet">
                      <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();connectTyphon();}}>
                        <div className="wallet-dropdown-wallet-row">
                          <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/typhon-icon.svg" />
                          <div className="wallet-dropdown-wallet-name">Typhon</div>
                          {activeWallet === 'Typhon' && <img className="wallet-dropdown-wallet-icon" src="./green-checkbox.png" />}
                        </div>
                      </a>
                    </div>
                  )}
                  {haveFlint && (
                    <div className="wallet-dropdown-option-wallet">
                      <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();connectFlint()}}>
                        <div className="wallet-dropdown-wallet-row">
                          <img className="wallet-dropdown-wallet-icon" style={{width: '20px', height: '20px'}} src="https://rswebpublic2.s3.amazonaws.com/walletIcons/flint-icon.svg" />
                          <div className="wallet-dropdown-wallet-name">Flint</div>
                          {activeWallet === 'Flint' && <img className="wallet-dropdown-wallet-icon" src="./green-checkbox.png" />}
                        </div>
                      </a>
                    </div>
                  )}                      
                  {haveGero && (
                    <div className="wallet-dropdown-option-wallet">
                      <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();connectGero();}}>
                        <div className="wallet-dropdown-wallet-row">
                          <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/gero-icon.svg" />
                          <div className="wallet-dropdown-wallet-name">Gero</div>
                          {activeWallet === 'Gero' && <img className="wallet-dropdown-wallet-icon" src="./green-checkbox.png" />}
                        </div>
                      </a>
                    </div>
                  )} 
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenu();disconnectWallet();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <div className="wallet-dropdown-wallet-name">Disconnect Wallet</div>
                      </div>
                    </a>
                  </div>     
              </div>
          </div>
      </>
    )
  }

  const MenuNotConnected = () => {
    let haveNami = window && window.cardano && window.cardano.nami ? true : true;
    let haveEternl = window && window.cardano && window.cardano.eternl ? true : true;
    let haveTyphon = window && window.cardano && window.cardano.typhon ? true : true;
    let haveFlint = window && window.cardano && window.cardano.flint ? true : true;
    let haveGero = window && window.cardano && window.cardano.gerowallet ? true : true;

    return (
      <>
          <div className="wallet-dropdown-menu" style={{marginTop: '-7px'}}>
              <div className="wallet-dropdown-menu-inner">
                {haveNami && (
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenuNotConnected();connectNami();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/nami.svg" />
                        <div className="wallet-dropdown-wallet-name">Nami</div>
                      </div>
                    </a>
                  </div>
                )}
                {haveEternl && (
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenuNotConnected();connectEternl();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/eternl.webp" />
                        <div className="wallet-dropdown-wallet-name">Eternl</div>
                      </div>
                    </a>
                  </div>
                )}
                {haveTyphon && (
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenuNotConnected();connectTyphon();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/typhon-icon.svg" />
                        <div className="wallet-dropdown-wallet-name">Typhon</div>
                      </div>
                    </a>
                  </div>
                )}
                {haveFlint && (
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenuNotConnected();connectFlint();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <img className="wallet-dropdown-wallet-icon" style={{width: '20px', height: '20px'}} src="https://rswebpublic2.s3.amazonaws.com/walletIcons/flint-icon.svg" />
                        <div className="wallet-dropdown-wallet-name">Flint</div>
                      </div>
                    </a>
                  </div>
                )}                      
                {haveGero && (
                  <div className="wallet-dropdown-option-wallet">
                    <a className="wallet-dropdown-option-text" onClick={() => {closeHoverMenuNotConnected();connectGero();}}>
                      <div className="wallet-dropdown-wallet-row">
                        <img className="wallet-dropdown-wallet-icon" src="https://rswebpublic2.s3.amazonaws.com/walletIcons/gero-icon.svg" />
                        <div className="wallet-dropdown-wallet-name">Gero</div>
                      </div>
                    </a>
                  </div>
                )}                          
              </div>
          </div>
      </>
    )
  }

  return (
      <div className="flex justify-between nowrap header_container">
        <div className="flex flex-fixed black">
            {messagesPage &&
              <a href={`/`} style={{textDecoration: 'none'}}><img className="header_rs_logo" src="https://rswebpublic2.s3.amazonaws.com/gamegfx/rslogo2.png" /></a>
            }
          </div>
        <div className="top_header_row">
          <div className="header_faq_text">
            <a className="header_faq_link" href="/faq">
              FAQ
            </a>
          </div>
          {!isMobile && state.connectInProgress && state.doneInitialWalletLoad && (raccoonsInWallet || raccoonUpgradesInWallet) &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}} ref={dropdownNotConnectedRef}>
            <div className="connect_button" style={{minHeight: '44px', maxHeight: '44px', minWidth: '200px',}} >
              {state.connectInProgress &&
                <div>
                  <ClipLoader
                    color={'#FFF'}
                    loading={true}
                    size={12}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              }
            </div>
          </div>
          }          
          {!isMobile && !state.connectInProgress && state.doneInitialWalletLoad && (raccoonsInWallet || raccoonUpgradesInWallet) &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}} ref={dropdownRef}>
              <div className="connect_button" style={{display: 'flex', flexDirection: 'row'}} onMouseOver={() => setMenuDropDownOpen(true)}>
                {raccoonsInWallet && 
                  <div style={{display: 'flex', flexDirection: 'row', fontSize: '20px', marginRight: raccoonUpgradesInWallet ? '20px' : null}}>
                      <img src="racface1.png" className="connect_button_icon" />{raccoonsInWallet.length}
                  </div>
                }
                {raccoonUpgradesInWallet && 
                  <div style={{display: 'flex', flexDirection: 'row', fontSize: '20px'}}>
                    <img src="forgej.gif" className="connect_button_icon_upgrades" />{raccoonUpgradesInWallet.length}
                  </div>
                }
                <div style={{display: 'flex', flexDirection: 'row', fontSize: '20px', marginLeft: '20px'}}>
                  <img src="messageicon.png" className="connect_button_icon_upgrades" />
                </div>
              </div>
              {isMenuDropDownOpen && <Menu />} 
            </div>
          }
          {!isMobile && state.doneInitialWalletLoad && !raccoonsInWallet && !raccoonUpgradesInWallet && 
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}} ref={dropdownNotConnectedRef}>
              <div className="connect_button" style={{minHeight: '44px', maxHeight: '44px'}} onMouseOver={() => setMenuDropDownNotConnectedOpen(true)}>
                CONNECT
                {state.connectInProgress &&
                  <div style={{marginLeft: '10px'}}>
                    <ClipLoader
                      color={'#FFF'}
                      loading={true}
                      size={12}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }
              </div>
              {isMenuDropDownNotConnectedOpen && <MenuNotConnected />} 
            </div>
          }
          {!isMobile && !state.doneInitialWalletLoad && (
            <div className="connect_button" style={{padding: '0px', minHeight: '44px', maxHeight: '44px', alignItems: 'center', justifyContent: 'center', minWidth: '110px', marginTop: '12px'}}>
              <ClipLoader
                color={'#FFF'}
                loading={true}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          {isMobile && 
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}}>
              <div className="connect_button" style={{minHeight: '44px', maxHeight: '44px'}} onClick={() => {
                setTimeout(() => {
                  toast("Mobile wallet connect coming soon");
                }, 200);
              }}>
                CONNECT
              </div>
            </div>
          }          
        </div>
        {isMobile &&
          <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: '#e08705', color: '#FFF' }} autoClose={6000} />
        }
      </div>
  )

  
  /*
  return (
    <div className="flex justify-between nowrap header_container">
      <div className="flex flex-fixed black">
        <Link to="/" className="no-underline black">
          <div className="fw7 mr1 white"></div>
        </Link>
      </div>
      {(raccoonsInWallet || raccoonUpgradesInWallet) &&
        <div className="connect_button" style={{display: 'flex', flexDirection: 'row'}} onClick={onClickConnect}>
          {raccoonsInWallet && <div style={{marginRight: raccoonUpgradesInWallet ? '10px' : null}}>R: {raccoonsInWallet.length}</div>}
          {raccoonUpgradesInWallet && <div>U: {raccoonUpgradesInWallet.length}</div>}
          <Dropdown options={options} onChange={_onSelect} value={defaultOption} placeholder="Select an option" />;
        </div>
      }
      {!raccoonsInWallet && !raccoonUpgradesInWallet && 
        <div className="connect_button" onClick={onClickConnect}>
          CONNECT
        </div>
      }
    </div>
  );
  */
};

export default Header;