import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import SelectItemModal from './SelectItemModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';
import SelectItemToForgeModal from './SelectItemToForgeModal';

const myWallets = ['stake1u9w3dt4zw038lk87wcjlduqecwn3h5n7vd0ar97890ykrscya9zms', 'stake1u88rs565mlmlrkz4z8ssv39jrgddaujglgtu5vcreg9872cmzxvx6'] // stakepool, namiwallet


const browser = detect();

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_UPGRADE_STATUS = gql`
  query GetUpgradeStatus($upgradeNames: String!) {
    upgradeStatus(upgradeNames: $upgradeNames) {
      results
      upgrades
    }
  }
`;

const UPGRADE_BONUS_RATES = {
  'Milk': 30,
  'Canteen': 50,
  'Vegebite': 60,
  'Baked Beans': 50,
  'PotionX': 60,
  'Gummy Raccoon': 65,
  'Mushrooms': 70
};

const GET_RACCOON_INFO_FOR_NFT_LIST = gql`
  query GetRaccoonInfoForNFTList($raccoons: String!) {
    raccoonInfoForNFTList(raccoons: $raccoons) {
      raccoonInfo
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $requestType: String!, $inventoryItemId: String, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, requestType: $requestType, inventoryItemId: $inventoryItemId, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;


const GET_INVENTORY = gql`
  query GetInventory {
    inventory {
      id
      type
      status
      earned_method
      was_listed_raccoon_at_end_of_battle_round
    }
  }
`;


const FORGE_ITEMS_ADV = gql`
  mutation ForgeItemsAdv($cellItems: String!, $itemsToReceiveFromForge: String!, $destRows: String!) {
    forgeItemsAdv( cellItems: $cellItems, itemsToReceiveFromForge: $itemsToReceiveFromForge, destRows: $destRows ) {
      result
      cellItems
      itemsToReceiveFromForge
      destRows
      pairs
      nftItems
      inventoryItems
      forge_adv_watch_id
      operationId
    }
  }
`;


const FORGE_ITEMS_ADV_UPDATE_STATUS = gql`
  mutation ForgeItemsAdvUpdateStatus($forge_adv_watch_id: String!, $status: String!, $trx_hash: String, $operationId: String) {
    forgeItemsAdvUpdateStatus( forge_adv_watch_id: $forge_adv_watch_id, status: $status, trx_hash: $trx_hash, operationId: $operationId ) {
      result
      cellItems
      pairs
      nftItems
      inventoryItems
      forge_adv_watch_id
      trx_hash
    }
  }
`;



const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};




const ForgeAdv = (props, v2, v3) => {
  const { width, height } = useWindowSize();

  const [tab, setTab] = useState('myRaccoons');
  const [showForgeSelectItemModal, setShowForgeSelectItemModal] = useState(false);
  
  const [cellNumberToSelectItemFor, setCellNumberToSelectItemFor] = useState(null);
  const [cellNumberToReceiveForgeItem, setCellNumberToReceiveForgeItem] = useState(null);
  const [cellItems, setCellItems] = useState({});
  const [itemsToReceiveFromForge, setItemsToReceiveFromForge] = useState({});

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonSquadInfo, setRaccoonSquadInfo] = useState([]);
  const [raccoonInfo, setRaccoonInfo] = useState([]);
  const [upgradeStatus, setUpgradeStatus] = useState([]);

  const [destRow1, setDestRow1] = useState('nft');
  const [destRow2, setDestRow2] = useState('nft');
  const [destRow3, setDestRow3] = useState('nft');
  const [destRow4, setDestRow4] = useState('nft');
  const [destRow5, setDestRow5] = useState('nft');

  const [squadMemberRaccoonInfo, setSquadMemberRaccoonInfo] = useState([]);

  const [inventory, setInventory] = useState([]);

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [filterUpgradesMyUpgrades, setFilterUpgradesMyUpgrades] = useState('Any');
  const [filterUpgradesMyInventory, setFilterUpgradesMyUInventory] = useState('Any');

  const [walletAddress, setWalletAddrress] = useState(null);

  const { state, walletSendDetachRequest, walletMintInventoryItem, walletForgeItemsAdv, team, raccoonUpgradesApplied, globalLucid, backgroundRefreshWallet } = useContext(GlobalStateContext);

  const [toastColor, setToastColor] = useState('#e08705');
  
  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);
  
  const [getRaccoonsUpgradeStatus, { loading: loadingGetRaccoonsUpdateStatus, error: errorGetRaccoonsUpdateStatus, data: dataGetRaccoonsUpdateStatus, refetch: refetchGetRaccoonsUpdateStatus }] = useLazyQuery(GET_RACCOONS_UPGRADE_STATUS);

  const [getUpgradeStatus, { loading: loadingGetUpdradeStatus, error: errorGetUpdradeStatus, data: dataGetUpdradeStatus, refetch: refetchGetUpdradeStatus }] = useLazyQuery(GET_UPGRADE_STATUS);

  const [getInventory, { loading: loadingGetInventory, error: errorGetInventory, data: dataGetInventory, refetch: refetchGetInventory }] = useLazyQuery(GET_INVENTORY);



  const [forgeItemsAdvUpdateStatus, { data: dataForgeItemsUpdateStatus, loading: loadingForgeItemsUpdateStatus, error: errorForgeItemsUpdateStatus }] = useMutation(FORGE_ITEMS_ADV_UPDATE_STATUS, 
    {
      onCompleted: response => {
        /*
        if (response && response.forgeItemsUpdateStatus && response.forgeItemsUpdateStatus.result === 'success') {
          setToastColor('#14711f');
          setTimeout(() => {
            toast("Forge request added to queue. Please wait 5 - 15 minutes for the forge to complete your item(s).")
          }, 200);
          return;
        } else {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Sorry, there was an error completing your forge request.")
          }, 200);
          return;
        }
        */
      }
  });



  const [forgeItemsAdv, { data: dataForgeItems, loading: loadingForgeItems, error: errorForgeItems }] = useMutation(FORGE_ITEMS_ADV, 
    {
      onCompleted: response => {
        if (response && response.forgeItemsAdv && response.forgeItemsAdv.result === 'success') {

          const forgeItemsSuccess = ({forgeItems, trx_hash}) => {
            setToastColor('#14711f');
            setTimeout(() => {
              toast("Forge request added to queue. Please wait 5 - 15 minutes for the forge to complete your item(s).")
            }, 200);
          }

          const forgeItemsFailure = ({forgeItems, e}) => {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Sorry, there was an error creating your forge request.")
            }, 200);
            return;
          }

          walletForgeItemsAdv({forgeItems: response.forgeItemsAdv, forgeItemsSuccess, forgeItemsFailure})
        } else {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Sorry, there was an error creating your forge request")
          }, 200);
          return;
        }
    }
  });



  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
          getUpgradeStatusData();
          getInventory();
        }
    }
  });



  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
            getRaccoonsUpgradeStatus({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
          }
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }



  const refreshInventory = () => {
    getInventory();
  }


  const getUpgradeStatusData = async () => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getUpgradeStatus({variables: {upgradeNames: JSON.stringify(raccoonUpgradesInWallet)}});
          }
        }
      }
    }
  }

  let raccoonUpgradesInWallet = null;
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }


  useEffect(() => {
    if (dataGetUpdradeStatus && dataGetUpdradeStatus.upgradeStatus && dataGetUpdradeStatus.upgradeStatus.upgrades) {
        let upgrades = JSON.parse(dataGetUpdradeStatus.upgradeStatus.upgrades);
        setUpgradeStatus(upgrades);
    }
  }, [dataGetUpdradeStatus])

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet({fromApi: true});
    }
  }, [state.api]);

  const setWalletAddr = async () => {
    if (globalLucid) {
      if (globalLucid && globalLucid.wallet) {
        let addr = await globalLucid.wallet.rewardAddress();
        setWalletAddrress(addr);
      }
    }
  }
  
  useEffect(() => {
    setWalletAddr();
  }, [globalLucid, state.api]);

  useEffect(() => {
    if (dataGetInventory && dataGetInventory.inventory) {
      setInventory(dataGetInventory.inventory);
    }
  }, [dataGetInventory])
  
  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let newRaccoonsInWallet = [];
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      if (results && results.forEach ) {
        let racSquadInfo = {};
        results.forEach(result => {
          if (result && (result.raccoonsInSquadILead || result.raccoonsInSquadImIn || result.squadILead || result.squadImIn)) {
            racSquadInfo[result.racNum] = result;
          }
        });

/*
        if (raccoonsInWallet && raccoonsInWallet.forEach) {
          raccoonsInWallet.forEach(rac => {
            let newRac = {...rac};
            let racNumStr= rac.assetNameAscii.substring('Raccoon '.length, rac.assetNameAscii.length);
            let racNum = parseInt(racNumStr);
            if (racSquadInfo[racNum]) {
              newRac.racNum = racSquadInfo[racNum].racNum;
              newRac.raccoonsInSquadILead = racSquadInfo[racNum].raccoonsInSquadILead;
              newRac.raccoonsInSquadImIn = racSquadInfo[racNum].raccoonsInSquadImIn;
              newRac.squadILead = racSquadInfo[racNum].squadILead;
              newRac.squadImIn = racSquadInfo[racNum].squadImIn;
            }
            newRaccoonsInWallet.push(newRac);
          });
        }
*/
        setRaccoonSquadInfo({...racSquadInfo});
      }
    }
  }, [dataGetRaccoonsSquads, loadingGetRaccoonsSquads]);


  const onForgeItems = () => {
    let destRows = {};
    destRows[1] = destRow1;
    destRows[2] = destRow2;
    destRows[3] = destRow3;
    destRows[4] = destRow4;
    destRows[5] = destRow5;

    forgeItemsAdv({ variables: {cellItems: JSON.stringify(cellItems), itemsToReceiveFromForge: JSON.stringify(itemsToReceiveFromForge), destRows: JSON.stringify(destRows) } })
  }


  const selectItem = ({boxNumber, nftOrInventory, upgrade}) => {
    let inputBoxNumber = boxNumber;

    setCellNumberToSelectItemFor(null);
    

    let oldCellItems = cellItems;

    let thisBoxUpgradeType = null;
    let otherBoxUpgradeType = null;
    if (oldCellItems[inputBoxNumber]) {
      oldCellItems[inputBoxNumber].push({nftOrInventory, upgrade})
    } else {
      oldCellItems[inputBoxNumber] = [{nftOrInventory, upgrade}];
    }

    setCellItems({...oldCellItems});
  }

  const getItemCategory = item => {
    let category = 'None';
    if (item && item.indexOf && item.indexOf('Milk') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Canteen') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Vegebite') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Beans') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('PotionX') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Gummy') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Mushrooms') >= 0) {
      category = 'Consumable';
    } else if (item && item.indexOf && item.indexOf('Gold Ring') >= 0) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('Emerald Ring') >= 0) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('Ruby Ring') >= 0) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('Diamond Ring') === 0 || item.indexOf('Diamond Ring') === 3) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('CompoundX Ring') >= 0) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('Blue Diamond Ring') >= 0) {
      category = 'Ring';
    } else if (item && item.indexOf && item.indexOf('Light Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('Dark Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('Gold Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('Psychedelic Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('CompoundX Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('Blue Diamond Wings') >= 0) {
      category = 'Wings';
    } else if (item && item.indexOf && item.indexOf('10 Battle Points') >= 0) {
      category = 'Battle Points';
    } else if (item && item.indexOf && item.indexOf('20 Battle Points') >= 0) {
      category = 'Battle Points';
    } else if (item && item.indexOf && item.indexOf('50 Battle Points') >= 0) {
      category = 'Battle Points';
    } else if (item && item.indexOf && item.indexOf('100 Battle Points') >= 0) {
      category = 'Battle Points';
    } else if (item && item.indexOf && item.indexOf('Blue Squad Potion') >= 0) {
      category = 'Squad Potion';
    } else if (item && item.indexOf && item.indexOf('Red Squad Potion') >= 0) {
      category = 'Squad Potion';
    } else if (item && item.indexOf && item.indexOf('Purple Squad Potion') >= 0) {
      category = 'Squad Potion';
    }
    return category;  
  }

  const selectForgeItemToReceive = ({boxNumber, item}) => {
    setCellNumberToReceiveForgeItem(null);

    let newItemCategory = getItemCategory(item);
    let originalItemCategory = null;
    let originalItemToReceiveFromForge = itemsToReceiveFromForge[boxNumber];
    if (originalItemToReceiveFromForge) {
      originalItemCategory = getItemCategory(originalItemToReceiveFromForge);
    }

    if (newItemCategory !== originalItemCategory) {
      setCellItems({});
    }

    let newItemsToReceiveFromForge = itemsToReceiveFromForge;
    newItemsToReceiveFromForge[boxNumber] = item;
    setItemsToReceiveFromForge({...newItemsToReceiveFromForge});

    if (item && item.indexOf('Squad Potion') >= 0) {
      setDestRow1('inventory');
    }
  }


  const clearCell = (cellNumber) => {
    let newCellItems = [];
    if (cellItems && cellItems[parseInt(cellNumber)]) {
      for (var cellNum in cellItems) {
        if (cellItems.hasOwnProperty(cellNum)) {
          if (parseInt(cellNum) !== parseInt(cellNumber)) {
            newCellItems[cellNum] = cellItems[cellNum];
          }
        }
      }
    }
    setCellItems({...newCellItems});
  }

  const clearItemToReceiveCell = (cellNumber) => {
    let newItemsToReceiveFromForge = itemsToReceiveFromForge;
    newItemsToReceiveFromForge[cellNumber] = null;
    setCellItems({});
    setItemsToReceiveFromForge({...newItemsToReceiveFromForge});
  }

  if (browser && browser.name) {
    if (browser.name === 'firefox' || browser.name === 'safari') {
      return (
        <div className="home_wrapper">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          <center>
            <div className="global_leaderboard_title_text" style={{marginTop: '100px', textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              Only Chrome, Brave and Edge browsers can view this page
            </div>  
          </center>    

        </div>
      )
    }
  }

  /*
  if (!walletAddress || !myWallets.includes(walletAddress)) {
    return (
        <>
        <div className="home_wrapper">
          
          <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          </div>
        </>
    )
  }
  */

  if (!walletAddress) {
    return (
        <>
        <div className="home_wrapper">
          
          <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          </div>
        </>
    )
  }


  const getUpgradeType = upgradeName => {
    let upgradeType = 'None';
    if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Milk') >= 0) {
      upgradeType = 'Milk';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Canteen') >= 0) {
      upgradeType = 'Canteen';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Vegebite') >= 0) {
      upgradeType = 'Vegebite';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Beans') >= 0) {
      upgradeType = 'Baked Beans';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Potion') >= 0) {
      upgradeType = 'PotionX';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gummy') >= 0) {
      upgradeType = 'Gummy Raccoon';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Mushrooms') >= 0) {
      upgradeType = 'Mushrooms';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Ring') >= 0) {
      upgradeType = 'Gold Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Emerald Ring') >= 0) {
      upgradeType = 'Emerald Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Ruby Ring') >= 0) {
      upgradeType = 'Ruby Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Diamond Ring') === 0 || upgradeName.indexOf('Diamond Ring') === 3) {
      upgradeType = 'Diamond Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Ring') >= 0) {
      upgradeType = 'CompoundX Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Ring') >= 0) {
      upgradeType = 'Blue Diamond Ring';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Light Wings') >= 0) {
      upgradeType = 'Light Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Dark Wings') >= 0) {
      upgradeType = 'Dark Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Gold Wings') >= 0) {
      upgradeType = 'Gold Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Psychedelic Wings') >= 0) {
      upgradeType = 'Psychedelic Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('CompoundX Wings') >= 0) {
      upgradeType = 'CompoundX Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('Blue Diamond Wings') >= 0) {
      upgradeType = 'Blue Diamond Wings';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('10 Battle Points') >= 0) {
      upgradeType = '10 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('20 Battle Points') >= 0) {
      upgradeType = '20 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('50 Battle Points') >= 0) {
      upgradeType = '50 Battle Points';
    } else if (upgradeName && upgradeName.indexOf && upgradeName.indexOf('100 Battle Points') >= 0) {
      upgradeType = '100 Battle Points';
    }
    return upgradeType;
  }

  


  const getForgedItem = (upgradeType) => {
    let imgSrc = null;
    let resultItemUpgradeType = null;
    if (upgradeType === 'Milk') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
      resultItemUpgradeType = 'Canteen';
    } else if (upgradeType === 'Canteen') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif';
      resultItemUpgradeType = 'Vegebite';
    } else if (upgradeType === 'Vegebite') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif';
      resultItemUpgradeType = 'Baked Beans';
    } else if (upgradeType === 'Baked Beans') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif';
      resultItemUpgradeType = 'PotionX';
    } else if (upgradeType === 'PotionX') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif';
      resultItemUpgradeType = 'PotionX';
    } else if (upgradeType === 'Gummy Raccoon') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif';
      resultItemUpgradeType = 'Mushrooms';
    } else if (upgradeType === 'Gold Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif';
      resultItemUpgradeType = 'Emerald Ring';
    } else if (upgradeType === 'Emerald Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif';
      resultItemUpgradeType = 'Ruby Ring';
    } else if (upgradeType === 'Ruby Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif';
      resultItemUpgradeType = 'Diamond Ring';
    } else if (upgradeType === 'Diamond Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif';
      resultItemUpgradeType = 'CompoundX Ring';
    } else if (upgradeType === 'CompoundX Ring') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif';
      resultItemUpgradeType = 'Blue Diamond Ring';
    } else if (upgradeType === 'Light Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif';
      resultItemUpgradeType = 'Dark Wings';
    } else if (upgradeType === 'Dark Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif';
      resultItemUpgradeType = 'Gold Wings';
    } else if (upgradeType === 'Gold Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif';
      resultItemUpgradeType = 'Psychedelic Wings';
    } else if (upgradeType === 'Psychedelic Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif';
      resultItemUpgradeType = 'CompoundX Wings';
    } else if (upgradeType === 'CompoundX Wings') {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif';
      resultItemUpgradeType = 'Blue Diamond Wings';
    } 
    return {
      imgSrc,
      resultItemUpgradeType
    };
  }



  const renderCellItem = (boxNumber, cellItem) => {
    let numPoints = 0;

    let asciiName = cellItem.upgrade.assetNameAscii;
    let imgSrc = null;
    let upgradeNum = 0;
    let asciiPrefix = null;

    if (asciiName.indexOf('Milk') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif';
      asciiPrefix = 'RS Milk #';
      upgradeNum = asciiName.substring('RS Milk '.length, asciiName.length);
      numPoints = 1;
    } else if (asciiName.indexOf('Canteen') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
      asciiPrefix = 'RS Canteen #';
      upgradeNum = asciiName.substring('RS Canteen '.length, asciiName.length);
      numPoints = 2;
    } else if (asciiName.indexOf('Vegebite') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif';
      asciiPrefix = 'RS Vegebite #';
      upgradeNum = asciiName.substring('RS Vegebite '.length, asciiName.length);
      numPoints = 4;
    } else if (asciiName.indexOf('Baked Beans') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif';
      asciiPrefix = 'RS Baked Beans #';
      upgradeNum = asciiName.substring('RS Baked Beans '.length, asciiName.length);
      numPoints = 8;
    } else if (asciiName.indexOf('PotionX') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif';
      asciiPrefix = 'RS PotionX #';
      upgradeNum = asciiName.substring('RS PotionX '.length, asciiName.length);
      numPoints = 16;
    } else if (asciiName.indexOf('Gummy Raccoon') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif';
      asciiPrefix = 'RS Gummy Raccoon #';
      upgradeNum = asciiName.substring('RS Gummy Raccoon '.length, asciiName.length);
      numPoints = 32;
    } else if (asciiName.indexOf('Mushrooms') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif';
      asciiPrefix = 'RS Mushrooms #';
      upgradeNum = asciiName.substring('RS Mushrooms '.length, asciiName.length);
      numPoints = 64;
    } else if (asciiName.indexOf('Gold Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif';
      asciiPrefix = 'RS Gold Ring #';
      upgradeNum = asciiName.substring('RS Gold Ring '.length, asciiName.length);
      numPoints = 1;
    } else if (asciiName.indexOf('Emerald Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif';
      asciiPrefix = 'RS Emerald Ring #';
      upgradeNum = asciiName.substring('RS Emerald Ring '.length, asciiName.length);
      numPoints = 2;
    } else if (asciiName.indexOf('Ruby Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif';
      asciiPrefix = 'RS Ruby Ring #';
      upgradeNum = asciiName.substring('RS Ruby Ring '.length, asciiName.length);
      numPoints = 4;
    } else if (asciiName.indexOf('Diamond Ring') === 0 || asciiName.indexOf('Diamond Ring') === 3) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif';
      asciiPrefix = 'RS Diamond Ring #';
      upgradeNum = asciiName.substring('RS Diamond Ring '.length, asciiName.length);
      numPoints = 8;
    } else if (asciiName.indexOf('CompoundX Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif';
      asciiPrefix = 'RS CompoundX Ring #';
      upgradeNum = asciiName.substring('RS CompoundX Ring '.length, asciiName.length);
      numPoints = 16;
    } else if (asciiName.indexOf('Blue Diamond Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif';
      asciiPrefix = 'RS Blue Diamond Ring #';
      upgradeNum = asciiName.substring('RS Blue Diamond Ring '.length, asciiName.length);
      numPoints = 32;
    } else if (asciiName.indexOf('Light Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif';
      asciiPrefix = 'RS Light Wings #';
      upgradeNum = asciiName.substring('RS Light Wings '.length, asciiName.length);
      numPoints = 1;
    } else if (asciiName.indexOf('Dark Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif';
      asciiPrefix = 'RS Dark Wings #';
      upgradeNum = asciiName.substring('RS Dark Wings '.length, asciiName.length);
      numPoints = 2;
    } else if (asciiName.indexOf('Gold Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif';
      asciiPrefix = 'RS Gold Wings #';
      upgradeNum = asciiName.substring('RS Gold Wings '.length, asciiName.length);
      numPoints = 4;
    } else if (asciiName.indexOf('Psychedelic Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif';
      asciiPrefix = 'RS Psychedelic Wings #';
      upgradeNum = asciiName.substring('RS Psychedelic Wings '.length, asciiName.length);
      numPoints = 8;
    } else if (asciiName.indexOf('CompoundX Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif';
      asciiPrefix = 'RS CompoundX Wings #';
      upgradeNum = asciiName.substring('RS CompoundX Wings '.length, asciiName.length);
      numPoints = 16;
    } else if (asciiName.indexOf('Blue Diamond Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif';
      asciiPrefix = 'RS Blue Diamond Wings #';
      upgradeNum = asciiName.substring('RS Blue Diamond Wings '.length, asciiName.length);
      numPoints = 32;
    } else if (asciiName.indexOf('Green Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif';
      asciiPrefix = 'RS Green Squad Potion';
      upgradeNum = asciiName.substring('RS Green Squad Potion '.length, asciiName.length);
      numPoints = 1;
    } else if (asciiName.indexOf('Blue Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif';
      asciiPrefix = 'RS Blue Squad Potion';
      upgradeNum = asciiName.substring('RS Blue Squad Potion '.length, asciiName.length);
      numPoints = 2;
    } else if (asciiName.indexOf('Red Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif';
      asciiPrefix = 'RS Red Squad Potion';
      upgradeNum = asciiName.substring('RS Red Squad Potion '.length, asciiName.length);
      numPoints = 4;
    } else if (asciiName.indexOf('Purple Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif';
      asciiPrefix = 'RS Purple Squad Potion';
      upgradeNum = asciiName.substring('RS Purple Squad Potion '.length, asciiName.length);
      numPoints = 8;
    }

    return (
      <div className="cellItem_cont">
        <img src={imgSrc} className="my_nfts_nft_image_upgrades" />
        <div style={{color: '#fff', width: '100%', textAlign: 'center'}}>{numPoints} {numPoints === 1 ? 'point' : 'points'}</div>
        <div className="cellItemClear" onClick={() => removeInputCellItem(boxNumber, cellItem)}><div className="cellItemClearInner">x</div></div>
      </div>
    )
  }


  const removeInputCellItem = (boxNumber, item) => {
    let oldItems = cellItems[boxNumber];
    if (!oldItems || !oldItems.forEach) {
      return;
    }

    let newItems = [];

    oldItems.forEach(oldItem => {
      if (oldItem.nftOrInventory !== item.nftOrInventory) {
        newItems.push(oldItem);
      } else {
        if (item.nftOrInventory === 'nft') {
          if (item.upgrade.assetNameHex === oldItem.upgrade.assetNameHex) {
            // skip
          } else {
            newItems.push(oldItem);
          }
        } else if (item.nftOrInventory === 'inventoryItem') { 
          if (item.upgrade.id === oldItem.upgrade.id) {
            // skip
          } else {
            newItems.push(oldItem)
          }
        } else {
          newItems.push(oldItem)
        }
      }
    });

    let newCellItems = {};
    for (var cellNum in cellItems) {
      if (cellItems.hasOwnProperty(cellNum)) {
        if (parseInt(cellNum) === parseInt(boxNumber)) {
          newCellItems[cellNum] = [...newItems];
        } else {
          newCellItems[cellNum] = cellItems[cellNum]
        }
      }
    }

    setCellItems({...newCellItems});
  }


  const removeResultCell = (boxNumber) => {
    let newItemsToReceiveFromForge = itemsToReceiveFromForge;
    newItemsToReceiveFromForge[boxNumber] = null;
    setItemsToReceiveFromForge({...newItemsToReceiveFromForge});

    let newCellItems = {};
    for (var cellNum in cellItems) {
      if (cellItems.hasOwnProperty(cellNum)) {
        if (parseInt(cellNum) === parseInt(boxNumber)) {
          newCellItems[cellNum] = null;
        } else {
          newCellItems[cellNum] = cellItems[cellNum]
        }
      }
    }

    setCellItems({...newCellItems});
  }


  const getPointsForItem = item => {

    if (!item) return 99999;

    let itemName = null;
    
    if (item && item.nftOrInventory && item.nftOrInventory === 'nft') {
      itemName = item.upgrade.assetNameAscii;
    } else if (item && item.nftOrInventory && item.nftOrInventory === 'inventoryItem') {
      itemName = item.upgrade.type;
    } else if (typeof item === 'string') {
      itemName = item;
    }

    if (!itemName) return 99999;

    if (itemName.indexOf('Milk') >= 0) {
      return 1;
    } else if (itemName.indexOf('Canteen') >= 0) {
      return 2;
    } else if (itemName.indexOf('Vegebite') >= 0) {
      return 4;
    } else if (itemName.indexOf('Baked Beans') >= 0) {
      return 8;
    } else if (itemName.indexOf('PotionX') >= 0) {
      return 16;
    } else if (itemName.indexOf('Gummy Raccoon') >= 0) {
      return 32;
    } else if (itemName.indexOf('Mushrooms') >= 0) {
      return 64;
    } else if (itemName.indexOf('Gold Ring') >= 0) {
      return 1;
    } else if (itemName.indexOf('Emerald Ring') >= 0) {
      return 2;
    } else if (itemName.indexOf('Ruby Ring') >= 0) {
      return 4;
    } else if (itemName.indexOf('Diamond Ring') === 0 || itemName.indexOf('Diamond Ring') === 3) {
      return 8;
    } else if (itemName.indexOf('CompoundX Ring') >= 0) {
      return 16;
    } else if (itemName.indexOf('Blue Diamond Ring') >= 0) {
      return 32;
    } else if (itemName.indexOf('Light Wings') >= 0) {
      return 1;
    } else if (itemName.indexOf('Dark Wings') >= 0) {
      return 2;
    } else if (itemName.indexOf('Gold Wings') >= 0) {
      return 4;
    } else if (itemName.indexOf('Psychedelic Wings') >= 0) {
      return 8;
    } else if (itemName.indexOf('CompoundX Wings') >= 0) {
      return 16;
    } else if (itemName.indexOf('Blue Diamond Wings') >= 0) {
      return 32;
    } else if (itemName.indexOf('Green Squad Potion') >= 0) {
      return 1;
    } else if (itemName.indexOf('Blue Squad Potion') >= 0) {
      return 2;
    } else if (itemName.indexOf('Red Squad Potion') >= 0) {
      return 4;
    } else if (itemName.indexOf('Purple Squad Potion') >= 0) {
      return 8;
    }

    return 0;
  }


  const getInputNumPoints = (boxNumber) => {
    let items = cellItems[1];

    if (!items) return 0;

    let numPoints = 0;

    items.forEach(item => {
      let pointsForItem = getPointsForItem(item);
      numPoints += pointsForItem;
    });

    return numPoints;
  }

  const renderCellItems = (boxNumber, cellItem) => {

    let items = cellItems[1];

    return (
      <div className="forge_input_items">
        {items.map((item, i) => {
          return (
            <div key={`itm_${i}`} className="forge_input_item_container">
              {renderCellItem(boxNumber, item)}
            </div>
          )
        })}
      </div>
    );

  }

  const renderResultCell = (boxNumber) => {

    let item = itemsToReceiveFromForge[boxNumber];

    if (!item) {
      return <div />
    }

    let imgSrc = null;
    let bpmBoost = null;
    let squadBattlePowerLevelBoost = null;
    let boostNumEpochs = null;
    let numPoints = 0;
    if (item.indexOf('Milk') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif'
      bpmBoost = '30%';
      boostNumEpochs = 2;
      numPoints = 1;
    } else if (item.indexOf('Canteen') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
      bpmBoost = '50%';
      boostNumEpochs = 2;
      numPoints = 2;
    } else if (item.indexOf('Vegebite') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif'
      bpmBoost = '60%';
      boostNumEpochs = 2;
      numPoints = 4;
    } else if (item.indexOf('Baked Beans') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif'
      bpmBoost = '50%';
      boostNumEpochs = 3;
      numPoints = 8;
    } else if (item.indexOf('PotionX') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif'
      bpmBoost = '60%';
      boostNumEpochs = 3;
      numPoints = 16;
    } else if (item.indexOf('Gummy Raccoon') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif'
      bpmBoost = '65%';
      boostNumEpochs = 3;
      numPoints = 32;
    } else if (item.indexOf('Mushrooms') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif'
      bpmBoost = '70%';
      boostNumEpochs = 3;
      numPoints = 64;
    } else if (item.indexOf('Gold Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif'
      bpmBoost = '20%';
      boostNumEpochs = -1;
      numPoints = 1;
    } else if (item.indexOf('Emerald Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif'
      bpmBoost = '25%';
      boostNumEpochs = -1;
      numPoints = 2;
    } else if (item.indexOf('Ruby Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif'
      bpmBoost = '30%';
      boostNumEpochs = -1;
      numPoints = 4;
    } else if (item.indexOf('Diamond Ring') === 0 || item.indexOf('Diamond Ring') === 3) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif'
      bpmBoost = '35%';
      boostNumEpochs = -1;
      numPoints = 8;
    } else if (item.indexOf('CompoundX Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif'
      bpmBoost = '40%';
      boostNumEpochs = -1;
      numPoints = 16;
    } else if (item.indexOf('Blue Diamond Ring') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif'
      bpmBoost = '45%';
      boostNumEpochs = -1;
      numPoints = 32;
    } else if (item.indexOf('Light Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif'
      bpmBoost = '50%';
      boostNumEpochs = -1;
      numPoints = 1;
    } else if (item.indexOf('Dark Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif'
      bpmBoost = '55%';
      boostNumEpochs = -1;
      numPoints = 2;
    } else if (item.indexOf('Gold Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif'
      bpmBoost = '60%';
      boostNumEpochs = -1;
      numPoints = 4;
    } else if (item.indexOf('Psychedelic Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif'
      bpmBoost = '65%';
      boostNumEpochs = -1;
      numPoints = 8;
    } else if (item.indexOf('CompoundX Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif'
      bpmBoost = '70%';
      boostNumEpochs = -1;
      numPoints = 16;
    } else if (item.indexOf('Blue Diamond Wings') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif'
      bpmBoost = '75%';
      boostNumEpochs = -1;
      numPoints = 32;
    } else if (item.indexOf('Green Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
      squadBattlePowerLevelBoost = 10;
      boostNumEpochs = 1;
      numPoints = 1;
    } else if (item.indexOf('Blue Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
      squadBattlePowerLevelBoost = 20;
      boostNumEpochs = 1;
      numPoints = 2;
    } else if (item.indexOf('Red Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
      squadBattlePowerLevelBoost = 30;
      boostNumEpochs = 1;
      numPoints = 4;
    } else if (item.indexOf('Purple Squad Potion') > -1) {
      imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
      squadBattlePowerLevelBoost = 40;
      boostNumEpochs = 1;
      numPoints = 8;
    }


    return (
      <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center', width: '100%'}}>
        <div style={{position: 'relative', width: '200px', height: '200px'}}>
          <img src={imgSrc} className="my_nfts_nft_image_upgrades" style={{width: '200px', height: '200px'}}/>
          <div className="cellItemClear" onClick={() => removeResultCell(boxNumber)}><div className="cellItemClearInner">x</div></div>
        </div>
        <div className="my_nfts_box_text_forge_adv">
            {item} ({numPoints} points)
        </div>
        {bpmBoost && (
         <div className="my_nfts_box_text_forge_adv">
           {bpmBoost} Boost {boostNumEpochs >= 1 ? `/ ${boostNumEpochs} Epochs` : ``}
        </div>
        )}
        {squadBattlePowerLevelBoost && (
         <div className="my_nfts_box_text_forge_adv">
           +{squadBattlePowerLevelBoost} Power Level to All Squad Raccoons for 1 Epoch
        </div>
        )}
      </div>
    )
  }

  let itemsToForge = [];
  if (itemsToReceiveFromForge) {
    for (let boxNumber in itemsToReceiveFromForge) {
      let boxNumberInt = parseInt(boxNumber);
      let itemToForge = itemsToReceiveFromForge[boxNumber];
      let inputItemsForThisItem = cellItems[boxNumber];

      let pointsNeeded = getPointsForItem(itemToForge);

      let pointsProvided = 0;
      if (inputItemsForThisItem && inputItemsForThisItem.forEach) {
        inputItemsForThisItem.forEach(inputItem => {
          pointsProvided += getPointsForItem(inputItem);
        })
      }

      if (pointsProvided >= pointsNeeded) {
        itemsToForge.push({
          itemToForge,
          inputItemsForThisItem
        });
      }
    }
  }


  const renderNFTOrInventoryDestinationMenu = () => {
    let receivingSquadPotion = false;

    if (itemsToReceiveFromForge && itemsToReceiveFromForge[1]) {
      if (itemsToReceiveFromForge[1].indexOf('Squad Potion') >= 0) {
        receivingSquadPotion = true;
      }
    }

    return (
      <div className="forge_adv_row_mint_type_col" style={{paddingLeft: '20px'}}>
        {!receivingSquadPotion && <div style={{color: destRow1 === 'nft' ? '#e08705' : '#aaa', margin: '5px 0px', cursor: 'pointer'}} onClick={() => setDestRow1('nft')}><input type="radio" onChange={() => {}} checked={destRow1 === 'nft' ? true : false} style={{marginRight: '8px'}} />Mint to NFT (4₳)</div>}
        <div style={{color: destRow1 === 'inventory' ? '#e08705' : '#aaa', margin: '5px 0px', cursor: 'pointer'}} onClick={() => setDestRow1('inventory')}><input type="radio" onChange={() => {}} checked={destRow1 === 'inventory' ? true : false} style={{marginRight: '8px'}} />Send to Inventory (3₳)</div>
      </div>   
    )
  }

  const renderBasket = () => {
    if (!itemsToForge || itemsToForge.length < 1) {
      return <div />;
    }

    return (
      <div style={{paddingLeft: '10%', paddingRight: '10%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px', marginBottom: '20px', fontSize: '21px'}}>
        <div style={{color: '#ededed', marginBottom: '10px'}}>We will forge the following:</div>
        {itemsToForge.map((item, i) => {
          let itemToForge = item.itemToForge;
          let inputItemsForThisItem = item.inputItemsForThisItem;
          return (
            <div key={`itemToForge_${i}`} >
              <div style={{color: '#ededed', marginBottom: '5px'}}>{inputItemsForThisItem.length} items will forge into {itemToForge}</div>
            </div>
          )
        })}
      </div>
    )
  }

  let enableForgeItemsButton = false;
  if (itemsToForge && itemsToForge.length > 0) {
    enableForgeItemsButton = true;
  }

  return (
    <>
      <div className="home_wrapper">
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>

        {!state.doneInitialWalletLoad && (
          <div className="my_nfts_container">
            <ClipLoader
              color={'#FFF'}
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}





        {state.doneInitialWalletLoad && (
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px', marginBottom: '20px'}}>

            <div style={{width: '100%', marginBottom: '70px'}}>
              <div className="forge_adv_row">
                <div className="forge_adv_row_left_1_col">
                  <div className="forge_adv_box_left" style={{
                    border: (itemsToReceiveFromForge && itemsToReceiveFromForge[1] && getInputNumPoints(1) >= getPointsForItem(itemsToReceiveFromForge[1])) ? '3px solid #0f0' : '3px solid #444'
                  }}>
                    {cellItems[1] && renderCellItems(1)}
                    <button disabled={!itemsToReceiveFromForge || !itemsToReceiveFromForge[1]} className={(itemsToReceiveFromForge && itemsToReceiveFromForge[1]) ? "addItemToForgeButton" : "addItemToForgeButtonDisabled"} onClick={(itemsToReceiveFromForge && itemsToReceiveFromForge[1]) ? () => {setCellNumberToSelectItemFor(1)} : () => {}}>
                      Add Item to Forge
                    </button>
                    {(itemsToReceiveFromForge && itemsToReceiveFromForge[1] && cellItems[1]) && (
                      <div className={"forge_adv_num_points_so_far"} style={{
                        color: getInputNumPoints(1) < getPointsForItem(itemsToReceiveFromForge[1]) ? '#f00' : '#0F0'
                      }}>
                        Num points so far: {getInputNumPoints(1)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="forge_adv_row_equals_sign_column">
                  =
                </div>
                <div className="forge_adv_row_right_col">
                  <div className="forge_adv_box_right" style={{
                    border: (itemsToReceiveFromForge && itemsToReceiveFromForge[1] && getInputNumPoints(1) >= getPointsForItem(itemsToReceiveFromForge[1])) ? '3px solid #0f0' : '3px solid #e08705'
                  }}>
                    {renderResultCell(1)}
                    <button className={"addItemToForgeButton"} onClick={() => setCellNumberToReceiveForgeItem(1)}>
                      Item to Receive
                    </button>
                    <div style={{display: 'flex', flexDirection: 'row', color: '#efdbb8'}}>
                    {(itemsToReceiveFromForge && itemsToReceiveFromForge[1]) && (
                      <div className={"forge_adv_num_points_so_far"} style={{
                        color: getInputNumPoints(1) < getPointsForItem(itemsToReceiveFromForge[1]) ? '#fff' : '#0F0'
                      }}>
                        Num points needed: {getPointsForItem(itemsToReceiveFromForge[1])}
                      </div>
                    )}
                  </div>
                  </div>
                </div>                
                  {renderNFTOrInventoryDestinationMenu()}             
              </div>
            </div>

          </div>

        )}

        

        {renderBasket()}

        <center style={{marginTop: '100px'}}>
          <button className={enableForgeItemsButton ? "forgeItemsBtn" : "forgeItemsBtnDisabled"} onClick={enableForgeItemsButton ? (e) => {
            e.preventDefault();
            e.stopPropagation();
            onForgeItems();
          } : undefined}>
            Forge Items (Advanced)
          </button>
        </center>

        <SelectItemModal source="forge" advancedForge={"true"} show={cellNumberToSelectItemFor} buttonLabel="Select" boxNumber={cellNumberToSelectItemFor} cellItems={cellItems} refreshInventory={refreshInventory} raccoonUpgradesInWallet={raccoonUpgradesInWallet} itemsToReceiveFromForge={itemsToReceiveFromForge} includeAttachables={true} includeConsumables={true} includeSquadPotions={true} inventoryItems={inventory} getUpgradeStatusData={getUpgradeStatusData} parentSetToastColor={setToastColor} selectItem={selectItem} close={() => {setCellNumberToSelectItemFor(null);}} />

        <SelectItemToForgeModal source="forge" advancedForge={"true"} show={cellNumberToReceiveForgeItem} buttonLabel="Select" boxNumber={cellNumberToReceiveForgeItem} cellItems={cellItems} refreshInventory={refreshInventory} raccoonUpgradesInWallet={raccoonUpgradesInWallet} includeAttachables={true} includeConsumables={true} includeSquadPotions={true} inventoryItems={inventory} getUpgradeStatusData={getUpgradeStatusData} parentSetToastColor={setToastColor} selectItem={selectForgeItemToReceive} close={() => {setCellNumberToReceiveForgeItem(null);}} />

        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />

      </div>
    </>
  );
};


export default ForgeAdv;

