import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import dayjs from "dayjs";
import RaccoonAvatar from './RaccoonAvatar';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

const browser = detect();

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const GET_CONVERSATIONS = gql`
  query GetConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    conversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      result
      conversations
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($messageId: String!, $myWalletAddresses: String!, $mailboxId: String) {
    messages(messageId: $messageId, myWalletAddresses: $myWalletAddresses, mailboxId: $mailboxId) {
      result
      messages
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($fromRaccoonNum: Int!, $toRaccoonNum: Int!, $messageContents: String!, $messageType: String! ) {
    sendMessage (fromRaccoonNum: $fromRaccoonNum, toRaccoonNum: $toRaccoonNum, messageContents: $messageContents, messageType: $messageType ) {
      result
      logEntries
    }
  }
`;

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers) {
      results
    }
  }
`;

const SQUAD_ADD_RACCOON = gql`
  mutation SquadAddRaccoon($officerRaccoonNum: Int!, $raccoonNum: Int!, $myWalletAddresses: String!, $isJoinRequest: String ) {
    squadAddRaccoon( officerRaccoonNum: $officerRaccoonNum, raccoonNum: $raccoonNum, myWalletAddresses: $myWalletAddresses, isJoinRequest: $isJoinRequest ) {
      result
      logEntries
      officerRaccoon
      memberRaccoon
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;



const MessagesPage = (props, v2, v3) => {
  const [tab, setTab] = useState('Conversations');

  const [conversations, setConversations] = useState(null);
  const [currentConversation, setCurrentConversation] = useState(null);

  const [messageFromRaccoon, setMessageFromRaccoon] = useState("");
  const [messageToRaccoon, setMessageToRaccoon] = useState("");
  const [messageContents, setMessageContents] = useState("");

  const [messages, setMessages] = useState(null);
  const [leftRaccoon, setLeftRaccoon] = useState(null);
  const [rightRaccoon, setRightRaccoon] = useState(null);
  const [iOwnLeftRaccoon, setIOwnLeftRaccoon] = useState(null);
  const [iOwnRightRaccoon, setIOwnRightRaccoon] = useState(null);

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonSquads, setRacconSquads] = useState(null);

  const [toastColor, setToastColor] = useState('#e08705');

  const messagesContainerRef = useRef(null)
  const messagesEndRef = useRef(null)

  const { state, mailboxId, refetchRaccoonsSquads, globalLucid } = useContext(GlobalStateContext);

  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
      raccoonNumsInWallet.push(parseInt(raccoonNum));
    })
  }

  const { data: dataConversations, loading: loadingConversations, error: errorConversations, refetch: refetchConversations } = useQuery(GET_CONVERSATIONS, {
    variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }
  });

  const [getMessages, { loading: loadingGetMessages, error: errorGetMessages, data: dataGetMessages, refetch: refetchGetMessages }] = useLazyQuery(GET_MESSAGES);

  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);


  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          // setMailboxId(response.setReadMessagesTime.mailboxId);
        }
    }
  });

  const [sendMessage, { data: dataSendMessage, loading: loadingSendMessage, error: errorSendMessage }] = useMutation(SEND_MESSAGE, 
    {
      refetchQueries: [{ query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }],
      onCompleted: response => {
        if (response && response.sendMessage && response.sendMessage.result) {
          if (response.sendMessage.result.indexOf('fail') >= 0 || response.sendMessage.result.indexOf('Fail') >= 0 ) {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Error sending message");
            }, 200);
          } else if (response.sendMessage.result === 'success') {
            setToastColor('#14711f');
            setTimeout(() => {
              setMessageFromRaccoon("");
              setMessageToRaccoon("");
              setMessageContents("");
              toast(`Message successfully sent`);
              setTab('Conversations');
            }, 200);
            refetchMessages(currentConversation);
          }
        }
    }
  });

  const [squadAddRaccoon, { data: dataSquadAddRaccoon, loading: loadingSquadAddRaccoon, error: errorSquadAddRaccoon }] = useMutation(SQUAD_ADD_RACCOON, 
    {
      refetchQueries: [
        { query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  },
        { query: GET_MESSAGES, variables: { messageId: messages && messages[0] ? messages[0].messageId : null, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }
      ],
      onCompleted: response => {
        if (response && response.squadAddRaccoon && response.squadAddRaccoon.result) {
          if (response.squadAddRaccoon.result === 'Fail - Squad is full') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("The squad is already full and cannot add more raccoons.")
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Fail - Member already in another squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} is already in another squad and cannot join a new squad.`)
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Fail - Member already in this squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} is already in this squad.`)
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Success') {
            setToastColor('#14711f');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} has been added to the squad.`)
            }, 200);
            refetchMessages(currentConversation);
          }

          refetchRaccoonsSquads();
          if (messages && messages[0]) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify([messages[0].fromRaccoon, messages[0].toRaccoon])}});
          }
        }
    }
  });

  const localRefreshWallet = async (parms) => {
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
          if (raccoonNums && raccoonNums.length > 0) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify(raccoonNums)}});
          }
        }

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
        }

        setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet();
    }
  }, [state.api])

  useEffect(() => {
    if (currentConversation) {
      refetchMessages(currentConversation);
    }
  }, [])

  useEffect(() => {
    if (dataConversations && dataConversations.conversations && dataConversations.conversations.conversations) {
      setConversations(JSON.parse(dataConversations.conversations.conversations));
      let convsObj = JSON.parse(dataConversations.conversations.conversations);
      if (!currentConversation && convsObj) {
        if (convsObj[0]) {
          onClickConversation(convsObj[0]);
        }
      }
    }
  }, [dataConversations, loadingConversations]);

  useEffect(() => {
    if (dataGetMessages && dataGetMessages.messages && dataGetMessages.messages.messages) {
      let messagesObj = JSON.parse(dataGetMessages.messages.messages)
      setMessages(messagesObj);
      if (messagesObj[0]) {
        let messageZero = messagesObj[0];
        if (messageZero && raccoonsInWallet && raccoonSquads && raccoonNumsInWallet) {
          let iOwnFromRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(messageZero.fromRaccoon))) {
            iOwnFromRaccoon = true;
          }
          let iOwnToRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(messageZero.toRaccoon))) {
            iOwnToRaccoon = true;
          }

          let leftRaccoonInner = messageZero.fromRaccoon;
          let rightRaccoonInner = messageZero.toRaccoon;
          if (iOwnFromRaccoon) {
            leftRaccoonInner = messageZero.fromRaccoon;
            rightRaccoonInner = messageZero.toRaccoon;
          } else if (iOwnToRaccoon) {
            leftRaccoonInner = messageZero.toRaccoon;
            rightRaccoonInner = messageZero.fromRaccoon;
          }

          let iOwnLeftRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(leftRaccoonInner))) {
            iOwnLeftRaccoon = true;
          }
          let iOwnRightRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(rightRaccoonInner))) {
            iOwnRightRaccoon = true;
          }

          setLeftRaccoon(leftRaccoonInner);
          setRightRaccoon(rightRaccoonInner);
          setIOwnLeftRaccoon(iOwnLeftRaccoon);
          setIOwnRightRaccoon(iOwnRightRaccoon);
        }
        getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify([messagesObj[0].fromRaccoon, messagesObj[0].toRaccoon])}});
      }
    }
  }, [dataGetMessages]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [messages]);

  useEffect(() => {
    refetchConversations({ myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' });
  }, [state]);

  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      let raccoonSquads = {};
      results.forEach(result => {
        raccoonSquads[result.racNum] = result;
      });
      setRacconSquads({...raccoonSquads});
    }
  }, [dataGetRaccoonsSquads]);

  useEffect(() => {
    if (currentConversation) {
      refetchGetMessages({ messageId: currentConversation.latestMessageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none', mailboxId });
    }
  }, [mailboxId]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchMessages(currentConversation);
    }, 15000);
    return () => clearInterval(interval);
  }, [currentConversation]);

  if (browser && browser.name) {
    if (browser.name === 'firefox' || browser.name === 'safari') {
      return (
        <div className="home_wrapper">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          <center>
            <div className="global_leaderboard_title_text" style={{marginTop: '100px', textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              Only Chrome, Brave and Edge browsers can view this page
            </div>  
          </center>    

        </div>
      )
    }
  }

  const refetchMessages = async (conversation) => {
    if (conversation) {
      getMessages({variables: { messageId: conversation.latestMessageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }});
    }
  }

  const onClickConversation = async (conversation) => {
    setCurrentConversation(conversation);
    getMessages({variables: { messageId: conversation.latestMessageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }});
  }

  const scrollToBottom = () => {
    if (messagesContainerRef && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight -30;
    }
  }

  if (!raccoonsInWallet || raccoonsInWallet.length < 1) {
    return (
      <div />
    )
  }

  if (!conversations || conversations.length < 1) {
    return (
      <div className="messages_no_conversations">
        <div className="messages_no_conversations_text">
          You do not have any conversations in your Inbox.
        </div>
        <div className="messages_no_conversations_text">
          Visit a Raccoon's Detail Info page to start a conversation with a Raccoon.
        </div>
      </div>
    )
  }

  const renderFromRaccoonPulldown = () => {
    let options = [];
    if (raccoonsInWallet) {
      raccoonsInWallet.forEach(rac => {
        options.push({value: rac.racNum, label: rac.racNum});
      });
    }

    const customStyles = {
        control: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            border: '#222',
            // This line disable the blue border
            boxShadow: 'none'
          };
        },
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            backgroundColor: isFocused ? "#222" : "#000",
          };
        },
        singleValue: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            color: "#efdbb8"
          };
        }
    };

    return (
      <>
         <Select
          defaultValue={"Select"}
          onChange={setMessageFromRaccoon}
          options={options}
          classNames={{
            control: (state) => 'from_raccoon_pulldown_control',
            menu: (state) => 'from_raccoon_pulldown_menu',
          }}
          styles={customStyles}
        />
      </>
    )
  }

  const renderMessage = (message) => {
    let sentDayJS = dayjs(message.createdTime );

    let messageContent = message.messageContents;
    if (message.messageType === 'squadInvite') {
      messageContent = "Would you like to join my squad?";
    } else if (message.messageType === 'squadJoinRequest') {
      messageContent = "I'd like to join your squad.";
    }

    let iOwnFromRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(message.fromRaccoon))) {
      iOwnFromRaccoon = true;
    }

    let iOwnToRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(message.toRaccoon))) {
      iOwnToRaccoon = true;
    }

    if (!iOwnFromRaccoon && !iOwnToRaccoon) {
      return;
    }

    let bothRaccoonsInSameSquad = false;
    if (raccoonSquads[message.fromRaccoon] && raccoonSquads[message.toRaccoon] && raccoonSquads[message.fromRaccoon].squadImIn && raccoonSquads[message.toRaccoon].squadILead) {
      if (raccoonSquads[message.fromRaccoon].squadImIn.squadNum === raccoonSquads[message.toRaccoon].squadILead.squadNum) {
        bothRaccoonsInSameSquad = true;
      }
    } 
    if (raccoonSquads[message.toRaccoon] && raccoonSquads[message.fromRaccoon] && raccoonSquads[message.toRaccoon].squadImIn && raccoonSquads[message.fromRaccoon].squadILead) {
      if (raccoonSquads[message.toRaccoon].squadImIn.squadNum === raccoonSquads[message.fromRaccoon].squadILead.squadNum) {
        bothRaccoonsInSameSquad = true;
      }
    }     

    let youOrThemPrefix = 'You';
    if (!iOwnFromRaccoon) {
      youOrThemPrefix = 'Them';
    }

    let actionMarkup = (
      <>
        {message.messageType === 'squadInvite' && iOwnToRaccoon &&
          <>
            {iOwnToRaccoon && !bothRaccoonsInSameSquad && message.processed !== 'true' &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <div className="message_page_join_button" onClick={() => {
                  squadAddRaccoon({ variables: { officerRaccoonNum: parseInt(message.fromRaccoon), raccoonNum: parseInt(message.toRaccoon), myWalletAddresses: 'testAddresses', isJoinRequest: `false` } });
                }}>
                  Join Squad
                </div>
              </div>
            }
            {iOwnToRaccoon && (bothRaccoonsInSameSquad || message.processed === 'true') &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <Tooltip placement="bottom" content={<div className="message_page_join_button_tooltip">Already in the same squad or already processed this request</div>}>
                  <div className="message_page_join_button" style={{backgroundColor: '#444', backgroundImage: 'inherit'}}>
                    Join Squad
                  </div>
                </Tooltip>
              </div>
            }
          </>      
        }
        {message.messageType === 'squadJoinRequest' && iOwnToRaccoon &&
          <>
            {iOwnToRaccoon && !bothRaccoonsInSameSquad && message.processed !== 'true' &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <div className="message_page_join_button" onClick={() => {
                  squadAddRaccoon({ variables: { officerRaccoonNum: parseInt(message.toRaccoon), raccoonNum: parseInt(message.fromRaccoon), myWalletAddresses: 'testAddresses', isJoinRequest: `true` } });
                }}>
                  Accept Join Request
                </div>
              </div>
            }
            {iOwnToRaccoon && (bothRaccoonsInSameSquad || message.processed === 'true') &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <Tooltip placement="bottom" content={<div className="message_page_join_button_tooltip">Already in the same squad or already processed this request</div>}>
                  <div className="message_page_join_button" style={{backgroundColor: '#444', backgroundImage: 'inherit'}}>
                    Accept Join Request
                  </div>
                </Tooltip>
              </div>
            }
          </>      
        }
      </>
    );

    return (
      <div key={message.messageId} className="message_page_message_container">
        <div className="message_page_container">
          <div className="message_page_row">
            {iOwnFromRaccoon &&
              <div className="message_page_row_value" style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <a href={`/raccoon?r=${message.fromRaccoon}`} target="_blank" >
                  <RaccoonAvatar racNum={message.fromRaccoon} hidePreview /> 
                </a>
                <div className="message_page_date_message_div" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <div className="message_page_message_bubble">
                    {messageContent}
                    {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && actionMarkup}
                  </div>
                  <div className="message_page_date_time">
                    {sentDayJS.format('MMM D, H:mm A')}
                  </div>
                </div>
              </div>
            }
            {!iOwnFromRaccoon &&
              <div className="message_page_row_value" style={{alignItems: 'flex-start', justifyContent: 'flex-end'}}>
                <div className="message_page_date_message_div" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
                  <div className="message_page_message_bubble">
                    {messageContent}
                    {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && actionMarkup}
                  </div>
                  <div className="message_page_date_time" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', marginRight: '10px'}}>
                    {sentDayJS.format('MMM D, H:mm A')}
                  </div>
                </div>
                <a href={`/raccoon?r=${message.fromRaccoon}`} target="_blank" >
                  <RaccoonAvatar racNum={message.fromRaccoon} hidePreview /> 
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
  

  const renderNewFormat = () => {

    return (
      <div className="messages_new_outer_2_container">
        <div className="messages_new_outer_container">
          <div className="messages_new_inner_container">
            <div className="messages_new_left_col">
              {conversations && conversations.length >= 1 && conversations.map(conversation => {
                let messageContentPreview = conversation.lastMessageContents;
                if (messageContentPreview.length > 400) {
                  messageContentPreview = messageContentPreview.substring(0, 398) + '..';
                }
            
                let messageType = conversation.lastMessageType;
                if (messageType === 'squadInvite') {
                  messageContentPreview = "Would you like to join my squad?";
                } else if (messageType === 'squadJoinRequest') {
                  messageContentPreview = "I'd like to join your squad.";
                }

                return (
                  <div key={conversation.conversationId} style={{backgroundColor: currentConversation && currentConversation.conversationId === conversation.conversationId ? '#222' : undefined}}>
                    <div className="messages_new_left_row" onClick={() => {
                      onClickConversation(conversation);
                    }}>
                      <div className="messages_new_left_rac_row">
                        <div className="messages_new_left_rac_info">
                          <RaccoonAvatar racNum={conversation.raccoon1} hidePreview /> 
                          <div className="messages_new_left_rac_num">{conversation.raccoon1}</div>
                        </div>
                        <div className="messages_new_left_rac_info" style={{minWidth: '70px', paddingLeft: '15px'}}>
                          <RaccoonAvatar racNum={conversation.raccoon2} hidePreview /> 
                          <div className="messages_new_left_rac_num">{conversation.raccoon2}</div>
                        </div>
                        <div className="messages_new_left_msg_preview" style={{paddingLeft: '15px'}}>
                          <div className="messages_new_left_msg_preview_sender">{conversation.raccoon1}:</div>
                          <div className="messages_new_left_msg_preview_content">{messageContentPreview}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="messages_new_right_col">
              <div className="message_v2_page_messages_container_outer" ref={messagesContainerRef}>
                <div className="message_v2_page_messages_container">
                  {messages && raccoonSquads && raccoonsInWallet && raccoonsInWallet.length > 0 && messages.map(message => renderMessage(message))}
                  {messages && raccoonSquads && raccoonsInWallet && raccoonsInWallet.length > 0 && <div ref={messagesEndRef}>test</div>}
                  {!messages && <div className="message_v2_page_no_messages_container">no messages</div>}
                </div>
              </div>

              {currentConversation && (
                <div className="message_page_send_message_container_outer">
                  <div className="message_page_send_message_container">
                      <textarea 
                        className="message_page_send_message_textarea" 
                        rows={5}
                        value={messageContents} 
                        onChange={e => {
                          let inputString = e.target.value;
                          setMessageContents(inputString);
                        }}
                      />
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{display: 'flex', flex: 1, alignItems: 'flex-start'}}>
                          <div className="message_page_send_message_button" onClick={() => {
                            if (!messages || !messages[0]) {
                              return;
                            }
                            let iOwnFromRaccoon = false;
                            if (raccoonNumsInWallet.includes(parseInt(messages[0].fromRaccoon))) {
                              iOwnFromRaccoon = true;
                            }
                            let iOwnToRaccoon = false;
                            if (raccoonNumsInWallet.includes(parseInt(messages[0].toRaccoon))) {
                              iOwnToRaccoon = true;
                            }

                            if (!iOwnFromRaccoon && !iOwnToRaccoon) {
                              return;
                            }

                            let fromRaccoon = messages[0].fromRaccoon;
                            let toRaccoon = messages[0].toRaccoon;
                            if (!iOwnFromRaccoon && iOwnToRaccoon) {
                              fromRaccoon = messages[0].toRaccoon;
                              toRaccoon = messages[0].fromRaccoon;
                            }

                            setMessageContents("");
                            sendMessage({ variables: { fromRaccoonNum: parseInt(fromRaccoon), toRaccoonNum: parseInt(toRaccoon), messageContents: messageContents, messageType: 'chat'  } });
                          }}>
                              Send Message
                          </div>
                          {loadingSendMessage &&
                            <div style={{marginTop: '15px', marginLeft: '10px'}}>
                              <ClipLoader
                                color={'#FFF'}
                                loading={true}
                                size={12}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          }
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

  }



  return (
    <>
      <div className="home_wrapper" style={{paddingBottom: '0px'}}>

        {renderNewFormat()}

        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={6000} />
        
      </div>
    </>
  );
};


export default MessagesPage;

