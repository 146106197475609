import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useMutation,
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import RaccoonAvatar from './RaccoonAvatar';
import dayjs from "dayjs";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import SendMessageComponent from "./SendMessageComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { detect } from 'detect-browser';

const browser = detect();

const GET_MESSAGES = gql`
  query GetMessages($messageId: String!, $myWalletAddresses: String!, $mailboxId: String) {
    messages(messageId: $messageId, myWalletAddresses: $myWalletAddresses, mailboxId: $mailboxId) {
      result
      messages
    }
  }
`;

const GET_CONVERSATIONS = gql`
  query GetConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    conversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      result
      conversations
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($fromRaccoonNum: Int!, $toRaccoonNum: Int!, $messageContents: String!, $messageType: String! ) {
    sendMessage (fromRaccoonNum: $fromRaccoonNum, toRaccoonNum: $toRaccoonNum, messageContents: $messageContents, messageType: $messageType ) {
      result
      logEntries
    }
  }
`;

const SQUAD_ADD_RACCOON = gql`
  mutation SquadAddRaccoon($officerRaccoonNum: Int!, $raccoonNum: Int!, $myWalletAddresses: String!, $isJoinRequest: String ) {
    squadAddRaccoon( officerRaccoonNum: $officerRaccoonNum, raccoonNum: $raccoonNum, myWalletAddresses: $myWalletAddresses, isJoinRequest: $isJoinRequest ) {
      result
      logEntries
      officerRaccoon
      memberRaccoon
    }
  }
`;

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers) {
      results
    }
  }
`;


const MessagePage = (props) => {
  const search = useLocation().search;
  const messageId = new URLSearchParams(search).get("m");
  
  const [showSendMessage, setShowSendMessage] = useState(true);
  const [messages, setMessages] = useState(null);

  const [resultMessage, setResultMessage] = useState(null);

  const [toastColor, setToastColor] = useState('#e08705');

  const [raccoonSquads, setRacconSquads] = useState(null);

  const [leftRaccoon, setLeftRaccoon] = useState(null);
  const [rightRaccoon, setRightRaccoon] = useState(null);
  const [iOwnLeftRaccoon, setIOwnLeftRaccoon] = useState(null);
  const [iOwnRightRaccoon, setIOwnRightRaccoon] = useState(null);


  const [messageContents, setMessageContents] = useState("");

  const { state, mailboxId, refetchRaccoonsSquads } = useContext(GlobalStateContext);

  let raccoonsInWallet = null;
  let raccoonUpgradesInWallet = null;

  if (state && state.raccoonsInWallet) {
    raccoonsInWallet = state.raccoonsInWallet;
  }
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }

  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      raccoonNumsInWallet.push(rac.racNum);
    })
  }

  const [sendMessage, { data: dataSendMessage, loading: loadingSendMessage, error: errorSendMessage }] = useMutation(SEND_MESSAGE, 
    {
      refetchQueries: [
        { query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  },
        { query: GET_MESSAGES, variables: { messageId: messages && messages[0] ? messages[0].messageId : null, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }
      ],
      onCompleted: response => {
        // if (response && response.squadAddMyRaccoon && response.squadAddMyRaccoon.result) {
          // refetchRaccoonsSquads();
        // }
    }
  });

  const { data: dataMessages, loading: loadingMessages, error: errorMessages, refetch: refetchMessages } = useQuery(GET_MESSAGES, {
    variables: { messageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }
  });

  useEffect(() => {
    if (dataMessages && dataMessages.messages && dataMessages.messages.messages) {
      let messagesObj = JSON.parse(dataMessages.messages.messages)
      setMessages(messagesObj);
      if (messagesObj[0]) {
        let messageZero = messagesObj[0];
        if (messageZero && raccoonsInWallet && raccoonSquads && state.doneGetRaccoonsSquads) {
          let iOwnFromRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(messageZero.fromRaccoon))) {
            iOwnFromRaccoon = true;
          }
          let iOwnToRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(messageZero.toRaccoon))) {
            iOwnToRaccoon = true;
          }

          let leftRaccoonInner = messageZero.fromRaccoon;
          let rightRaccoonInner = messageZero.toRaccoon;
          if (iOwnFromRaccoon) {
            leftRaccoonInner = messageZero.fromRaccoon;
            rightRaccoonInner = messageZero.toRaccoon;
          } else if (iOwnToRaccoon) {
            leftRaccoonInner = messageZero.toRaccoon;
            rightRaccoonInner = messageZero.fromRaccoon;
          }

          let iOwnLeftRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(leftRaccoonInner))) {
            iOwnLeftRaccoon = true;
          }
          let iOwnRightRaccoon = false;
          if (raccoonNumsInWallet.includes(parseInt(rightRaccoonInner))) {
            iOwnRightRaccoon = true;
          }

          setLeftRaccoon(leftRaccoonInner);
          setRightRaccoon(rightRaccoonInner);
          setIOwnLeftRaccoon(iOwnLeftRaccoon);
          setIOwnRightRaccoon(iOwnRightRaccoon);
        }
        getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify([messagesObj[0].fromRaccoon, messagesObj[0].toRaccoon])}});
      }
    }
  }, [dataMessages, loadingMessages]);

  useEffect(() => {
    if (!messages || !messages[0]) {
      return;
    }
    let messageZero = messages[0];
    let iOwnFromRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(messageZero.fromRaccoon))) {
      iOwnFromRaccoon = true;
    }
    let iOwnToRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(messageZero.toRaccoon))) {
      iOwnToRaccoon = true;
    }

    let leftRaccoonInner = messageZero.fromRaccoon;
    let rightRaccoonInner = messageZero.toRaccoon;
    if (iOwnFromRaccoon) {
      leftRaccoonInner = messageZero.fromRaccoon;
      rightRaccoonInner = messageZero.toRaccoon;
    } else if (iOwnToRaccoon) {
      leftRaccoonInner = messageZero.toRaccoon;
      rightRaccoonInner = messageZero.fromRaccoon;
    }

    let iOwnLeftRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(leftRaccoonInner))) {
      iOwnLeftRaccoon = true;
    }
    let iOwnRightRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(rightRaccoonInner))) {
      iOwnRightRaccoon = true;
    }

    setLeftRaccoon(leftRaccoonInner);
    setRightRaccoon(rightRaccoonInner);
    setIOwnLeftRaccoon(iOwnLeftRaccoon);
    setIOwnRightRaccoon(iOwnRightRaccoon);
  }, [state]);

  const [getRaccoonsSquads, { loading: loadingGetRaccoonsSquads, error: errorGetRaccoonsSquads, data: dataGetRaccoonsSquads, refetch: refetchGetRaccoonsSquads }] = useLazyQuery(GET_RACCOONS_SQUADS);

  const [squadAddRaccoon, { data: dataSquadAddRaccoon, loading: loadingSquadAddRaccoon, error: errorSquadAddRaccoon }] = useMutation(SQUAD_ADD_RACCOON, 
    {
      refetchQueries: [
        { query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  },
        { query: GET_MESSAGES, variables: { messageId: messages && messages[0] ? messages[0].messageId : null, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }
      ],
      onCompleted: response => {
        if (response && response.squadAddRaccoon && response.squadAddRaccoon.result) {
          if (response.squadAddRaccoon.result === 'Fail - Squad is full') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Your squad is already full and cannot add more raccoons.")
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Fail - Member already in another squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} is already in another squad and cannot join a new squad.`)
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Fail - Member already in this squad') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} is already in this squad.`)
            }, 200);
          } else if (response.squadAddRaccoon.result === 'Success') {
            setToastColor('#14711f');
            setTimeout(() => {
              toast(`Raccoon #${response.squadAddRaccoon.memberRaccoon} has been added to your squad.`)
            }, 200);
          }
          refetchRaccoonsSquads();
          if (messages && messages[0]) {
            getRaccoonsSquads({variables: {raccoonNumbers: JSON.stringify([messages[0].fromRaccoon, messages[0].toRaccoon])}});
          }
        }
    }
  });

  useEffect(() => {
    if (dataGetRaccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads && dataGetRaccoonsSquads.raccoonsSquads.results) {
      let results = JSON.parse(dataGetRaccoonsSquads.raccoonsSquads.results);
      let raccoonSquads = {};
      results.forEach(result => {
        raccoonSquads[result.racNum] = result;
      });
      setRacconSquads({...raccoonSquads});
    }
  }, [dataGetRaccoonsSquads]);

  useEffect(() => {
    refetchMessages({ messageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none', mailboxId });
  }, [mailboxId]);

  if (browser && browser.name) {
    if (browser.name === 'firefox' || browser.name === 'safari') {
      return (
        <div className="home_wrapper">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
          </div>

          <center>
            <div className="global_leaderboard_title_text" style={{marginTop: '100px', textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              Only Chrome, Brave and Edge browsers can view this page
            </div>  
          </center>    

        </div>
      )
    }
  }

  const renderMessage = (message) => {
    let sentDayJS = dayjs(message.createdTime );

    let messageContent = message.messageContents;
    if (message.messageType === 'squadInvite') {
      messageContent = "Would you like to join my squad?";
    } else if (message.messageType === 'squadJoinRequest') {
      messageContent = "I'd like to join your squad.";
    }

    let iOwnFromRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(message.fromRaccoon))) {
      iOwnFromRaccoon = true;
    }

    let iOwnToRaccoon = false;
    if (raccoonNumsInWallet.includes(parseInt(message.toRaccoon))) {
      iOwnToRaccoon = true;
    }

    if (!iOwnFromRaccoon && !iOwnToRaccoon) {
      return;
    }

    let bothRaccoonsInSameSquad = false;
    if (raccoonSquads[message.fromRaccoon] && raccoonSquads[message.toRaccoon] && raccoonSquads[message.fromRaccoon].squadImIn && raccoonSquads[message.toRaccoon].squadILead) {
      if (raccoonSquads[message.fromRaccoon].squadImIn.squadNum === raccoonSquads[message.toRaccoon].squadILead.squadNum) {
        bothRaccoonsInSameSquad = true;
      }
    } 
    if (raccoonSquads[message.toRaccoon] && raccoonSquads[message.fromRaccoon] && raccoonSquads[message.toRaccoon].squadImIn && raccoonSquads[message.fromRaccoon].squadILead) {
      if (raccoonSquads[message.toRaccoon].squadImIn.squadNum === raccoonSquads[message.fromRaccoon].squadILead.squadNum) {
        bothRaccoonsInSameSquad = true;
      }
    }     

    let youOrThemPrefix = 'You';
    if (!iOwnFromRaccoon) {
      youOrThemPrefix = 'Them';
    }

    let actionMarkup = (
      <>
        {message.messageType === 'squadInvite' && iOwnToRaccoon &&
          <>
            {iOwnToRaccoon && !bothRaccoonsInSameSquad &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <div className="message_page_join_button" onClick={() => {
                  squadAddRaccoon({ variables: { officerRaccoonNum: parseInt(message.fromRaccoon), raccoonNum: parseInt(message.toRaccoon), myWalletAddresses: 'testAddresses', isJoinRequest: `false` } });
                }}>
                  Join Squad
                </div>
              </div>
            }
            {iOwnToRaccoon && bothRaccoonsInSameSquad &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <Tooltip placement="bottom" content={<div className="message_page_join_button_tooltip">Already in the same squad</div>}>
                  <div className="message_page_join_button" style={{backgroundColor: '#444', backgroundImage: 'inherit'}}>
                    Join Squad
                  </div>
                </Tooltip>
              </div>
            }
          </>      
        }
        {message.messageType === 'squadJoinRequest' && iOwnToRaccoon &&
          <>
            {iOwnToRaccoon && !bothRaccoonsInSameSquad &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <div className="message_page_join_button" onClick={() => {
                  squadAddRaccoon({ variables: { officerRaccoonNum: parseInt(message.toRaccoon), raccoonNum: parseInt(message.fromRaccoon), myWalletAddresses: 'testAddresses', isJoinRequest: `true` } });
                }}>
                  Accept Join Request
                </div>
              </div>
            }
            {iOwnToRaccoon && bothRaccoonsInSameSquad &&
              <div className="message_page_join_button_container" style={{justifyContent: 'flex-end'}}>
                <Tooltip placement="bottom" content={<div className="message_page_join_button_tooltip">Already in the same squad</div>}>
                  <div className="message_page_join_button" style={{backgroundColor: '#444', backgroundImage: 'inherit'}}>
                    Accept Join Request
                  </div>
                </Tooltip>
              </div>
            }
          </>      
        }
      </>
    );

    return (
      <div key={message.messageId} className="message_page_message_container">
        <div className="message_page_container">
          <div className="message_page_row">
            {iOwnFromRaccoon &&
              <div className="message_page_row_value" style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <a href={`/raccoon?r=${message.fromRaccoon}`} target="_blank" >
                  <RaccoonAvatar racNum={message.fromRaccoon} /> 
                </a>
                <div className="message_page_date_message_div" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <div className="message_page_message_bubble">
                    {messageContent}
                    {actionMarkup}
                  </div>
                  <div className="message_page_date_time">
                    {sentDayJS.format('MMM D, H:mm A')}
                  </div>
                </div>
              </div>
            }
            {!iOwnFromRaccoon &&
              <div className="message_page_row_value" style={{alignItems: 'flex-start', justifyContent: 'flex-end'}}>
                <div className="message_page_date_message_div" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
                  <div className="message_page_message_bubble">
                    {messageContent}
                    {actionMarkup}
                  </div>
                  <div className="message_page_date_time" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', marginRight: '10px'}}>
                    {sentDayJS.format('MMM D, H:mm A')}
                  </div>
                </div>
                <a href={`/raccoon?r=${message.fromRaccoon}`} target="_blank" >
                  <RaccoonAvatar racNum={message.fromRaccoon} /> 
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const renderHeader = messages => {
    return (
      <div className="message_page_header">
        <div className="message_page_header_left_cell">
          {leftRaccoon &&
            <>
              <a href={`/raccoon?r=${leftRaccoon}`} target="_blank" >
                <RaccoonAvatar racNum={leftRaccoon} />
              </a>
              <div className="message_page_header_info" style={{alignItems: 'flex-start'}}>
                <div>
                  {iOwnLeftRaccoon && <span>You</span>}
                  {!iOwnLeftRaccoon && <span>Them</span>}
                </div>
                <div>
                  Raccoon #{leftRaccoon}
                </div>
              </div>
            </>
          }
        </div>
        <div className="message_page_header_right_cell">
          {rightRaccoon &&
            <>
              <div className="message_page_header_info" style={{alignItems: 'flex-end'}}>
                <div>
                  {iOwnRightRaccoon && <span>You</span>}
                  {!iOwnRightRaccoon && <span>Them</span>}
                </div>
                <div>
                  Raccoon #{rightRaccoon}
                </div>
              </div>
              <a href={`/raccoon?r=${rightRaccoon}`} target="_blank" >
                <RaccoonAvatar racNum={rightRaccoon} />
              </a>
            </>
          }
        </div>
      </div>
    )
  }

  let raccoon1 = null;
  let raccoon2 = null;
  if (messages && messages[0]) {
    raccoon1 = messages[0].fromRaccoon;
    raccoon2 = messages[0].toRaccoon;
  }

  return (
    <center>
      <div className="home_wrapper">
        {/*
        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>
        */}

        <div className="global_leaderboard_header_box" style={{marginTop: '0px', maxWidth: '1000px'}}>
          <div className="leaderboard_title_row">
            <a href="/messages" style={{textDecoration: 'none'}}>
              <div className={"tier_leaderboard_text_selected"}>BACK</div>
            </a>
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>

        {messages && raccoonSquads && state && state.doneInitialWalletLoad && state.doneGetRaccoonsSquads && (
          <div>
            {renderHeader(messages)}
          </div>
        )}

        <div className="message_page_messages_container_outer">
          <div className="message_page_messages_container">
            {messages && raccoonSquads && state && state.doneInitialWalletLoad && state.doneGetRaccoonsSquads && messages.map(message => renderMessage(message))}
          </div>
        </div>

        <div className="message_page_send_message_container_outer">
          <div className="message_page_send_message_container">
              <textarea 
                className="message_page_send_message_textarea" 
                rows={5}
                value={messageContents} 
                onChange={e => {
                  let inputString = e.target.value;
                  setMessageContents(inputString);
                }}
              />
              <div style={{display: 'flex', flex: 1, alignItems: 'flex-start'}}>
                <div className="message_page_send_message_button" onClick={() => {
                  if (!messages || !messages[0]) {
                    return;
                  }
                  let iOwnFromRaccoon = false;
                  if (raccoonNumsInWallet.includes(parseInt(messages[0].fromRaccoon))) {
                    iOwnFromRaccoon = true;
                  }
                  let iOwnToRaccoon = false;
                  if (raccoonNumsInWallet.includes(parseInt(messages[0].toRaccoon))) {
                    iOwnToRaccoon = true;
                  }

                  if (!iOwnFromRaccoon && !iOwnToRaccoon) {
                    return;
                  }

                  let fromRaccoon = messages[0].fromRaccoon;
                  let toRaccoon = messages[0].toRaccoon;
                  if (!iOwnFromRaccoon && iOwnToRaccoon) {
                    fromRaccoon = messages[0].toRaccoon;
                    toRaccoon = messages[0].fromRaccoon;
                  }

                  setMessageContents("");
                  sendMessage({ variables: { fromRaccoonNum: parseInt(fromRaccoon), toRaccoonNum: parseInt(toRaccoon), messageContents: messageContents, messageType: 'chat'  } });
                }}>
                    Send Message
                </div>
              </div>
          </div>
        </div>

        {/*showSendMessage && <SendMessageComponent sourceMessageId={messageId} raccoon1={raccoon1} raccoon2={raccoon2} />*/}

        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={6000} />
      </div>
    </center>
  );
};


export default MessagePage;

