import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import RaccoonAvatar from './RaccoonAvatar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const SEND_MESSAGE = gql`
  mutation SendMessage($fromRaccoonNum: Int!, $toRaccoonNum: Int!, $messageContents: String!, $messageType: String! ) {
    sendMessage (fromRaccoonNum: $fromRaccoonNum, toRaccoonNum: $toRaccoonNum, messageContents: $messageContents, messageType: $messageType ) {
      result
      logEntries
    }
  }
`;

const GET_CONVERSATIONS = gql`
  query GetConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    conversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      result
      conversations
    }
  }
`;

const SendMessageModal = (props) => {
  let {show, close, destRaccoonNum, raccoonsInWallet} = props;

  const [messageFromRaccoon, setMessageFromRaccoon] = useState("");
  const [messageToRaccoon, setMessageToRaccoon] = useState(destRaccoonNum);
  const [messageContents, setMessageContents] = useState("");

  const [showSentMessage, setShowSentMessage] = useState(false);
  const [showFailRaccoonNotFound, setShowFailRaccoonNotFound] = useState(false);
  const [showFailRaccoonIsOfficer, setShowFailRaccoonIsOfficer] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, refetchRaccoonsSquads } = useContext(GlobalStateContext);


  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      raccoonNumsInWallet.push(rac.racNum);
    })
  }


  const [sendMessage, { data: dataSendMessage, loading: loadingSendMessage, error: errorSendMessage }] = useMutation(SEND_MESSAGE, 
    {
      refetchQueries: [{ query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }],
      onCompleted: response => {
        if (response && response.sendMessage && response.sendMessage.result) {
          let result = response.sendMessage.result;
          if (result.indexOf('fail') >= 0 || result.indexOf('Fail') >= 0) {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Error sending message");
            }, 200);
          } else {
            setToastColor('#14711f');
            setMessageToRaccoon("");
            setMessageContents("");
            setTimeout(() => {
              toast(`Message successfully sent to Raccoon #${messageToRaccoon}`);
            }, 200);
          }
        }
    }
  });

  if (!show) {
    return <div />
  }


  const renderFromRaccoonPulldown = () => {
    let options = [];
    if (raccoonsInWallet) {
      raccoonsInWallet.forEach(rac => {
        let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
        options.push({value: raccoonNum, label: raccoonNum});
      });
    }

    const customStyles = {
        control: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            border: '#222',
            // This line disable the blue border
            boxShadow: 'none'
          };
        },
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            backgroundColor: isFocused ? "#222" : "#000",
          };
        },
        singleValue: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            color: "#efdbb8"
          };
        }
    };

    return (
      <>
         <Select
          defaultValue={"Select"}
          onChange={setMessageFromRaccoon}
          options={options}
          classNames={{
            control: (state) => 'from_raccoon_pulldown_control',
            menu: (state) => 'from_raccoon_pulldown_menu',
          }}
          styles={customStyles}
        />
      </>
    )
  }

  return (
    <>
      <div className="squad_add_modal_outside_container"  style={{backgroundColor: '#110503'}} onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSentMessage(false);
        setShowFailRaccoonIsOfficer(false);
        setShowFailRaccoonNotFound(false);
        close();
      }}>
        <div className="squad_add_modal" style={{backgroundColor: '#110503'}} onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          <div>
          
          {raccoonsInWallet && raccoonsInWallet.length > 0 && 
            <div className="message_page_message_container" style={{backgroundColor: '#110503', padding : '10px'}}>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      Send Message
                    </div>
                  </div>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      To (Raccoon #)
                    </div>
                    <div className="message_page_row_value">
                      <input 
                        type="text" 
                        className="global_leaderboard_search_box_text_input" 
                        placeholder="eg: 4321"
                        value={messageToRaccoon} 
                        onChange={e => {
                          let inputString = e.target.value;
                          inputString = inputString.replace(/\D/g,'');
                          if (inputString.length > 4) inputString = inputString.substring(0, 4);
                          setShowSentMessage(false);
                          setShowFailRaccoonIsOfficer(false);
                          setShowFailRaccoonNotFound(false);
                          setMessageToRaccoon(inputString);
                        }}
                        style={{
                          backgroundColor: '#222',
                          padding: '8px',
                          width: '100px',
                          flexGrow: 'inherit',
                          height: '38px', 
                          borderRadius: '5px'
                        }}
                      />
                      {messageToRaccoon &&
                        <div style={{marginLeft: '15px', cursor: 'pointer'}}>
                          <RaccoonAvatar racNum={messageToRaccoon} size={40} /> 
                        </div>
                      }
                    </div>
                  </div>
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      From (Raccoon #)
                    </div>
                    <div className="message_page_row_value">
                      {/*<input 
                        type="text" 
                        className="global_leaderboard_search_box_text_input" 
                        placeholder="eg: 4321"
                        value={messageFromRaccoon} 
                        onChange={e => {
                          let inputString = e.target.value;
                          inputString = inputString.replace(/\D/g,'');
                          if (inputString.length > 4) inputString = inputString.substring(0, 4);
                          setShowSentMessage(false);
                          setShowFailRaccoonIsOfficer(false);
                          setShowFailRaccoonNotFound(false);
                          setMessageFromRaccoon(inputString);
                        }}
                        style={{
                          backgroundColor: '#222',
                          padding: '10px',
                          width: '100px',
                          flexGrow: 'inherit'
                        }}
                      />*/}
                      {renderFromRaccoonPulldown()}
                      {messageFromRaccoon &&
                      <div style={{marginLeft: '15px', cursor: 'pointer'}}>
                        <RaccoonAvatar racNum={messageFromRaccoon.value} size={40} /> 
                      </div>
                      }
                    </div>
                  </div>
                      
                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                      Message
                    </div>
                    <div className="message_page_row_value">
                      <textarea 
                        className="messages_send_message_textarea" 
                        rows={10}
                        cols={70}
                        value={messageContents} 
                        onChange={e => {
                          let inputString = e.target.value;
                          setMessageContents(inputString);
                        }}
                        style={{
                          backgroundColor: '#160805'
                        }}
                      />
                    </div>
                  </div>

                  <div className="message_page_row" style={{padding: '10px'}}>
                    <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center', width: '200px', minWidth: '200px'}}>
                    </div>
                    <div className="message_page_row_value">
                      <div>
                        <div 
                          className="squad_add_modal_add_button" style={{marginRight: '30px'}}
                          onClick={() => {
                            sendMessage({ variables: { fromRaccoonNum: parseInt(messageFromRaccoon.value), toRaccoonNum: parseInt(messageToRaccoon), messageContents, messageType: 'chat'  } });
                          }}
                        >
                          Send Message
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          }


          {/*!isJoinRequest && tab === 'invite' && showSentMessage && messageToRaccoon && (
            <>
              <div className="send_invite_result">An invite message has been sent to Raccoon #{messageToRaccoon}. The invitation can be seen by clicking 'My Messages' in the menu at the top right of this page. To send an invitation to another Raccoon, update the "To (Raccoon #)" value above and click "Send Invite" again.</div>
              <div className="send_invite_result">To send an invitation to another Raccoon, update the "To (Raccoon #)" value above and click "Send Invite" again.</div>
            </>
          )*/}

        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => {
          setShowSentMessage(false);
          setShowFailRaccoonIsOfficer(false);
          setShowFailRaccoonNotFound(false);
          close();
        }}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={6000} />
    </>
  );
};


export default SendMessageModal;

