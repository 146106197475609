import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import UpgradeUseModal from './UpgradeUseModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";

const browser = detect();

const FAQ = ({isMobile}, v2, v3) => {

  const [expandQuestionWhenStartEnd, setExpandQuestionWhenStartEnd] = useState(false);
  const [expandQuestionWhatWallets, setExpandQuestionWhatWallets] = useState(false);

  const [expandQuestionBattlePoints, setExpandQuestionBattlePoints] = useState(false);
  const [expandQuestionCeasefire, setExpandQuestionCeasefire] = useState(false);
  const [expandQuestionPrizesRewards, setExpandQuestionPrizesRewards] = useState(false);

  const [expandQuestionHowJoinSquad, setExpandQuestionHowJoinSquad] = useState(false);
  const [expandQuestionHowAddRaccoon, setExpandQuestionHowAddRaccoon] = useState(false);
  const [expandQuestionHowSquadBonusPointsWork, setExpandQuestionHowSquadBonusPointsWork] = useState(false);
  const [expandQuestionHCrossWalletSquadBonus, setExpandQuestionCrossWalletSquadBonus] = useState(false);
  const [expandQuestionHowRemoveFromSquad, setExpandQuestionHowRemoveFromSquad] = useState(false);

  const [expandQuestionUpgradeNFTTypes, setExpandQuestionUpgradeNFTTypes] = useState(false);
  const [expandQuestionUpgradeNFTPrizes, setExpandQuestionUpgradeNFTPrizes] = useState(false);
  const [expandQuestionUpgradeNFTDetach, setExpandQuestionUpgradeNFTDetach] = useState(false);
  const [expandQuestionUpgradeNFTMintCost, setExpandQuestionUpgradeNFTMintCost] = useState(false);

  const { state } = useContext(GlobalStateContext);

  return (
    <>
      <div className="home_wrapper" style={{paddingBottom: isMobile ? '200px' : undefined}}>
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png"  className={!isMobile ? "logo_banner_raccoon_details" : "logo_banner_raccoon_details_mobile"} onClick={() => window.location = "/"} /> <br/>
        </div>

        <div className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"} style={{marginTop: '30px'}}>
          <div className="global_leaderboard_title_text" style={{marginLeft: '10px'}}>
              FAQ
          </div>  
        </div>

        <div className={!isMobile ? "global_leaderboard_header_box" : "global_leaderboard_header_box_mobile"} style={{marginTop: '40px'}}>
          <div className="faq_container">

          <div className={!isMobile ? "faq_section_label" : "faq_section_label_mobile"}>
            GENERAL
          </div>

          <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionWhenStartEnd(!expandQuestionWhenStartEnd);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      When does the first Battle Round start and end?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionWhenStartEnd && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionWhenStartEnd && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionWhenStartEnd && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        The first Battle Round start date is still To Be Determined but will be sometime in March, 2023. The Battle Round will continue for 10 Epochs (50 days) from the start time.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>




            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionWhatWallets(!expandQuestionWhatWallets);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      What wallets are supported for the Raccoon Syndicate game?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionWhatWallets && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionWhatWallets && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionWhatWallets && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        Every Raccoon (in any wallet) on the Cardano Blockchain will earn points every 5 days and appear on the leaderboards. In order to join a Squad or view and use Upgrade NFTs, you can connect your Nami or Eternal (Desktop) wallet using the CONNECT button at the top of this, the game website.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>












          <div className={!isMobile ? "faq_section_label" : "faq_section_label_mobile"} >
            BATTLE MECHANICS
          </div>

            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionBattlePoints(!expandQuestionBattlePoints);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How do I earn Battle Points?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionBattlePoints && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionBattlePoints && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionBattlePoints && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          By default, every Raccoon earns 100 Battle Points when we do our regular snapshot every 5 days. 
                        </div>
                        <div className="faq_item_answer_paragraph">
                          There are several ways to increase the number of Battle Points your Raccoon earns beyond the default 100 points:
                        </div>
                        <ul className="faq_item_answer_list">
                          <li className="faq_item_answer_list_item">1. <u>BPM</u> - The Base Point Multiplier (BPM) value on your Raccoon will multiply the # of points you earn. A Raccoon with a 1.2 BPM will earn 120 points instead of 100 points.</li>
                          <li className="faq_item_answer_list_item">2. <u>Random Traits</u> - Every 5 day snapshot will also have a selection of traits (Hand, Wardrobe, Head, Mouth, Eyewear, Eyes) that will give any Raccoon with these traits a bonus multiplier. If the 'Sword' trait has been selected as a bonus for the current Epoch with a 15% boost value, then instead of 100 points, that Raccoon will receive 115 points.</li>
                          <li className="faq_item_answer_list_item">3. <u>Partner Projects</u> - Every 5 day snapshot will also have a selection of Partner Projects selected that will give any Raccoon with an NFT of the partner project in the same wallet a further boost. If 'The Ape Society' partner project has been selected as a bonus for the current Epoch with a 10% boost value, then instead of 100 points, that Raccoon will receive 110 points.</li>
                          <li className="faq_item_answer_list_item">4. <u>Squad Bonus</u> - If you Raccoon is part of a squad, it will receive an extra 10% battle points during every Epoch that it remains in the squad for the entirety of the Epoch.</li>
                          <li className="faq_item_answer_list_item">5. <u>Upgrade NFTs</u> - Upgrade NFTs can be won (or purchased from NFT marketplaces) to apply another multipler to the points that your Raccoon earn. See the 'What are Upgrade NFTs?" question in the 'Upgrades' section of the FAQ below.</li>
                          <li className="faq_item_answer_list_item">6. <u>Holding Pool Bonus</u> - Raccoons can also earn Holding Pool bonus points at the end of the Battle Round that will be lower or higher depending on how long the Raccoon has remaining in the wallet that it is in at the end of the round.</li>
                          <li className="faq_item_answer_list_item">7. <u>Syndicate Victory Bonus</u> - At the end of the 2 month Battle Round, we will tally all of the Battle Points earned by all Farmers, and compare it to all of the Battle Points earned by all Hunters. If the Farmers earn more points than the Hunters, then all Farmers will receive extra points on the Global Leaderboard. The same will be done for Scientist vs Hunters and Aristocrats vs Procyon Lotor.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>




            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionCeasefire(!expandQuestionCeasefire);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      What is the Ceasefire period?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionCeasefire && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionCeasefire && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionCeasefire && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          At the end of each 50-day Battle Round will be a 15 day Ceasefire / rest period before starting the next Battle Round. This is a great time to apply any Attachable Upgrades to your Raccoons, to leave and join Squads, and to purchase (or sell) Raccoons and Upgrades on the various Cardano NFT marketplaces. In the future, this ceasefire period may be reduced to 10 days.
                        </div>
                        <div className="faq_item_answer_paragraph">
                          NOTE - It's possible that the very first Ceasefire (at the end of the first Battle Round) is slightly longer than 15 days depending on how many new features the community + team decides to add and change before starting the second Battle Round. We also anticipate many Attachable Upgrade NFTs being applied during this time so may need a few extra days for that rendering as well. Note also that it is not necessary to apply attachable upgrades during the ceasefire period. This can happen at anytime durin the Battle Rounds.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>




            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionPrizesRewards(!expandQuestionPrizesRewards);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      What are the prizes / rewards for winning the game?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionPrizesRewards && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionPrizesRewards && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionPrizesRewards && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          Every Raccoon will receive a free NFT at the end of the 10 Epoch (50 day) battle round. This NFT will be viewable in the 'My NFTs' section, accessible using the dropdown at the top of the page.
                        </div>
                        <div className="faq_item_answer_paragraph">
                          Placing at the top of the Global Leaderboard will earn you one of our most powerful Attachable Upgrades (e.g. rings) that can be attached to your Raccoon. Finishing near the middle of the leaderboard will earn you some of our more powerful consumable upgrades that last for 3 Epochs (15 days). Raccoons who place near the bottom of the leaderboard will earn our 2 Epoch (10 day) consumable upgrades.
                        </div>
                        <div className="faq_item_answer_paragraph">
                          We will also give Wallet Effect NFTs to a small number of Raccoons who place at the top of the Tier 1, Tier 2 and Tier 3 leaderboards (independent of the Global Leaderboard ranking). This prize will be in addition to any other prize the Raccoon earns from the Global Leaderboard. 
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>










          <div className={!isMobile ? "faq_section_label" : "faq_section_label_mobile"} >
            SQUADS
          </div>

            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionHowJoinSquad(!expandQuestionHowJoinSquad);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How do I join a squad?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionHowJoinSquad && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionHowJoinSquad && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionHowJoinSquad && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          The easiest way to join another officer's squad is to browse the Global Leaderboard on the home page and find a Captain or General Raccoon (they will have a star icon in the top left of their NFT image). After clicking their NFT image to load their Raccoon Details page, you can scroll down and hit the 'Join Squad' button to send them a join request. The owner of that Raccoon can visit their 'My Messages' section using the dropdown menu at the top of the page to accept your join request.
                        </div>
                        <div className="faq_item_answer_paragraph">
                          If you'd like to join your own Captain or General's squad, you can visit the 'My NFTs' section using the dropdown at the top of the page then click on your Captain or General's image to load their Raccoon Details page. From here, you will be able to instantly Add any Raccoon in your wallet using the 'Add / Invite' button at the bottom of the page.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionHowAddRaccoon(!expandQuestionHowAddRaccoon);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How do I add a Raccoon to my squad?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionHowAddRaccoon && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionHowAddRaccoon && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionHowAddRaccoon && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                            Start by clicking 'My NFTs' from the drop down menu at the top right. Then select one of your Captain or General Raccoons. From their Raccoon Details page, you will see an ADD / INVITE button. Click this button and either Add one of your own Raccoons or select the 'Invite Raccoon' tab and enter the Raccoon # of a Raccoon that you'd like to invite.
                        </div>
                        <div className="faq_item_answer_paragraph">
                            When inviting a Raccoon that is not your own to a squad, they will receive a message in their 'My Messages' section of the dropdown menu at the top of the page. They will also see a 'Join Squad' button that they can click to accept your invitation.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionHowRemoveFromSquad(!expandQuestionHowRemoveFromSquad);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How do I remove a Raccoon from my squad?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionHowRemoveFromSquad && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionHowRemoveFromSquad && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionHowRemoveFromSquad && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                            To remove one of your own non-officer Raccoons from your squad, visit the 'My NFTs' page, select your squad from the 'My Squads' tab, select the Captain or the General Raccoon listed on the left to load the Raccoon's Detail page. Scrolling down will revel a 'Remove' button under any Raccoon that you own.
                        </div>
                        <div className="faq_item_answer_paragraph">
                            NOTE - You can also remove Raccoons in other holder's wallets from your squad using the same steps ^ but only during the Ceasefire period when a Battle Round is not running. Our first Battle Round starts March 31st and you can only remove other wallet Raccoons up until that time. After March 31st, you will not be able to remove another wallet's Raccoon from your squad until the end of the Battle Round.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>



            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionHowSquadBonusPointsWork(!expandQuestionHowSquadBonusPointsWork);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How do Squad Bonus Points work?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionHowSquadBonusPointsWork && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionHowSquadBonusPointsWork && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionHowSquadBonusPointsWork && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                            Ever Raccoon that is in a squad earns 10% more Battle Points every 5 days. Captains and Generals are automatically in a squad, and they also earn this 10% Battle Points boost. 
                        </div>
                        <div className="faq_item_answer_paragraph">
                            Captains and Generals earn an additional 1.5% more Battle Points every 5 days for every Raccoon in their squad. If they have 2 Raccoons in their squad they will earn an additional 3% boost (1.5 x 2) (in addition to the 10% boost that all Raccoons in a squad earns). If they have a full squad (4 Raccoons) they will earn a 6% boost (in addition to the 10% boost that all Raccoons in a squad earns).
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>



            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionCrossWalletSquadBonus(!expandQuestionHCrossWalletSquadBonus);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      Is there a bonus for multi-wallet squads?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionHCrossWalletSquadBonus && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionHCrossWalletSquadBonus && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionHCrossWalletSquadBonus && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                            At least for now, there is no bonus or incentive to having multiple wallets in your squads. Our concern is to avoid creating a hassle/chore for holders by manually moving to secondary and other wallets that they hold themselves. That said, this may be something we implement in a future Battle Round - but not for Battle Round 1.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            





          <div className={!isMobile ? "faq_section_label" : "faq_section_label_mobile"} >
            UPGRADE NFTS
          </div>

            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionUpgradeNFTTypes(!expandQuestionUpgradeNFTTypes);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      What types of Upgrade NFTs exist?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionUpgradeNFTTypes && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionUpgradeNFTTypes && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionUpgradeNFTTypes && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          During our first Battle Round, only 'Consumable NFTs' will be availble to apply to your Raccoon. At the end of the first Battle Round, 'Attachble NFTs' and 'Wallet Effect' NFTs will be won as prizes to the top Raccoons on our Global and Tier Leaderboards - and these types of Upgrade NFTs will be available for use in the next Battle Round.
                        </div>
                        <div className="faq_item_answer_paragraph">
                          More information on all 3 types can be found below: 
                        </div>
                        <ul className="faq_item_answer_list">
                          <li className="faq_item_answer_list_item">1. <u>Consumable Upgrade NFTs</u> - These include: Milk, Canteens, Vegebite, PotionX and Gummy Raccoon Upgrade NFTs and can be used by visiting the 'My NFTs' section accessible from the dropdown menu at the top right of every page. These will give a temporary (10 or 15 day) boost (between 30% and 60%) to a Raccoon of your choosing. </li>
                          <li className="faq_item_answer_list_item">2. <u>Attachable Upgrade NFTs</u> - These include: Rings and can also be used by visiting the 'My NFTs' section in the dropdown at the top right. They will become available at the end of the first Battle Round and owners of these NFTs can attach them to their Raccoon during the cease fire period, or at any time during future Battle Rounds. Applying (for example) a ring to your Raccoon, will give that Raccoon a permanent Base Point Multiplier (BPM) increase that will give the Raccoon more Battle Points every future 5 day snapshot. We will also re-render a new image of your Raccoon showing the ring (or other attachable) on your Raccoon. The on-chain metadata for the NFT will reflect the updated BPM value, the updated Image, and a new trait showing what attachables have been applied to that Raccoon.</li>
                          <li className="faq_item_answer_list_item">3. <u>Wallet Effect Upgrade NFTs</u> - This type of NFT will be awarded to some of the highest performers on the Global and Tier Leaderboards. They give a points bonus to all Raccoons in the same wallet when the snapshots are taken every 5 days.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>



            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionUpgradeNFTPrizes(!expandQuestionUpgradeNFTPrizes);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      Which Raccoons will get which Upgrade NFT Prizes?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionUpgradeNFTPrizes && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionUpgradeNFTPrizes && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionUpgradeNFTPrizes && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                        Raccoons will earn the Upgrade NFT according to their rank on the global leaderboard as described below:<br/><br/>

Rank    1          Psychedelic Wings  + PotionX<br/>
Rank    2          Psychedelic Wings + BakedBeans<br/>
Rank    3          Psychedelic Wings  + Vegebite<br/>
Rank    4  - 40          Psychedelic Wings       (65% Permanent Boost)       (40)<br/>
Rank 41 - 120          Gold Wings                   (60% Permanent Boost)       (80)<br/>
Rank 121 - 280         Dark Wings                   (55% Permanent Boost)       (160)<br/>
Rank 281 - 600         Light Wings                  (50% Permanent Boost)       (320)<br/>
Rank 601 - 850        Diamond Ring             (35% Permanent Boost)       (250)<br/>
Rank 851 - 1350        Ruby Ring                    (30% Permanent Boost)       (500)<br/> 
Rank 1351 - 2350        Emerald Ring              (25% Permanent Boost)       (1000)<br/>
Rank 2351 - 3850       Gold Ring                     (20% Permanent Boost)       (1500)<br/>
Rank 3851 - 4650       Potion-X                      (60% Boost for 3 Epochs)     (800) <br/>
Rank 4651 - 5650       Baked Beans               (50% Boost for 3 Epochs)     (1000) <br/>
Rank 5651 - 6850         Vegebite                       (60% Boost for 2 Epochs)     (1200)<br/>
Rank 6851 - 8350         Canteen                       (50% Boost for 2 Epochs)     (1500)<br/>
Rank 8351 - 10000      Milk                               (30% Boost for 2 Epochs)     (1650)<br/><br/><br/>

TIER LEADERBOARD PRIZE BREAKDOWN<br/>
If you earn one of the prizes below by placing high on your Tier Leaderboard, it will replace your prize from the Global Leaderboard above ^ <br/><br/>

Rank    1  - 5          Psychedelic Wings       (65% Permanent Boost)       (5)<br/>
Rank 6 - 15          Gold Wings                   (60% Permanent Boost)       (10)<br/>
Rank 16 - 35        Dark Wings                   (55% Permanent Boost)       (20)<br/>
Rank 36 - 65         Light Wings                  (50% Permanent Boost)       (30)<br/>
Rank 66 - 100        Diamond Ring             (35% Permanent Boost)       (35)<br/>
Rank 101 - 150        Ruby Ring                    (30% Permanent Boost)       (50) <br/>
Rank 151 - 250       Emerald Ring              (25% Permanent Boost)       (100)<br/>
Rank 251 - 400       Gold Ring                     (20% Permanent Boost)       (150)<br/>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>




            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionUpgradeNFTDetach(!expandQuestionUpgradeNFTDetach);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      Will I be able to detach upgrades that I have attached to my Raccoon?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionUpgradeNFTDetach && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionUpgradeNFTDetach && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionUpgradeNFTDetach && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          Yes! It may come a few days after the end of Battle Round 1, but you will be able to detach any Upgrade NFT that you have already attached. Since this requires re-rendering your Raccoon and minting multiple NFTs, there is a 12 ADA fee attached to this.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className={!isMobile ? "faq_item_container" : "faq_item_container_mobile"}>
              <div className="faq_item_container_inner">
                <div className="faq_item_question_row" onClick={() => {
                  setExpandQuestionUpgradeNFTMintCost(!expandQuestionUpgradeNFTMintCost);
                }}>
                  <div className="faq_item_question_container">
                    <div className="faq_item_question">
                      How much will it cost to mint and attach and detach Upgrades NFTs?
                    </div>
                  </div>
                  <div className="faq_item_toggle_container">
                    <div className="faq_item_toggle">
                    <div className="upcoming_bonuses_text">
                      {expandQuestionUpgradeNFTMintCost && <span className="faq_minus_sign" ><img src="collapse_icon.png" className="faq_minus_sign_img" /></span>}
                      {!expandQuestionUpgradeNFTMintCost && <span className="faq_plus_sign" ><img src="expand_icon.png" className="faq_plus_sign_img" /></span>}
                    </div>
                    </div>
                  </div>
                </div>
                {expandQuestionUpgradeNFTMintCost && (
                  <div className="faq_item_answer_row">
                    <div className="faq_item_answer_container">
                      <div className="faq_item_answer">
                        <div className="faq_item_answer_paragraph">
                          There is a 12 ADA fee for the following operations since they require minting a new NFT, and often burning an NFT. Sometimes (detaching) requires minting 2 NFTs and burning an NFT.
                        </div>
                        <ul className="faq_item_answer_list">
                          <li className="faq_item_answer_list_item">1. Attaching an NFT to your Raccoon </li>
                          <li className="faq_item_answer_list_item">2. Detaching an NFT from your Raccoon</li>
                          <li className="faq_item_answer_list_item">3. Converting an attachable NFT prize from your inventory on the game website into a separate NFT (ie to trade or sell).</li>
                        </ul><br/>
                        <div className="faq_item_answer_paragraph">
                          There is a 7 ADA fee for the following operations since they require minting a new NFT.
                        </div>
                        <ul className="faq_item_answer_list">
                          <li className="faq_item_answer_list_item">1. Converting a consumable NFT prize from your inventory on the game website into a separate NFT (ie to trade or sell).</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>


          </div>  
        </div>

      </div>
    </>
  );
};


export default FAQ;

