import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import UpgradeUseModal from './UpgradeUseModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti';
import dayjs from "dayjs";


const browser = detect();

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

const GET_RACCOONS_SQUADS = gql`
  query GetRaccoonsSquads($raccoonNumbers: String!, $source: String) {
    raccoonsSquads(raccoonNumbers: $raccoonNumbers, source: $source) {
      results
      source
    }
  }
`;

const GET_RACCOONS_UPGRADE_STATUS = gql`
  query GetRaccoonsUpgradeStatus($raccoonNumbers: String!) {
    raccoonsUpgradeStatus(raccoonNumbers: $raccoonNumbers) {
      results
      upgrades
    }
  }
`;

const GET_UPGRADE_STATUS = gql`
  query GetUpgradeStatus($upgradeNames: String!) {
    upgradeStatus(upgradeNames: $upgradeNames) {
      results
      upgrades
    }
  }
`;

const UPGRADE_BONUS_RATES = {
  'Milk': 30,
  'Canteen': 50,
  'Vegebite': 60,
  'Baked Beans': 50,
  'PotionX': 60,
  'Gummy Raccoon': 65,
  'Mushrooms': 70
};

const GET_RACCOON_INFO_FOR_NFT_LIST = gql`
  query GetRaccoonInfoForNFTList($raccoons: String!) {
    raccoonInfoForNFTList(raccoons: $raccoons) {
      raccoonInfo
    }
  }
`;

const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const TRX_CREATE_WATCH_FOR_SPINS = gql`
  mutation TRXCreateWatchForSpins($assetName: String!, $requestType: String!, $action: String!, $myWalletAddresses: String!, $quantity: Int!) {
    trxCreateWatchForSpins( assetName: $assetName, requestType: $requestType, action: $action, myWalletAddresses: $myWalletAddresses, quantity: $quantity) {
      result
      assetNameHex
      quantity
      upgradeType
      requestType
      watchId
    }
  }
`;


const GET_INVENTORY = gql`
  query GetInventory {
    inventory {
      id
      type
      status
    }
  }
`;


const SPIN_WHEEL = gql`
  mutation SpinWheel {
    spinWheel {
      result
      spinResult
    }
  }
`;

const GET_SPINS = gql`
  query GetSpins {
    spins {
      id
      status
      purchased_time
      spin_time
      spin_result
      package_num_spins
      purchased_trx_hash
    }
  }
`;

const GET_SPIN_PURCHASES = gql`
  query GetSpinPurchases {
    spinPurchases {
      watch_id
      trx_hash
      quantity
      stakeAddress
      status
      modified_time
    }
  }
`;


const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];



const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};


let retcb = null;

const MyNFTs = (props, v2, v3) => {
  const { width, height } = useWindowSize();

  const [tab, setTab] = useState('spin');
  const [showUpgradeUseModal, setShowUpgradeUseModal] = useState(false);
  const [useUpgradeInventoryItem, setUseUpgradeInventoryItem] = useState(false);

  const [raccoonsInWallet, setRaccoonsInWallet] = useState([]);
  const [raccoonSquadInfo, setRaccoonSquadInfo] = useState([]);
  const [raccoonInfo, setRaccoonInfo] = useState([]);
  const [upgradeStatus, setUpgradeStatus] = useState([]);

  const [squadMemberRaccoonInfo, setSquadMemberRaccoonInfo] = useState([]);

  const [inventory, setInventory] = useState([]);

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');

  const [filterUpgradesMyUpgrades, setFilterUpgradesMyUpgrades] = useState('Any');
  const [filterUpgradesMyInventory, setFilterUpgradesMyUInventory] = useState('Any');

  const { state, walletGetSpins, team, raccoonUpgradesApplied, globalLucid, backgroundRefreshWallet } = useContext(GlobalStateContext);

  const [toastColor, setToastColor] = useState('#e08705');

  const [didFirstSpin, setDidFirstSpin] = useState(false);

  const [showConfetti, setShowConfetti] = useState(false);

  const [hideUseButton, setHideUseButton] = useState(false);

  const [spins, setSpins] = useState(null);
  const [availableSpins, setAvailableSpins] = useState(null);
  const [gotFirstSpins, setGotFirstSpins] = useState(false);

  const [spinPurchases, setSpinPurchases] = useState(null);

  let hasBankerCard = false;
  if (state && state.bankerCardsInWallet && state.bankerCardsInWallet.length > 0) {
    hasBankerCard = true;
  }

  let fiveSpinPrice = "25,000,000";
  let twentyFourSpinPrice = "100,000,000";
  let seventyFiveSpinPrice = "200,000,000";
  let fiveSpinPricePerSpin = "5.0M";
  let twentyFourSpinPricePerSpin = "4.16M";
  let seventyFiveSpinPricePerSpin = "2.66M";

  if (hasBankerCard) {
    fiveSpinPrice = "22,500,000";
    twentyFourSpinPrice = "90,000,000";
    seventyFiveSpinPrice = "180,000,000";
    fiveSpinPricePerSpin = "4.5M";
    twentyFourSpinPricePerSpin = "3.75M";
    seventyFiveSpinPricePerSpin = "2.40M";
  }


  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
        }
    }
  });


  const [getSpins, { loading: loadingGetSpins, error: errorGetSpins, data: dataGetSpins, refetch: refetchGetSpins }] = useLazyQuery(GET_SPINS);

  const [getSpinPurchases, { loading: loadingGetSpinPurchases, error: errorGetSpinPurchases, data: dataGetSpinPurchases, refetch: refetchGetSpinPurchases }] = useLazyQuery(GET_SPIN_PURCHASES);

  
  const [trxCreateWatchForSpins, { data: dataTRXCreateWatch, loading: loadingTRXCreateWatch, error: errorTRXCreateWatch }] = useMutation(TRX_CREATE_WATCH_FOR_SPINS, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideUseButton(false);
        }, 1000);
        getSpinPurchases({variables: {}});
        if (response && response.trxCreateWatchForSpins && response.trxCreateWatchForSpins.result === 'fail - Already has spin order in progress') {
            setToastColor('#e08705');
            setTimeout(() => {
              toast("There is already an order in to get spins. Wait for the blockchain to process that before creating another.")
            }, 200);
        } else if (response && response.trxCreateWatchForSpins && response.trxCreateWatchForSpins.result === 'success' && response.trxCreateWatchForSpins.assetNameHex) {
          getSpinPurchases({variables: {}});
          setTimeout(() => {
            getSpinPurchases({variables: {}});
          }, 1000);

          const getSpinsSuccess = () => {
            getSpinPurchases({variables: {}});
            setTimeout(() => {
              getSpinPurchases({variables: {}});
            }, 1000);
            setToastColor('#14711f');
            setTimeout(() => {
              toast("Tokens sent. Wait 10-30 minutes for the blockchain and then for your number of available spins to be updated.")
              // getUpgradeStatusData();
            }, 200);
          }

          const getSpinsFailure = () => {
            getSpinPurchases({variables: {}});
            setTimeout(() => {
              getSpinPurchases({variables: {}});
            }, 1000);
            setToastColor('#e08705');
            setTimeout(() => {
              toast("Failed to get spins. Please make sure you have enough BANK coin in your wallet, then try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          if (response.trxCreateWatchForSpins.requestType === 'getSpins') {
              walletGetSpins(response.trxCreateWatchForSpins.assetNameHex, response.trxCreateWatchForSpins.watchId, response.trxCreateWatchForSpins.quantity, response.trxCreateWatchForSpins.upgradeType, getSpinsSuccess, getSpinsFailure);
           
          }
          
        }
    }
  });


  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
            getSpins({variables: {}});
            getSpinPurchases({variables: {}});
          }
        }
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet({fromApi: true});
    }
  }, [state.api]);

  useEffect(() => {
    window.spinInit(doSpin, onResult);
  }, []);

  useEffect(() => {
    window.spinInit(doSpin, onResult);
  }, [tab]);

  useEffect(() => {
    setDidFirstSpin(false);
  }, []);

  useEffect(() => {
    setDidFirstSpin(false);
  }, [tab]);

  useEffect(() => {
    getSpinPurchases({variables: {}});
  }, [dataTRXCreateWatch]);

  useEffect(() => {
    if (dataGetSpins) {
      setSpins(dataGetSpins.spins);
      let availableSpins = [];
      if (dataGetSpins.spins && dataGetSpins.spins.length > 0) {
        dataGetSpins.spins.forEach(spin => {
          if (spin.status === 'notSpunYet') {
            availableSpins.push(spin)
          }
        });
      }
      setAvailableSpins(availableSpins);
      setTimeout(() => {
        setGotFirstSpins(true);
      }, 100);
    }
  }, [dataGetSpins]);

  useEffect(() => {
    if (dataGetSpinPurchases) {
      setSpinPurchases(dataGetSpinPurchases.spinPurchases);
    }
  }, [dataGetSpinPurchases]);


  const [spinWheel, { data: dataSpinWheel, loading: loadingSpinWheel, error: errorSpinWheel }] = useMutation(SPIN_WHEEL, 
    {
      onCompleted: response => {
       getSpins({variables: {}});
       getSpinPurchases({variables: {}});
       if (response && response.spinWheel && response.spinWheel.result === 'success') {
          if (retcb) {
            retcb(response.spinWheel.spinResult);
          }
       } else if (response && response.spinWheel && response.spinWheel.result === 'fail - no spins left') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("You do not have any spins remaining. Click the 'Get Spins' tab to get more spins.");
          }, 200);
       }
    }
  });

  const doSpin = async (cb) => {
    retcb = cb;
    spinWheel({variables: {}});
  }

  let audio = new Audio("https://rswebpublic2.s3.amazonaws.com/gamesounds/tada.mp3")

  const onResult = (var1) => {
    setDidFirstSpin(true);
    setShowConfetti(true);
    audio.play()
    setTimeout(() => {
      setShowConfetti(false);
    }, 7500)
  }

  let spinEvents = [];
  if (spins && spins.forEach) {
    spins.forEach(spin => {
      if (spin && spin.status === 'spinUsed') {
        spinEvents.push({
          ...spin,
          eventType: 'spin',
          eventTime: new Date(spin.spin_time)
        });
      }
    })
  }

  if (spinPurchases && spinPurchases.forEach) {
    spinPurchases.forEach(sp => {
      spinEvents.push({
        ...sp,
        eventType: 'spinPurchase',
        eventTime: new Date(parseInt(sp.modified_time))
      });
    })
  }

  function sortEvents(a, b) {
    if (a.eventTime > b.eventTime) {
      return -1;
    }
    if (a.eventTime < b.eventTime) {
      return 1;
    }
    return 0;
  }

  spinEvents = spinEvents.sort(sortEvents);

  return (
    <>
      <div className="home_wrapper">
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>

        <div className="global_leaderboard_header_box">
          <div className="leaderboard_title_row">
          <div className="global_leaderboard_title_text">
                <div className={tab === 'spin' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => { setTab('spin');}} style={{fontSize: '31px'}}>SPIN</div>
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tab === 'getSpins' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('getSpins');}} style={{fontSize: '31px'}}>GET SPINS</div> 
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tab === 'history' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('history');}} style={{fontSize: '31px'}}>HISTORY</div> 
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>


        {tab === 'spin' &&
          <center>
            {gotFirstSpins && (
              <div style={{marginTop: '30px', fontSize: '26px', color: '#efdbb8'}}>
                {(availableSpins && availableSpins.length > 0) && <span>{availableSpins.length} spins left!</span>}
                {(!availableSpins || availableSpins.length < 1) && <span>No spins remaining. Click <span onClick={() => setTab('getSpins')} style={{color: '#e08705', cursor: 'pointer'}}>here</span> to <span onClick={() => setTab('getSpins')} style={{color: '#e08705', cursor: 'pointer'}}>get more spins</span>.</span>}
              </div>
            )}
            {!gotFirstSpins && <div style={{marginTop: '30px', fontSize: '26px', color: '#000'}}>&nbsp;</div>}
            <div style={{width: '800px', height: '800px', paddingTop: '50px'}}>
              <div id="container">
                <button className="spinBtn" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>{!didFirstSpin ? "Spin the wheel!" : "Spin again!"}</button>
                <div className="wheelContainer">
                    <svg className="wheelSVG" version="1.1" textRendering="optimizeSpeed" preserveAspectRatio="xMidYMin meet">
                        <defs>
                            <filter id="shadow" x="-100%" y="-100%" width="550%" height="550%">
                                <feOffset in="SourceAlpha" dx="0" dy="0" result="offsetOut"></feOffset>
                                <feGaussianBlur stdDeviation="9" in="offsetOut" result="drop" />
                                <feColorMatrix in="drop" result="color-out" type="matrix" values="0 0 0 0   0
                      0 0 0 0   0 
                      0 0 0 0   0 
                      0 0 0 .3 0" />
                                <feBlend in="SourceGraphic" in2="color-out" mode="normal" />
                            </filter>
                        </defs>
                        <g className="mainContainer">
                            <g className="wheel">
                            </g>
                        </g>
                        <g className="centerCircle" />
                        <g className="wheelOutline" />
                        <g className="pegContainer" opacity="1">
                            <path className="peg" fill="#daaa70" d="M22.139,0C5.623,0-1.523,15.572,0.269,27.037c3.392,21.707,21.87,42.232,21.87,42.232 s18.478-20.525,21.87-42.232C45.801,15.572,38.623,0,22.139,0z" />
                        </g>
                        <g className="valueContainer" />
                        <g className="centerCircleImageContainer" />
                    </svg>
                    <div className="toast">
                        <p></p>
                    </div>
                </div>
              </div>
            </div>
            <div style={{marginTop: '100px', fontSize: '26px', color: '#efdbb8', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <div style={{marginLeft: '20%'}}>Spin Probability</div>
                <div style={{marginLeft: '20%', fontSize: '22px'}}>
                  {/*
                  <ul>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Gold Ring</div><div>1%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>PotionX</div><div>2%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Baked Beans</div><div>3%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Vegebite</div><div>4%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Canteen</div><div>5%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>100 Battle Points</div><div>6%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Milk</div><div>6%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>50 Battle Points</div><div>8%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>20 Battle Points</div><div>25%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>10 Battle Points</div><div>40%</div></div></li>
                  </ul>
                  */}
                  <ul>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Gold Ring</div><div>1%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>PotionX</div><div>2%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Baked Beans</div><div>3%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Vegebite</div><div>4%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Canteen</div><div>5%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>Milk</div><div>10%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>50 Battle Points</div><div>10%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>20 Battle Points</div><div>25%</div></div></li>
                    <li style={{textAlign: 'left', marginBottom: '10px'}}><div style={{display: 'flex', flexDirection:'row'}}><div style={{minWidth: '200px', maxWidth: '220px'}}>10 Battle Points</div><div>40%</div></div></li>
                  </ul>                  
                </div>
            </div>
          </center>
        }



        {tab === 'getSpins' && (
          <div className="global_leaderboard_header_box" style={{marginTop: '40px'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: '31px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                  <div>
                    Get 5 Spins for {fiveSpinPrice} $BANK
                  </div>
                  <div style={{fontSize: '15px'}}>({fiveSpinPricePerSpin} $BANK per spin)</div>
                </div>
              </div>
              <div 
                className="squad_add_modal_add_button" 
                onClick={() => {
                  setHideUseButton(true);
                  trxCreateWatchForSpins({ variables: { assetName: 'spins', requestType: 'getSpins', action: 'trxCreateWatchForSpins', myWalletAddresses: 'testAddresses', quantity: 5 } });

                }}
                style={{marginLeft: '75px', fontSize: '22px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '170px', maxWidth: '170px'}}
              >
                GET 5 SPINS
              </div>
            </div>


            <div style={{display: 'flex', flexDirection: 'row', marginTop: '60px'}}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: '31px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                  <div>
                    Get 24 Spins for {twentyFourSpinPrice} $BANK
                  </div>
                  <div style={{fontSize: '15px'}}>(4 extra spins! {twentyFourSpinPricePerSpin} $BANK per spin)</div>
                </div>
              </div>
              <div 
                className="squad_add_modal_add_button" 
                onClick={() => {
                  setHideUseButton(true);
                  trxCreateWatchForSpins({ variables: { assetName: 'spins', requestType: 'getSpins', action: 'trxCreateWatchForSpins', myWalletAddresses: 'testAddresses', quantity: 24 } });

                }}
                style={{marginLeft: '57px', fontSize: '22px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '170px', maxWidth: '170px'}}
              >
                GET 24 SPINS
              </div>
            </div>



            <div style={{display: 'flex', flexDirection: 'row', marginTop: '60px'}}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: '31px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                  <div>
                    Get 75 Spins for {seventyFiveSpinPrice} $BANK
                  </div>
                  <div style={{fontSize: '15px'}}>(25 extra spins! {seventyFiveSpinPricePerSpin} $BANK per spin)</div>
                </div>
              </div>
              <div 
                className="squad_add_modal_add_button" 
                onClick={() => {
                  setHideUseButton(true);
                  trxCreateWatchForSpins({ variables: { assetName: 'spins', requestType: 'getSpins', action: 'trxCreateWatchForSpins', myWalletAddresses: 'testAddresses', quantity: 75 } });

                }}
                style={{marginLeft: '39px', fontSize: '22px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '170px', maxWidth: '170px'}}
              >
                GET 75 SPINS
              </div>
            </div>

          </div>
        )}



        {tab === 'history' && (
          <div className="global_leaderboard_header_box" style={{marginTop: '40px'}}>
            {gotFirstSpins && (!spinEvents || spinEvents.length < 1) && (
              <div style={{fontSize: '22px', color: '#efdbb8'}}>You have not purchased spins or spun the wheel yet. Click 'Get Spins' to get spins.</div>
            )}
            {gotFirstSpins && spinEvents && spinEvents.length > 0 && (
              <div>
                {spinEvents.map((spinEvent, i) => {
                  if (spinEvent && spinEvent.eventType === 'spinPurchase') {
                    let eventTimeDayJS = dayjs(spinEvent.eventTime );
                    return (
                      <div style={{display: 'flex', flexDirection: 'row', marginBottom: '10px'}} key={spinEvent.watch_id}>
                        <div style={{fontSize: '18px', color: '#e08705', minWidth: '190px'}}>
                          {eventTimeDayJS.format('MMM D, H:mm A')}
                        </div>
                        {spinEvent.status === 'waitingForBlockchain' && (
                          <div style={{fontSize: '18px', color: '#efdbb8'}}>
                          You attempted to purchase {spinEvent.quantity} spins. Waiting for blockchain.
                          </div>
                        )}
                        {spinEvent.status === 'assetsReceived' && (
                          <div style={{fontSize: '18px', color: '#efdbb8'}}>
                          You successfully purchased {spinEvent.quantity} spins
                          </div>
                        )}
                        <div style={{fontSize: '11px', marginTop: '4px', marginLeft: '10px', color: '#444'}}>
                          (trx hash: {spinEvent.trx_hash})
                        </div>

                      </div>
                    )
                  } else if (spinEvent && spinEvent.eventType === 'spin' && spinEvent.status === 'spinUsed') {
                    let eventTimeDayJS = dayjs(spinEvent.eventTime );
                    return (
                      <div style={{display: 'flex', flexDirection: 'row', marginBottom: '10px'}} key={spinEvent.id}>
                        <div style={{fontSize: '18px', color: '#e08705', minWidth: '190px'}}>
                          {eventTimeDayJS.format('MMM D, H:mm A')}
                        </div>
                        <div style={{fontSize: '18px', color: '#efdbb8'}}>
                          You spun and won: {spinEvent.spin_result}
                        </div>
                        <div style={{fontSize: '11px', marginTop: '4px', marginLeft: '10px', color: '#444'}}>
                          (spin id: {spinEvent.id})
                        </div>
                      </div>
                    )
                  } else {
                    return <div key={`se+${i}`} />
                  }
                  
                })}
              </div>
              
            )}
          </div>
        )}


        {showConfetti &&
          <Confetti
            width={width}
            height={parseInt(height*3)}
            tweenDuration={10000}
            numberOfPieces={600}
            colors={['#d0843f', '#e6b75d', '#f7db8b', '#f0d06e', '#f5d57e', '#e08706', '#f20003', '#FFFFFF']}
          />
        }
        
        
        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />

      </div>
    </>
  );
};


export default MyNFTs;

