import React, {useState, useEffect} from 'react';
import {useLazyQuery, gql} from '@apollo/client';
import Link from './Link';
import dayjs from "dayjs";
import Tooltip from "react-simple-tooltip"

const RaccoonAvatar = (props) => {
  const {racNum, size, left, hidePreview} = props;

  if (hidePreview) {
    return (
      <div className="battle_round_countdown_container">
        <img className="rac_avatar_img" style={{width: size ? `${size}px` : undefined, height: size ? `${size}px` : undefined}} src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racNum}.png?cb=${Date.now()}`} />    
      </div>
    );
  }

  if (left) {
    return (
      <div className="battle_round_countdown_container">
        <Tooltip content={
            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racNum}.png?cb=${Date.now()}`} className="global_leaderboard_nft_large_left" style={{width: '300px !important', maxWidth: '300px !important', zIndex: '99999 !important'}} />
            }
            arrow={0}
            customCss={{
              zIndex: 9999,
              padding: 0
            }}
            offset={-200}
            placement="top"
            radius={0}
            zIndex={9999}
            style={{
              whiteSpace:'pre',
              zIndex: 9999,
              padding: 0
            }}
          >
          <img className="rac_avatar_img" style={{width: size ? `${size}px` : undefined, height: size ? `${size}px` : undefined}} src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racNum}.png?cb=${Date.now()}`} />    
        </Tooltip>
      </div>
    )
  }

  return (
      <div className="battle_round_countdown_container">
        <Tooltip content={
            <img src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racNum}.png?cb=${Date.now()}`} className="global_leaderboard_nft_large" style={{width: '300px !important', maxWidth: '300px !important', zIndex: '99999 !important'}} />
            }
            arrow={0}
            customCss={{
              zIndex: 9999,
              padding: 0
            }}
            offset={0}
            placement="top"
            radius={0}
            zIndex={9999}
            style={{
              whiteSpace:'pre',
              zIndex: 9999,
              padding: 0
            }}
          >
          <img className="rac_avatar_img" style={{width: size ? `${size}px` : undefined, height: size ? `${size}px` : undefined}} src={`https://rswebpublic2.s3.amazonaws.com/nftimgsmed/${racNum}.png?cb=${Date.now()}`} />    
        </Tooltip>
      </div>
  );
};

export default RaccoonAvatar;