import React, {useState, useEffect} from 'react';
import {useMutation, gql} from '@apollo/client';
import BattleRoundCountdown from './BattleRoundCountdown';

const GET_RANDOM_NUMBER = gql`
    mutation GetRandomNumber(
        $min: Int!
        $max: Int!
    ) {
        getRandomNumber(min: $min, max: $max)
    }
`;

const DO_DRAWS = gql`
    mutation DoDraws(
        $min: Int!
        $max: Int!
    ) {
      doDraws(min: $min, max: $max)
    }
`;


const RandomNumGenerator = () => {
  
  const [doDraws] = useMutation(DO_DRAWS, {
    variables: {
      min: 1,
      max: 100
    },
    onCompleted: (data) => {
      const {doDraws} = data;
    }
  });

  return (
    <>
      <div className="">
        <div className="white">
          <div className="logo_container">
            <img src="rs_logo_banner.png" className="logo_banner" /> <br/>
          </div>
          <img src="home_hero.png" className="hero_image" /> <br/>
          <br/>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div className="battle_round_dates_row battle_round_dates_row_l">
              <span className="home_battle_round_text">BATTLE ROUND ENDS:</span>
              <BattleRoundCountdown />
            </div>
            <div className="battle_round_dates_row battle_round_dates_row_r">
              <span className="home_battle_round_text">NEXT SNAPSHOT:</span>
              <BattleRoundCountdown />
            </div>    
          </div>     
          <br/>
          <center>
            Random Number Generator
            <div style={{
              cursor: 'pointer',
              backgroundColor: '#00f',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 250,
              padding: 10,
              marginTop: 20
            }} onClick={() => {
              doDraws();
            }}>
              Create Random Number
            </div>
          </center>
          <br/>
          <br/>
        </div>
      </div>
    </>
  );
};

export default RandomNumGenerator;

