import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';


const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $inventoryItemId: String, $requestType: String!, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, inventoryItemId: $inventoryItemId, requestType: $requestType, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;

const USE_INVENTORY_CONSUMABLE = gql`
  mutation UseInventoryConsumable($inventoryItemId: String!, $raccoonNum: Int) {
    useInventoryConsumable(inventoryItemId: $inventoryItemId, raccoonNum: $raccoonNum) {
      result
      raccoonNum
      inventoryItemId
    }
  }
`;





const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsBoostUpcoming = [
  { value: 'No Epoch Trait Upcoming', label: 'No Epoch Trait Upcoming' },
  { value: 'Epoch Trait Upcoming', label: 'Epoch Trait Upcoming' },
  { value: 'Any', label: 'Any' },
];

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}


const SelectItemToForgeModal = (props) => {
  let {show, close, source, boxNumber, buttonLabel, raccoonUpgradesInWallet, raccoonInfoLocal, inventoryItems, includeConsumables, includeAttachables, cellItems, getUpgradeStatusData, parentSetToastColor, refreshRaccoonInfo, refreshInventory, selectItem} = props;

  const [tab, setTab] = useState('consumables');
  const [messageToRaccoon, setMessageToRaccoon] = useState("");

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [hideUseButton, setHideUseButton] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, walletSendConsumableUpgradeNFT, walletSendAttachableUpgradeNFT, walletSendConsumableUpgradeFromInventory, walletSendAttachableUpgradeFromInventory, currentEpoch } = useContext(GlobalStateContext);

  
  function onUseInventoryConsumable(inventoryItem, raccoonNum) {
    consumeInventoryConsumable({ variables: { inventoryItemId: inventoryItem.id, raccoonNum }});
  }

  const [consumeInventoryConsumable, { data: dataUseInventoryConsumable, loading: loadingUseInventoryConsumable, error: errorUseInventoryConsumable }] = useMutation(USE_INVENTORY_CONSUMABLE, 
    {
      onCompleted: response => {
        setHideUseButton(false);

        if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'success') {
          setToastColor('#14711f');
          if (parentSetToastColor) {
            parentSetToastColor('#14711f');
          }
          setTimeout(() => {
            toast("Upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has upgrade coming soon') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon aready has a consumable upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has too many battle point upgrades applied') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon aready has too many battle point upgrades applied.")
            getUpgradeStatusData();
          }, 200);
        }
      }
    }
  );


  const [trxCreateWatch, { data: dataTRXCreateWatch, loading: loadingTRXCreateWatch, error: errorTRXCreateWatch }] = useMutation(TRX_CREATE_WATCH, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideUseButton(false);
        }, 1000);
        if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'fail - Already has upgrade coming soon') {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an upcoming upgrade.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('Raccoon already wearing an attachable') >= 0) {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an attachable. Detach their existing attachable first.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('fail 11 - upgrade already applied to a Raccoon and waiting for blockchain') >= 0) {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That upgrade item is already in progress of being used on a Raccoon and is waiting for the blockchain to process it.")
          }, 200);
        } else if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'success' && response.trxCreateWatch.assetNameHex) {

          const sendUpgradeSuccess = () => {
            setToastColor('#14711f');
            if (parentSetToastColor) {
              parentSetToastColor('#14711f');
            }
            setTimeout(() => {
              toast("Upgrade sent. Wait 10-30 minutes for the blockchain and then for your My Raccoons list to be updated.")
              getUpgradeStatusData();
            }, 200);
            refreshRaccoonInfo();
            refreshInventory();
          }

          const sendUpgradeFailure = () => {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Failed to send upgrade. Please make sure you have enough ADA in your wallet (6 for consumables, 12 for attachables) Try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          if (response.trxCreateWatch.requestType === 'useInventoryItem') {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              // walletSendConsumableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              // walletSendAttachableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            }
          } else {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              walletSendConsumableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              walletSendAttachableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            }
          }
          
        }
    }
  });







  if (!show) {
    return <div />
  }

  let numFixedBattlePoints = 0;


  return (
    <>
      <div className="squad_add_modal_outside_container"  onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        close();
      }}>
        <div className="squad_add_modal" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <div className="squad_add_modal_header_box">
          <div className="leaderboard_title_row">
            <div className="global_leaderboard_title_text">
              <div className={"squad_add_modal_header_selected"}>SELECT ITEM TO RECEIVE</div>
            </div>      
          </div>
            
          <div className="leaderboard_title_row" style={{marginTop: '40px'}}>
          <div className="global_leaderboard_title_text">
                <div className={tab === 'consumables' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('consumables');}} style={{fontSize: '29px'}}>CONSUMABLES</div> 
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tab === 'rings' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('rings');}} style={{fontSize: '29px'}}>RINGS</div> 
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tab === 'wings' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('wings');}} style={{fontSize: '29px'}}>WINGS</div>                 
                <div className="tier_leaderboard_text_separator">|</div>
                <div className={tab === 'squadPotions' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('squadPotions');}} style={{fontSize: '29px'}}>SQUAD POTIONS</div>                 
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>

        {tab === 'consumables' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '67vh', overflowY: 'scroll'}}>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Canteen (2 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      50% Boost / 2 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Canteen'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Vegebite (4 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      60% Boost / 2 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Vegebite'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Baked Beans (8 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      50% Boost / 3 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Baked Beans'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      PotionX (16 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      60% Boost / 3 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'PotionX'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Gummy Raccoon (32 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      65% Boost / 3 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Gummy Raccoon'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>         
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Mushrooms (64 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      70% Boost / 3 Epochs
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Mushrooms'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>                                          
          </div>
        }



        {tab === 'rings' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '67vh', overflowY: 'scroll'}}>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Emerald Ring (2 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      25% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Emerald Ring'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Ruby Ring (4 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      30% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Ruby Ring'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Diamond Ring (8 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      35% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Diamond Ring'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      CompoundX Ring (16 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                     40% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'CompoundX Ring'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Blue Diamond Ring (32 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      45% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Blue Diamond Ring'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>         
          </div>
        }



        {tab === 'wings' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '67vh', overflowY: 'scroll'}}>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Dark Wings (2 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      55% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Dark Wings'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Gold Wings (4 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      60% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Gold Wings'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Psychedelic Wings (8 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      65% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Psychedelic Wings'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      CompoundX Wings (16 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      70% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'CompoundX Wings'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>    
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Blue Diamond Wings (32 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      75% Boost
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Blue Diamond Wings'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>                       
          </div>
        }



        {tab === 'squadPotions' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '67vh', overflowY: 'scroll'}}>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Blue Squad Potion (2 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      +20 Power Level for all Squad Raccoons
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Blue Squad Potion'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Red Squad Potion (4 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      +30 Power Level for all Squad Raccoons
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Red Squad Potion'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
                <div className="my_nfts_nft_box_upgrades_forge_adv" style={{textAlign: 'center'}}>
                  <img src={'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'} className="my_nfts_nft_image_upgrades" />
                  <div className="my_nfts_box_text_forge_adv">
                      Purple Squad Potion (8 points)
                  </div>
                  <div className="my_nfts_box_text_forge_adv">
                      +40 Power Level for all Squad Raccoons
                  </div>
                  <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', flex: 0.2, marginTop: '10px', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                    <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, item: 'Purple Squad Potion'})}}>
                      {buttonLabel}
                    </div>
                  </div>                  
                </div>
          </div>
        }
        

        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => close()}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />
    </>
  );
};
 

export default SelectItemToForgeModal;

