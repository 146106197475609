import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import dayjs from "dayjs";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';

const GET_CONVERSATIONS = gql`
  query GetConversations($myRaccoons: String!, $myWalletAddresses: String!) {
    conversations(myRaccoons: $myRaccoons, myWalletAddresses: $myWalletAddresses) {
      result
      conversations
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($messageId: String!, $myWalletAddresses: String!) {
    messages(messageId: $messageId, myWalletAddresses: $myWalletAddresses) {
      result
      messages
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($fromRaccoonNum: Int!, $toRaccoonNum: Int!, $messageContents: String!, $messageType: String! ) {
    sendMessage (fromRaccoonNum: $fromRaccoonNum, toRaccoonNum: $toRaccoonNum, messageContents: $messageContents, messageType: $messageType ) {
      result
      logEntries
    }
  }
`;



const SendMessageComponent = (props, v2, v3) => {
  const [messageFromRaccoon, setMessageFromRaccoon] = useState("");
  const [messageToRaccoon, setMessageToRaccoon] = useState("");
  const [messageContents, setMessageContents] = useState("");

  const {sourceMessageId, raccoon1, raccoon2} = props;

  const { state } = useContext(GlobalStateContext);

  let raccoonsInWallet = null;
  let raccoonUpgradesInWallet = null;

  if (state && state.raccoonsInWallet) {
    raccoonsInWallet = state.raccoonsInWallet;
  }
  if (state && state.raccoonUpgradesInWallet) {
    raccoonUpgradesInWallet = state.raccoonUpgradesInWallet;
  }

  let raccoonNumsInWallet = [];
  if (raccoonsInWallet) {
    raccoonsInWallet.forEach(rac => {
      raccoonNumsInWallet.push(rac.racNum);
    })
  }

  let raccoon1InWallet = raccoonNumsInWallet.includes(raccoon1);
  let raccoon2InWallet = raccoonNumsInWallet.includes(raccoon2);
  
  const { data: dataConversations, loading: loadingConversations, error: errorConversations } = useQuery(GET_CONVERSATIONS, {
    variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }
  });

  const [sendMessage, { data: dataSendMessage, loading: loadingSendMessage, error: errorSendMessage }] = useMutation(SEND_MESSAGE, 
    {
      refetchQueries: [
        { query: GET_CONVERSATIONS, variables: { myRaccoons: JSON.stringify(raccoonNumsInWallet), myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  },
        { query: GET_MESSAGES, variables: { messageId: sourceMessageId, myWalletAddresses: JSON.stringify(state.rewardsAddresses) || 'none' }  }
      ],
      onCompleted: response => {
        // if (response && response.squadAddMyRaccoon && response.squadAddMyRaccoon.result) {
          // refetchRaccoonsSquads();
        // }
    }
  });
  
  
  const renderFromRaccoonPulldown = () => {
    let options = [];
    if (raccoonsInWallet) {
      raccoonsInWallet.forEach(rac => {
        options.push({value: rac.racNum, label: rac.racNum});
      });
    }

    const customStyles = {
        control: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            border: '#222',
            boxShadow: 'none',
          };
        },
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            backgroundColor: isFocused ? "#222" : "#000",
          };
        },
        singleValue: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            color: "#efdbb8"
          };
        }
    };

    return (
      <>
         <Select
          defaultValue={"Select"}
          onChange={setMessageFromRaccoon}
          options={options}
          classNames={{
            control: (state) => 'from_raccoon_pulldown_control',
            menu: (state) => 'from_raccoon_pulldown_menu',
          }}
          styles={customStyles}
        />
      </>
    )
  }


  return (
    <>
      <div className="message_page_message_container">
        <div className="message_page_container">
        <div className="message_page_row">
          <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center'}}>
            Send Message
          </div>
        </div>
        <div className="message_page_row">
            <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center'}}>
              From
            </div>
            <div className="message_page_row_value">
              {raccoon1InWallet && !raccoon2InWallet && <div>Raccoon #{raccoon1}</div>}
              {raccoon2InWallet && !raccoon1InWallet && <div>Raccoon #{raccoon2}</div>}
              {raccoon1InWallet && raccoon2InWallet && <div>Raccoon #{raccoon1}</div>}
            </div>
          </div>
          <div className="message_page_row">
            <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center'}}>
              To
            </div>
            <div className="message_page_row_value">
              {raccoon1InWallet && !raccoon2InWallet && <div>Raccoon #{raccoon2}</div>}
              {raccoon2InWallet && !raccoon1InWallet && <div>Raccoon #{raccoon1}</div>}
              {raccoon1InWallet && raccoon2InWallet && <div>Raccoon #{raccoon2}</div>}
            </div>
          </div>
          <div className="message_page_row">
            <div className="message_page_row_label" style={{marginTop: '5px'}}>
              Message
            </div>
            <div className="message_page_row_value">
              <textarea 
                className="messages_send_message_textarea" 
                rows={5}
                cols={70}
                value={messageContents} 
                onChange={e => {
                  let inputString = e.target.value;
                  setMessageContents(inputString);
                }}
              />
            </div>
          </div>
          <div className="message_page_row">
            <div className="message_page_row_label" style={{display: 'flex', alignItems: 'center'}}>
              
            </div>
            <div className="message_page_row_value">
              <div>
                <div 
                  className="squad_add_modal_add_button" style={{marginRight: '30px'}}
                  onClick={() => {
                    sendMessage({ variables: { fromRaccoonNum: parseInt(messageFromRaccoon.value), toRaccoonNum: parseInt(messageToRaccoon), messageContents: messageContents, messageType: 'chat'  } });
                  }}
                >
                  Send Message
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </>
  );
};


export default SendMessageComponent;

