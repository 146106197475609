import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStateProvider } from './providers/GlobalStateProvider';
import './styles/index.css';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import {setContext} from '@apollo/client/link/context';
import { split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from "@apollo/client";

/*
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import {AUTH_TOKEN} from "./constants";
*/

/*
const httpLink = createHttpLink({
  uri: 'http://localhost:5050'
});

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' &&
      operation === 'subscription'
    );
  },
  null,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Link: {
        keyFields: ["id"]
      }
    }
  })
});
*/

// let uri = 'https://game1.raccoonsyndicate.com:5050/graphql';

let uri = 'https://api.raccoonsyndicate.com/graphql';


if (window && window.location && window.location.href && window.location.href.indexOf('localhost') >= 0) {
  uri = 'http://localhost:5050/graphql';
}

const link = createHttpLink({
  uri,
  credentials: 'include'
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <GlobalStateProvider>
        <App/>
      </GlobalStateProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
