import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useQuery, 
  useLazyQuery,
  useMutation,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import UpgradeUseModal from './UpgradeUseModal';
import { detect } from 'detect-browser';
import ClipLoader from "react-spinners/ClipLoader";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti';
import dayjs from "dayjs";


const browser = detect();

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}


const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const TRANSFER_MY_INVENTORY = gql`
  mutation TransferMyInventory($sendToWalletAddress: String!, $validationString: String!) {
    transferMyInventory( sendToWalletAddress: $sendToWalletAddress, validationString: $validationString) {
      result
      numItemsTransferred
    }
  }
`;


const GET_SPINS = gql`
  query GetSpins {
    spins {
      id
      status
      purchased_time
      spin_time
      spin_result
      package_num_spins
      purchased_trx_hash
    }
  }
`;

const GET_SPIN_PURCHASES = gql`
  query GetSpinPurchases {
    spinPurchases {
      watch_id
      trx_hash
      quantity
      stakeAddress
      status
      modified_time
    }
  }
`;


const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];



const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};


let retcb = null;

const TransferInventory = (props, v2, v3) => {
  const { width, height } = useWindowSize();

  const [tab, setTab] = useState('spin');

  const [sendToWalletAddress, setSendToWalletAddress] = useState('');
  const [validationString, setValidationString] = useState('');
  
  const { state, walletGetSpins, team, raccoonUpgradesApplied, globalLucid, backgroundRefreshWallet } = useContext(GlobalStateContext);

  const [toastColor, setToastColor] = useState('#e08705');

  const [spins, setSpins] = useState(null);

  const [spinPurchases, setSpinPurchases] = useState(null);

  let hasBankerCard = false;
  if (state && state.bankerCardsInWallet && state.bankerCardsInWallet.length > 0) {
    hasBankerCard = true;
  }

  let fiveSpinPrice = "25,000,000";
  let twentyFourSpinPrice = "100,000,000";
  let seventyFiveSpinPrice = "200,000,000";
  let fiveSpinPricePerSpin = "5.0M";
  let twentyFourSpinPricePerSpin = "4.16M";
  let seventyFiveSpinPricePerSpin = "2.66M";

  if (hasBankerCard) {
    fiveSpinPrice = "22,500,000";
    twentyFourSpinPrice = "90,000,000";
    seventyFiveSpinPrice = "180,000,000";
    fiveSpinPricePerSpin = "4.5M";
    twentyFourSpinPricePerSpin = "3.75M";
    seventyFiveSpinPricePerSpin = "2.40M";
  }


  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
        }
    }
  });


  const [getSpins, { loading: loadingGetSpins, error: errorGetSpins, data: dataGetSpins, refetch: refetchGetSpins }] = useLazyQuery(GET_SPINS);

  const [getSpinPurchases, { loading: loadingGetSpinPurchases, error: errorGetSpinPurchases, data: dataGetSpinPurchases, refetch: refetchGetSpinPurchases }] = useLazyQuery(GET_SPIN_PURCHASES);

  


  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
            getSpins({variables: {}});
            getSpinPurchases({variables: {}});
          }
        }
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);

  useEffect(() => {
    if (globalLucid) {
      localRefreshWallet({fromApi: true});
    }
  }, [state.api]);


  const [transferMyInventory, { data: dataTransferMyInventory, loading: loadingTransferMyInventory, error: errorTransferMyInventory }] = useMutation(TRANSFER_MY_INVENTORY, 
    {
      onCompleted: response => {
        console.log('back from transferMyInventory with response', response);
        if (response && response.transferMyInventory && response.transferMyInventory.result === 'fail - compute in progress') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Cannot transfer inventory while we are computing epoch results.");
          }, 200);
        } else if (response && response.transferMyInventory && response.transferMyInventory.result === 'fail - no wallet connected') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("No wallet connected. Connect your wallet in the top right.");
          }, 200);
        } else if (response && response.transferMyInventory && response.transferMyInventory.result === 'fail - invalid address') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("The cardano address you provided is a not a valid cardano address.");
          }, 200);
        } else if (response && response.transferMyInventory && response.transferMyInventory.result === 'fail - incorrect validation string') {
          setToastColor('#e08705');
          setTimeout(() => {
            toast("Incorrect confirmation text. Be sure to type 'TRANSFER ALL OF MY INVENTORY' into the form field below.");
          }, 200);
        } else if (response && response.transferMyInventory && response.transferMyInventory.result === 'success') {
          let numTransferredItems = 0;
          if (response.transferMyInventory.numItemsTransferred) {
            numTransferredItems = response.transferMyInventory.numItemsTransferred;
          }
          setToastColor('#14711f');
          setTimeout(() => {
            toast(`Successfully transferred ${numTransferredItems} to address ${sendToWalletAddress}.`)
          }, 200);
        }
    }
  });

  const onTransferMyInventory = () => {
    transferMyInventory({variables: {sendToWalletAddress: sendToWalletAddress, validationString: validationString}});
  }


  return (
    <>
      <div className="home_wrapper">
        
        <div style={{backgroundImage: 'url("https://rswebpublic2.s3.amazonaws.com/gamegfx/honeycomb.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', width: '100%', height: '500px', zIndex: -5, position: 'absolute', top: 0, left: 0}} />

        <div className="logo_container">
          <img src="rs_logo_banner.png" className="logo_banner_raccoon_details" onClick={() => window.location = "/"} /> <br/>
        </div>

        <div className="global_leaderboard_header_box">
          <div className="leaderboard_title_row">
          <div className="global_leaderboard_title_text">
                <div className={tab === 'spin' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => { setTab('spin');}} style={{fontSize: '31px'}}>TRANSFER YOUR INVENTORY</div>
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>
        </div>


          <div className="global_leaderboard_header_box" style={{marginTop: '40px'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: '32px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                  <div>
                    Transfer all of your inventory to another wallet.
                  </div>
                </div>
              </div>
            </div>


            <div style={{display: 'flex', flexDirection: 'row', marginTop: '30px'}}>
              <div className={"tier_leaderboard_text_selected"} style={{fontSize: '22px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                  <div>
                    The tool below will transfer ALL of your in-game inventory to another wallet.<br/><br/>
                    This includes everything on the "MY INVENTORY" tab which you can see by selecting "MY NFTS" from the menu in the top right.<br/><br/>
                    Any NFTs that you have in your Cardano wallet will *not* be transferred. Only your in-game items (that are not yet on the blockchain) will be transferred. These are the items in your "My Inventory" tab.<br/><br/>
                    Consumable Items, Attachment Upgrades, Squad Battle Potions, Battle Point Upgrades, and anything else listed in your "My Inventory" tab will be transferred.<br/><br/>
                    Your entire inventory (all of your inventory items) will be transferred. It is not possible to transfer only some of your inventory items.<br/><br/>
                    THIS OPERATION CANNOT BE UNDONE! ONCE YOUR ITEMS ARE TRANSFERRED, THEY ARE GONE FOREVER TO THE OTHER WALLET. RACCOON SYNDICATE STAFF CANNOT HELP UNDO THE TRANSFER.<br/><br/><br/>
                    To continue, complete the form and hit the "Transfer all my inventory items" button below.<br/><br/><br/>

                    Cardano wallet address to send TO (must start with 'addr'):<br/>
                    <textarea 
                        className="message_page_send_message_textarea" 
                        style={{maxWidth: '700px', border: '1px solid #e08705', backgroundColor: '#252525', marginTop: '10px'}}
                        rows={3}
                        value={sendToWalletAddress} 
                        onChange={e => {
                          let inputString = e.target.value;
                          setSendToWalletAddress(inputString);
                        }}
                    />

                    <br/><br/><br/>


                    Type into the box "TRANSFER ALL OF MY INVENTORY":<br/>
                    <textarea 
                        className="message_page_send_message_textarea" 
                        style={{maxWidth: '700px', border: '1px solid #e08705', backgroundColor: '#252525', marginTop: '10px'}}
                        rows={3}
                        value={validationString} 
                        onChange={e => {
                          let inputString = e.target.value;
                          setValidationString(inputString);
                        }}
                    />    

                    <br/><br/>

                    <div className="message_page_join_button_container" style={{justifyContent: 'flex-start'}}>
                      <div className="message_page_join_button" style={{fontSize: '25px', padding: '10px 20px', cursor: 'pointer'}} onClick={() => {
                        onTransferMyInventory();
                      }}>
                        TRANSFER ALL OF MY INVENTORY
                      </div>
                    </div>                
                  </div>
                </div>
              </div>
            </div>



          </div>


        
        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />

      </div>
    </>
  );
};


export default TransferInventory;

