import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';


const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $inventoryItemId: String, $requestType: String!, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, inventoryItemId: $inventoryItemId, requestType: $requestType, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;

const USE_INVENTORY_CONSUMABLE = gql`
  mutation UseInventoryConsumable($inventoryItemId: String!, $raccoonNum: Int) {
    useInventoryConsumable(inventoryItemId: $inventoryItemId, raccoonNum: $raccoonNum) {
      result
      raccoonNum
      inventoryItemId
    }
  }
`;





const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsBoostUpcoming = [
  { value: 'No Epoch Trait Upcoming', label: 'No Epoch Trait Upcoming' },
  { value: 'Epoch Trait Upcoming', label: 'Epoch Trait Upcoming' },
  { value: 'Any', label: 'Any' },
];

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}


const getItemCategory = item => {

  let category = 'None';

  if (item && item.indexOf && item.indexOf('Milk') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Canteen') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Vegebite') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Beans') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('PotionX') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Gummy') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Mushrooms') >= 0) {
    category = 'Consumable';
  } else if (item && item.indexOf && item.indexOf('Gold Ring') >= 0) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('Emerald Ring') >= 0) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('Ruby Ring') >= 0) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('Diamond Ring') === 0 || item.indexOf('Diamond Ring') === 3) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('CompoundX Ring') >= 0) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('Blue Diamond Ring') >= 0) {
    category = 'Ring';
  } else if (item && item.indexOf && item.indexOf('Light Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('Dark Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('Gold Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('Psychedelic Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('CompoundX Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('Blue Diamond Wings') >= 0) {
    category = 'Wings';
  } else if (item && item.indexOf && item.indexOf('10 Battle Points') >= 0) {
    category = 'Battle Points';
  } else if (item && item.indexOf && item.indexOf('20 Battle Points') >= 0) {
    category = 'Battle Points';
  } else if (item && item.indexOf && item.indexOf('50 Battle Points') >= 0) {
    category = 'Battle Points';
  } else if (item && item.indexOf && item.indexOf('100 Battle Points') >= 0) {
    category = 'Battle Points';
  } else if (item && item.indexOf && item.indexOf('Green Squad Potion') >= 0) {
    category = 'Squad Potion';
  } else if (item && item.indexOf && item.indexOf('Blue Squad Potion') >= 0) {
    category = 'Squad Potion';
  } else if (item && item.indexOf && item.indexOf('Red Squad Potion') >= 0) {
    category = 'Squad Potion';
  } else if (item && item.indexOf && item.indexOf('Purple Squad Potion') >= 0) {
    category = 'Squad Potion';
  }

  return category;  

}


const SelectItemModal = (props) => {
  let {show, close, source, advancedForge, boxNumber, itemsToReceiveFromForge, buttonLabel, raccoonUpgradesInWallet, raccoonInfoLocal, inventoryItems, includeConsumables, includeAttachables, includeSquadPotions, cellItems, getUpgradeStatusData, parentSetToastColor, refreshRaccoonInfo, refreshInventory, selectItem} = props;

  const [tab, setTab] = useState((!includeSquadPotions || advancedForge !== "true") ? 'myUpgrades' : 'myInventory');
  const [messageToRaccoon, setMessageToRaccoon] = useState("");

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [hideUseButton, setHideUseButton] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, walletSendConsumableUpgradeNFT, walletSendAttachableUpgradeNFT, walletSendConsumableUpgradeFromInventory, walletSendAttachableUpgradeFromInventory, currentEpoch } = useContext(GlobalStateContext);

  
  function onUseInventoryConsumable(inventoryItem, raccoonNum) {
    consumeInventoryConsumable({ variables: { inventoryItemId: inventoryItem.id, raccoonNum }});
  }

  const [consumeInventoryConsumable, { data: dataUseInventoryConsumable, loading: loadingUseInventoryConsumable, error: errorUseInventoryConsumable }] = useMutation(USE_INVENTORY_CONSUMABLE, 
    {
      onCompleted: response => {
        setHideUseButton(false);

        if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'success') {
          setToastColor('#14711f');
          if (parentSetToastColor) {
            parentSetToastColor('#14711f');
          }
          setTimeout(() => {
            toast("Upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has upgrade coming soon') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon aready has a consumable upgrade applied.")
            getUpgradeStatusData();
          }, 200);
        } else if (response && response.useInventoryConsumable && response.useInventoryConsumable.result === 'fail - Already has too many battle point upgrades applied') {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That Raccoon aready has too many battle point upgrades applied.")
            getUpgradeStatusData();
          }, 200);
        }
      }
    }
  );


  const [trxCreateWatch, { data: dataTRXCreateWatch, loading: loadingTRXCreateWatch, error: errorTRXCreateWatch }] = useMutation(TRX_CREATE_WATCH, 
    {
      onCompleted: response => {
        setTimeout(() => {
          setHideUseButton(false);
        }, 1000);
        if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'fail - Already has upgrade coming soon') {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an upcoming upgrade.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('Raccoon already wearing an attachable') >= 0) {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Raccoon already has an attachable. Detach their existing attachable first.")
            }, 200);
        } else  if (response && response.trxCreateWatch && response.trxCreateWatch.result.indexOf('fail 11 - upgrade already applied to a Raccoon and waiting for blockchain') >= 0) {
          setToastColor('#e08705');
          if (parentSetToastColor) {
            parentSetToastColor('#e08705');
          }
          setTimeout(() => {
            toast("That upgrade item is already in progress of being used on a Raccoon and is waiting for the blockchain to process it.")
          }, 200);
        } else if (response && response.trxCreateWatch && response.trxCreateWatch.result === 'success' && response.trxCreateWatch.assetNameHex) {

          const sendUpgradeSuccess = () => {
            setToastColor('#14711f');
            if (parentSetToastColor) {
              parentSetToastColor('#14711f');
            }
            setTimeout(() => {
              toast("Upgrade sent. Wait 10-30 minutes for the blockchain and then for your My Raccoons list to be updated.")
              getUpgradeStatusData();
            }, 200);
            refreshRaccoonInfo();
            refreshInventory();
          }

          const sendUpgradeFailure = () => {
            setToastColor('#e08705');
            if (parentSetToastColor) {
              parentSetToastColor('#e08705');
            }
            setTimeout(() => {
              toast("Failed to send upgrade. Please make sure you have enough ADA in your wallet (6 for consumables, 12 for attachables) Try again and sign using your wallet extension. Also, wait 2 minutes between requests for your wallet to sync to the blockchain.")
            }, 200);
          }

          if (response.trxCreateWatch.requestType === 'useInventoryItem') {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              // walletSendConsumableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              // walletSendAttachableUpgradeFromInventory(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.inventoryItemId, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, upgradeType, sendUpgradeSuccess, sendUpgradeFailure);
            }
          } else {
            if (response.trxCreateWatch.consumableOrAttachable === 'consumable') {
              walletSendConsumableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            } else if (response.trxCreateWatch.consumableOrAttachable === 'attachable') {
              walletSendAttachableUpgradeNFT(response.trxCreateWatch.assetNameHex, response.trxCreateWatch.raccoonNum, response.trxCreateWatch.watchId, sendUpgradeSuccess, sendUpgradeFailure);
            }
          }
          
        }
    }
  });







  if (!show) {
    return <div />
  }

  let numFixedBattlePoints = 0;


  let itemToReceiveFromForge = null;
  let itemToReceiveFromForgeCategory = null;
  if (itemsToReceiveFromForge && itemsToReceiveFromForge[boxNumber]) {
    itemToReceiveFromForge = itemsToReceiveFromForge[boxNumber];
    itemToReceiveFromForgeCategory = getItemCategory(itemToReceiveFromForge);
  }
  
  return (
    <>
      <div className="squad_add_modal_outside_container"  onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        close();
      }}>
        <div className="squad_add_modal" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <div className="squad_add_modal_header_box">
          <div className="leaderboard_title_row">
            <div className="global_leaderboard_title_text">
              <div className={"squad_add_modal_header_selected"}>SELECT AN UPGRADE</div>
            </div>      
          </div>
            
          <div className="leaderboard_title_row" style={{marginTop: '40px'}}>
          <div className="global_leaderboard_title_text">
              {(!includeSquadPotions || advancedForge !== "true") && (
                <>
                  <div className={tab === 'myUpgrades' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('myUpgrades');}} style={{fontSize: '29px'}}>MY NFTS</div> 
                  <div className="tier_leaderboard_text_separator">|</div>
                </>
              )}
                <div className={tab === 'myInventory' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('myInventory');}} style={{fontSize: '29px'}}>MY INVENTORY</div> 
            </div>      
          </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>

        {tab === 'myUpgrades' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '70vh', overflowY: 'scroll'}}>
          {state.doneInitialWalletLoad && raccoonUpgradesInWallet && raccoonUpgradesInWallet.length > 0 &&
            raccoonUpgradesInWallet.map((upgrade, i) => {

              if (cellItems) {
                for (let itemsBoxNumber in cellItems) {
                  if (cellItems.hasOwnProperty(itemsBoxNumber)) {
                    let foundItem = false;
                    if (cellItems[itemsBoxNumber].forEach) {
                      cellItems[itemsBoxNumber].forEach((item) => {
                        if (item.upgrade) {
                          if (upgrade.assetNameAscii === item.upgrade.assetNameAscii) {
                            foundItem = true;
                          }
                        }
                      });
                    } else {
                      if (cellItems[itemsBoxNumber].upgrade) {
                        if (cellItems[itemsBoxNumber].assetNameAscii === cellItems[itemsBoxNumber].upgrade.assetNameAscii) {
                          foundItem = true;
                        }
                      }                      
                    }

                    if (foundItem) {
                      return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
                    }
                  }
                }
              }

              if (source === 'forge' && upgrade && upgrade.assetNameAscii && upgrade.assetNameAscii.indexOf('Blue Diamond') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }
              if (source === 'forge' && upgrade && upgrade.assetNameAscii && upgrade.assetNameAscii.indexOf('Mushrooms') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }
              if (source === 'forge' && upgrade && upgrade.assetNameAscii && upgrade.assetNameAscii.indexOf('Purple Squad Potion') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }

              let asciiName = upgrade.assetNameAscii;
              let asciiPrefix = null;
              let upgradeNum = null;
              let imgSrc = null;
              let bpmBoost = '';
              let squadBattlePowerLevelBoost = null;
              let boostNumEpochs = 2;

              let upgradeWaitingForBlockchain = false;

              let isConsumableItem = false;
              let isAttachableItem = false;
              let isSquadPotion = false;

              if (asciiName && (asciiName.indexOf('Milk') >= 0 || asciiName.indexOf('Canteen') >= 0 || asciiName.indexOf('Vegebite') >= 0 || asciiName.indexOf('Baked Beans') >= 0 || asciiName.indexOf('PotionX') >= 0 || asciiName.indexOf('Gummy Raccoon') >= 0 || asciiName.indexOf('Mushrooms') >= 0)) {
                isConsumableItem = true;
              } else if (asciiName && (asciiName.indexOf(' Ring') >= 0 || asciiName.indexOf(' Wings') >= 0)) {
                isAttachableItem = true;
              } else if (asciiName && (asciiName.indexOf(' Squad Potion') >= 0)) {
                isSquadPotion = true;
              }

              if (isConsumableItem && !includeConsumables) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }
              if (isAttachableItem && !includeAttachables) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }
              if (isSquadPotion && !includeSquadPotions) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }

              let itemCategory = getItemCategory(asciiName);

              if (source === "forge" && advancedForge === "true") {
                if (itemCategory !== itemToReceiveFromForgeCategory) {
                  return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
                }
              }

              if (asciiName.indexOf('Milk') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif'
                asciiPrefix = 'RS Milk #';
                upgradeNum = asciiName.substring('RS Milk '.length, asciiName.length);
                bpmBoost = '30%';
                boostNumEpochs = 2;
              } else if (asciiName.indexOf('Canteen') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
                asciiPrefix = 'RS Canteen #';
                upgradeNum = asciiName.substring('RS Canteen '.length, asciiName.length);
                bpmBoost = '50%';
                boostNumEpochs = 2;
              } else if (asciiName.indexOf('Vegebite') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif'
                asciiPrefix = 'RS Vegebite #';
                upgradeNum = asciiName.substring('RS Vegebite '.length, asciiName.length);
                bpmBoost = '60%';
                boostNumEpochs = 2;
              } else if (asciiName.indexOf('Baked Beans') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif'
                asciiPrefix = 'RS Baked Beans #';
                upgradeNum = asciiName.substring('RS Baked Beans '.length, asciiName.length);
                bpmBoost = '50%';
                boostNumEpochs = 3;
              } else if (asciiName.indexOf('PotionX') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif'
                asciiPrefix = 'RS PotionX #';
                upgradeNum = asciiName.substring('RS PotionX '.length, asciiName.length);
                bpmBoost = '60%';
                boostNumEpochs = 3;
              } else if (asciiName.indexOf('Gummy Raccoon') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif'
                asciiPrefix = 'RS Gummy Raccoon #';
                upgradeNum = asciiName.substring('RS Gummy Raccoon '.length, asciiName.length);
                bpmBoost = '65%';
                boostNumEpochs = 3;
              } else if (asciiName.indexOf('Mushrooms') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif'
                asciiPrefix = 'RS Mushrooms #';
                upgradeNum = asciiName.substring('RS Mushrooms '.length, asciiName.length);
                bpmBoost = '70%';
                boostNumEpochs = 3;
              } else if (asciiName.indexOf('Gold Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif'
                asciiPrefix = 'RS Gold Ring #';
                upgradeNum = asciiName.substring('RS Gold Ring '.length, asciiName.length);
                bpmBoost = '20%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Emerald Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif'
                asciiPrefix = 'RS Emerald Ring #';
                upgradeNum = asciiName.substring('RS Emerald Ring '.length, asciiName.length);
                bpmBoost = '25%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Ruby Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif'
                asciiPrefix = 'RS Ruby Ring #';
                upgradeNum = asciiName.substring('RS Ruby Ring '.length, asciiName.length);
                bpmBoost = '30%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Diamond Ring') === 0 || asciiName.indexOf('Diamond Ring') === 3) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif'
                asciiPrefix = 'RS Diamond Ring #';
                upgradeNum = asciiName.substring('RS Diamond Ring '.length, asciiName.length);
                bpmBoost = '35%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('CompoundX Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif'
                asciiPrefix = 'RS CompoundX Ring #';
                upgradeNum = asciiName.substring('RS CompoundX Ring '.length, asciiName.length);
                bpmBoost = '40%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Blue Diamond Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif'
                asciiPrefix = 'RS Blue Diamond Ring #';
                upgradeNum = asciiName.substring('RS Blue Diamond Ring '.length, asciiName.length);
                bpmBoost = '45%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Light Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif'
                asciiPrefix = 'RS Light Wings #';
                upgradeNum = asciiName.substring('RS Light Wings '.length, asciiName.length);
                bpmBoost = '50%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Dark Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif'
                asciiPrefix = 'RS Dark Wings #';
                upgradeNum = asciiName.substring('RS Dark Wings '.length, asciiName.length);
                bpmBoost = '55%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Gold Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif'
                asciiPrefix = 'RS Gold Wings #';
                upgradeNum = asciiName.substring('RS Gold Wings '.length, asciiName.length);
                bpmBoost = '60%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Psychedelic Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif'
                asciiPrefix = 'RS Psychedelic Wings #';
                upgradeNum = asciiName.substring('RS Psychedelic Wings '.length, asciiName.length);
                bpmBoost = '65%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('CompoundX Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif'
                asciiPrefix = 'RS CompoundX Wings #';
                upgradeNum = asciiName.substring('RS CompoundX Wings '.length, asciiName.length);
                bpmBoost = '70%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Blue Diamond Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif'
                asciiPrefix = 'RS Blue Diamond Wings #';
                upgradeNum = asciiName.substring('RS Blue Diamond Wings '.length, asciiName.length);
                bpmBoost = '75%';
                boostNumEpochs = -1;
              } else if (asciiName.indexOf('Green Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
                asciiPrefix = 'RS Squad Potion #';
                upgradeNum = asciiName.substring('RS Squad Potion '.length, asciiName.length);
                squadBattlePowerLevelBoost = 10;
                boostNumEpochs = 1;
              } else if (asciiName.indexOf('Blue Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
                asciiPrefix = 'RS Squad Potion #';
                upgradeNum = asciiName.substring('RS Squad Potion '.length, asciiName.length);
                squadBattlePowerLevelBoost = 20;
                boostNumEpochs = 1;
              } else if (asciiName.indexOf('Red Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
                asciiPrefix = 'RS Squad Potion #';
                upgradeNum = asciiName.substring('RS Squad Potion '.length, asciiName.length);
                squadBattlePowerLevelBoost = 30;
                boostNumEpochs = 1;
              } else if (asciiName.indexOf('Purple Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
                asciiPrefix = 'RS Squad Potion #';
                upgradeNum = asciiName.substring('RS Squad Potion '.length, asciiName.length);
                squadBattlePowerLevelBoost = 40;
                boostNumEpochs = 1;
              }


              let otherBoxNumber = boxNumber + 1;
              if (boxNumber % 2 === 0) {
                otherBoxNumber = boxNumber - 1;
              }

              if (cellItems && cellItems[parseInt(otherBoxNumber)] && cellItems[parseInt(otherBoxNumber)].raccoon) {
                let otherRaccoonAsciiName = cellItems[parseInt(otherBoxNumber)].raccoon.assetNameAscii;
                let otherRaccoonRaccoonNum = otherRaccoonAsciiName.substring('Raccoon '.length, otherRaccoonAsciiName.length);
                let otherRaccoonInfo = {};
                if (otherRaccoonInfo) {
                  let hasRing = false;
                  let hasWings = false;

                  otherRaccoonInfo = raccoonInfoLocal[otherRaccoonRaccoonNum];
                  if (otherRaccoonInfo && ((otherRaccoonInfo['ring'] && otherRaccoonInfo['ring'] !== 'None'))) {
                    hasRing = true;
                  }
                  if (otherRaccoonInfo && ((otherRaccoonInfo['wings'] && otherRaccoonInfo['wings'] !== 'None'))) {
                    hasWings = true;
                  }

                  if (hasRing && asciiName.indexOf(' Ring') > 0) {
                    // return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
                  }
                  if (hasWings && asciiName.indexOf(' Wings') > 0) {
                    // return <div key={`retup_${upgrade.assetNameAscii}+${i}`}/>
                  }                  
                }
              }

              if (source === 'forge' && asciiName.indexOf('Blue Diamond Ring') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              } else if (source === 'forge' && asciiName.indexOf('Blue Diamond Wings') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              } else if (source === 'forge' && asciiName.indexOf('Mushrooms') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              } else if (source === 'forge' && asciiName.indexOf('Purple Squad Potion') >= 0) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }

              if (!imgSrc) {
                return <div key={`retup_${upgrade.assetNameAscii}_${i}`}/>
              }


              return (
                <div className="my_nfts_nft_box_upgrades" style={{textAlign: 'center'}} key={`${asciiPrefix}_${upgradeNum}`}>
                  <img src={imgSrc} className="my_nfts_nft_image_upgrades" />
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '4px'}}>
                    <div className="my_nfts_nft_label" style={{display: 'flex', flex: 0.8, fontSize: '16px'}}>
                      {asciiPrefix}{upgradeNum}
                    </div>
                    {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !upgradeWaitingForBlockchain && isAttachableItem &&
                      <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', flex: 0.2, marginTop: '0', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                        <div className="squad_add_modal_add_button" onClick={() => {selectItem({boxNumber, nftOrInventory: 'nft', upgrade})}}>
                          {buttonLabel}
                        </div>
                      </div>
                    }
                    {(!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !upgradeWaitingForBlockchain && isConsumableItem &&
                      <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', flex: 0.2, marginTop: '0', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                        <div className="squad_add_modal_add_button" onClick={() => {
                            // setToastColor('#555');
                            /*
                            setTimeout(() => {
                              toast("Using consumables will be enabled 5 days before Battle Round 2 starts. This will be in mid/late June timeframe.")
                            }, 200);
                            */
                            selectItem({boxNumber, nftOrInventory: 'nft', upgrade})
                        }}>
                          {buttonLabel}
                        </div>
                      </div>
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '5px'}}>
                    {bpmBoost && (
                      <>
                        <div className="my_nfts_nft_label" style={{display: 'flex', flex: 0.5, fontSize: '16px'}}>
                          {bpmBoost} Boost
                        </div>
                        {boostNumEpochs >= 1 &&
                          <div className="my_nfts_nft_label" style={{display: 'flex', justifyContent: 'flex-end', flex: 0.5, fontSize: '16px'}}>
                            {boostNumEpochs} Epochs
                          </div>
                        }                      
                      </>
                    )}
                    {squadBattlePowerLevelBoost && (
                      <>
                        <div className="my_nfts_nft_label" style={{display: 'flex', flex: 1, fontSize: '16px'}}>
                          +{squadBattlePowerLevelBoost} Power Level All Squad Raccoons for 1 Epoch
                        </div>
                      </>
                    )}                   
                  </div>
                  {upgradeWaitingForBlockchain && (
                    <div className="upgrade_waiting_for_blockchain_spinner">
                      <Tooltip placement="top" content={<div className="use_upgrade_tooltip">Waiting for blockchain</div>}>
                        <ClipLoader
                          color={'#FFF'}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )
            })
          }
          {state.doneInitialWalletLoad && (!raccoonUpgradesInWallet || raccoonUpgradesInWallet.length <= 0) &&
            <div className="send_invite_result">You do not have any Upgrade NFTs in any of your connected wallets.</div>
          }
          </div>
        }



        {tab === 'myInventory' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '70vh', overflowY: 'scroll'}}>
          {state.doneInitialWalletLoad && inventoryItems && inventoryItems.length > 0 &&
            inventoryItems.map((inventoryItem, i) => {

              if (cellItems) {
                for (let itemsBoxNumber in cellItems) {
                  if (cellItems.hasOwnProperty(itemsBoxNumber)) {
                    let foundItem = false;
                    if (cellItems[itemsBoxNumber].forEach) {
                      cellItems[itemsBoxNumber].forEach((item) => {
                        if (item.upgrade) {
                          if (inventoryItem.id === item.upgrade.id) {
                            foundItem = true;
                          }
                        }
                      });
                    } else {
                      if (cellItems[itemsBoxNumber].upgrade) {
                        if (inventoryItem.id === cellItems[itemsBoxNumber].upgrade.id) {
                          foundItem = true;
                        }
                      }
                    }
                    
                    if (foundItem) { 
                      return <div key={`retup_${inventoryItem.id}_${i}`}/>
                    }
                  }
                }
              }

              if (source === 'forge' && inventoryItem && inventoryItem.type && inventoryItem.type.indexOf('Blue Diamond') >= 0) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (source === 'forge' && inventoryItem && inventoryItem.type && inventoryItem.type.indexOf('Mushrooms') >= 0) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (source === 'forge' && inventoryItem && inventoryItem.type && inventoryItem.type.indexOf('Purple Squad Potion') >= 0) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (inventoryItem.type && inventoryItem.type.indexOf(' Battle Points') >= 0) {
                return <div key={`inv_skip_${i}`} />;
              }

              let imgSrc = null;

              let bpmBoost = '';
              let squadBattlePowerLevelBoost = 0;
              let boostNumEpochs = 2;

              let upgradeWaitingForBlockchain = false;
              if (inventoryItem.status === 'waitingForBlockchain') {
                upgradeWaitingForBlockchain = true;
              }

              let upgradeType = inventoryItem.type;

              let isConsumableItem = false;
              let isAttachableItem = false;
              let isSquadPotion = false;

              if (upgradeType && (upgradeType.indexOf('Milk') >= 0 || upgradeType.indexOf('Canteen') >= 0 || upgradeType.indexOf('Vegebite') >= 0 || upgradeType.indexOf('Baked Beans') >= 0 || upgradeType.indexOf('PotionX') >= 0 || upgradeType.indexOf('Gummy Raccoon') >= 0 || upgradeType.indexOf('Mushrooms') >= 0 || upgradeType.indexOf('10 Battle Points') >= 0 || upgradeType.indexOf('20 Battle Points') >= 0 || upgradeType.indexOf('50 Battle Points') >= 0 || upgradeType.indexOf('100 Battle Points') >= 0)) {
                isConsumableItem = true;
              } else if (upgradeType && (upgradeType.indexOf(' Ring') >= 0 || upgradeType.indexOf(' Wings') >= 0)) {
                isAttachableItem = true;
              } else if (upgradeType && (upgradeType.indexOf(' Squad Potion') >= 0)) {
                isSquadPotion = true;
              }

              if (isConsumableItem && !includeConsumables) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (isAttachableItem && !includeAttachables) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (isSquadPotion && !includeSquadPotions) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }


              let createBoostSpacer = false;

              let asciiPrefix = 'Upgrade';

              let itemCategory = getItemCategory(upgradeType);

              if (source === "forge" && advancedForge === "true") {
                if (itemCategory !== itemToReceiveFromForgeCategory) {
                  return <div key={`retup_${inventoryItem.id}_${i}`}/>
                }
              }

              if (upgradeType.indexOf('Milk') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif'
                asciiPrefix = 'RS Milk';
                bpmBoost = '30% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Canteen') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
                asciiPrefix = 'RS Canteen';
                bpmBoost = '50% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Vegebite') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif'
                asciiPrefix = 'RS Vegebite';
                bpmBoost = '60% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Baked Beans') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif'
                asciiPrefix = 'RS Baked Beans';
                bpmBoost = '50% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('PotionX') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif'
                asciiPrefix = 'RS PotionX';
                bpmBoost = '60% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Gummy Raccoon') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif'
                asciiPrefix = 'RS Gummy Raccoon';
                bpmBoost = '65% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Mushrooms') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif'
                asciiPrefix = 'RS Mushrooms';
                bpmBoost = '70% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Gold Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif'
                asciiPrefix = 'RS Gold Ring';
                bpmBoost = '20% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Emerald Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif'
                asciiPrefix = 'RS Emerald Ring';
                bpmBoost = '25% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Ruby Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif'
                asciiPrefix = 'RS Ruby Ring';
                bpmBoost = '30% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Diamond Ring') === 0 || upgradeType.indexOf('Diamond Ring') === 3) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif'
                asciiPrefix = 'RS Diamond Ring';
                bpmBoost = '35% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('CompoundX Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif'
                asciiPrefix = 'RS CompoundX Ring';
                bpmBoost = '40% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Blue Diamond Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif'
                asciiPrefix = 'RS Blue Diamond Ring';
                bpmBoost = '45% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Light Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif'
                asciiPrefix = 'RS Light Wings';
                bpmBoost = '50% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Dark Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif'
                asciiPrefix = 'RS Dark Wings';
                bpmBoost = '55% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Gold Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif'
                asciiPrefix = 'RS Gold Wings';
                bpmBoost = '60% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Psychedelic Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif'
                asciiPrefix = 'RS Psychedelic Wings';
                bpmBoost = '65% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('CompoundX Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif'
                asciiPrefix = 'RS CompoundX Wings';
                bpmBoost = '70% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Blue Diamond Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif'
                asciiPrefix = 'RS Blue Diamond Wings';
                bpmBoost = '75% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('10 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/10_512x512.png'
                asciiPrefix = 'RS 10 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('20 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/20_512x512.png'
                asciiPrefix = 'RS 20 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('50 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/50_512x512.png'
                asciiPrefix = 'RS 50 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('100 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/100_Gold_512x512.png'
                asciiPrefix = 'RS 100 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('Green Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
                asciiPrefix = 'RS Green Squad Potion';
                squadBattlePowerLevelBoost = 10;
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Blue Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
                asciiPrefix = 'RS Blue Squad Potion';
                squadBattlePowerLevelBoost = 20;
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Red Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
                asciiPrefix = 'RS Red Squad Potion';
                squadBattlePowerLevelBoost = 30;
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Purple Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
                asciiPrefix = 'RS Purple Squad Potion';
                squadBattlePowerLevelBoost = 40;
                boostNumEpochs = 1;
              }

              let otherBoxNumber = boxNumber + 1;
              if (boxNumber % 2 === 0) {
                otherBoxNumber = boxNumber - 1;
              }

              if (cellItems && cellItems[parseInt(otherBoxNumber)] && cellItems[parseInt(otherBoxNumber)].raccoon) {
                let otherRaccoonAsciiName = cellItems[parseInt(otherBoxNumber)].raccoon.assetNameAscii;
                let otherRaccoonRaccoonNum = otherRaccoonAsciiName.substring('Raccoon '.length, otherRaccoonAsciiName.length);
                let otherRaccoonInfo = {};
                if (otherRaccoonInfo) {
                  let hasRing = false;
                  let hasWings = false;

                  otherRaccoonInfo = raccoonInfoLocal[otherRaccoonRaccoonNum];
                  if (otherRaccoonInfo && ((otherRaccoonInfo['ring'] && otherRaccoonInfo['ring'] !== 'None'))) {
                    hasRing = true;
                  }
                  if (otherRaccoonInfo && ((otherRaccoonInfo['wings'] && otherRaccoonInfo['wings'] !== 'None'))) {
                    hasWings = true;
                  }

                  if (hasRing && upgradeType.indexOf(' Ring') > 0) {
                    // return <div key={`retup_${upgradeType}_${i}`}/>
                  }
                  if (hasWings && upgradeType.indexOf(' Wings') > 0) {
                    // return <div key={`retup_${upgradeType}_${i}`}/>
                  }                  
                }
              }

              if (source === 'forgeItems' && upgradeType.indexOf('Blue Diamond Ring') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              } else if (source === 'forgeItems' && upgradeType.indexOf('Blue Diamond Wings') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              } else if (source === 'forgeItems' && upgradeType.indexOf('Mushrooms') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              } else if (source === 'forgeItems' && upgradeType.indexOf('Purple Squad Potion') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              }

              if (!imgSrc) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              }

              let showMintButton = (!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !upgradeWaitingForBlockchain && (upgradeType !== '10 Battle Points' && upgradeType !== '20 Battle Points' && upgradeType !== '50 Battle Points' && upgradeType !== '100 Battle Points');

              return (
                <div className="my_nfts_nft_box_upgrades" style={{textAlign: 'center'}} key={`${upgradeType}_${i}`}>
                  <img src={imgSrc} className="my_nfts_nft_image_upgrades" style={{}}/>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '4px'}}>
                    <div className="my_nfts_nft_label" style={{display: 'flex', flex: 0.8, fontSize: '16px'}}>
                      {upgradeType}
                    </div>
                    {inventoryItem.status === 'inventoryItem' &&
                      (
                        <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', flex: 0.2, marginTop: '0', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                          <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {selectItem({boxNumber, nftOrInventory: 'inventoryItem', upgrade: {...inventoryItem, assetNameAscii: inventoryItem.type}})}}>
                            {buttonLabel}
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '5px'}}>
                    {bpmBoost && (
                      <>
                        <div className="my_nfts_nft_label" style={{display: 'flex', flex: 0.5, fontSize: '16px'}}>
                          {bpmBoost}
                        </div>
                        {boostNumEpochs >= 1 &&
                          <div className="my_nfts_nft_label" style={{display: 'flex', justifyContent: 'flex-end', flex: 0.5, fontSize: '16px'}}>
                            {boostNumEpochs} Epochs
                          </div>
                        }                      
                      </>
                    )}
                    {squadBattlePowerLevelBoost && (
                      <>
                        <div className="my_nfts_nft_label" style={{display: 'flex', flex: 1, fontSize: '16px'}}>
                          +{squadBattlePowerLevelBoost} Power Level All Squad Raccoons for 1 Epoch
                        </div>
                      </>
                    )}
                    {createBoostSpacer && (
                      <div className="my_nfts_nft_label" style={{display: 'flex', justifyContent: 'flex-end', flex: 0.5, fontSize: '16px'}}>
                        &nbsp;
                      </div>
                    )}
                  </div>
                  {upgradeWaitingForBlockchain && (
                    <div className="upgrade_waiting_for_blockchain_spinner">
                      <Tooltip placement="top" content={<div className="use_upgrade_tooltip">Waiting for blockchain</div>}>
                        <ClipLoader
                          color={'#FFF'}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )
            })
          }
          </div>
        }


        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => close()}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />
    </>
  );
};


export default SelectItemModal;

