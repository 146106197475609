import React, {useContext, useState, useEffect, useRef, PureComponent} from 'react';
import BattleRoundCountdown from './BattleRoundCountdown';
import {
  useMutation,
  useQuery, 
  useLazyQuery,
  gql
} from "@apollo/client";
import { GlobalStateContext } from '../providers/GlobalStateProvider';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "react-simple-tooltip"
import { useLocation } from "react-router-dom";
import useSize from '@react-hook/size'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { CEASE_FIRE, COMPUTE_IN_PROGRESS } from '../gameConstants';

const RS_POLICY_ID = '59142df7dfea56a5b76b842c206fc7bd844b43a2d10f5eb05f695dcb';
const RS_UPGRADES_POLICY_ID = '7795eb9746390ea24c03c7f9898bb706dd6d3bd5cf5a7ee9ddf9707c';

function hex_to_ascii(str1) {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}


const TRX_CREATE_WATCH = gql`
  mutation TRXCreateWatch($assetName: String!, $inventoryItemId: String, $requestType: String!, $quantity: Int!, $action: String!, $myWalletAddresses: String!, $raccoonNum: Int) {
    trxCreateWatch( assetName: $assetName, inventoryItemId: $inventoryItemId, requestType: $requestType, quantity: $quantity, action: $action, myWalletAddresses: $myWalletAddresses, raccoonNum: $raccoonNum ) {
      result
      assetNameHex
      raccoonNum
      upgradeType
      consumableOrAttachable
      requestType
      inventoryItemId
      watchId
    }
  }
`;

const USE_INVENTORY_CONSUMABLE = gql`
  mutation UseInventoryConsumable($inventoryItemId: String!, $raccoonNum: Int) {
    useInventoryConsumable(inventoryItemId: $inventoryItemId, raccoonNum: $raccoonNum) {
      result
      raccoonNum
      inventoryItemId
    }
  }
`;

const USE_SQUAD_UPGRADE = gql`
  mutation UseSquadUpgrade($inventoryItemId: String, $raccoonNum: Int) {
    useSquadUpgrade(inventoryItemId: $inventoryItemId, raccoonNum: $raccoonNum) {
      result
    }
  }
`;

const GET_INVENTORY = gql`
  query GetInventory {
    inventory {
      id
      type
      status
      earned_method
      was_listed_raccoon_at_end_of_battle_round
    }
  }
`;


const SET_READ_MESSAGES_TIME = gql`
  mutation SetReadMessagesTime($mailboxId: String!, $myWalletAddresses: String!) {
    setReadMessagesTime( mailboxId: $mailboxId, myWalletAddresses: $myWalletAddresses ) {
      result
      mailboxId
    }
  }
`;

const sortOptionsFields = [
  { value: 'Raccoon #', label: 'Raccoon #' },
  { value: 'BPM', label: 'BPM' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Tier Rank', label: 'Tier Rank' },
];

const sortOptionsOrder = [
  { value: 'Descending', label: 'Descending' },
  { value: 'Ascending', label: 'Ascending' },
];

const filterOptionsProfession = [
  { value: 'All Professions', label: 'All Professions' },
  { value: 'Officers', label: 'Officers' },
  { value: 'Non-Officers', label: 'Non-Officers' },
];

const filterOptionsTier = [
  { value: 'All Tiers', label: 'All Tiers' },
  { value: 'Tier 1', label: 'Tier 1' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 3', label: 'Tier 3' },
];

const filterOptionsAvailability = [
  { value: 'Available', label: 'Available' },
  { value: 'In Squad', label: 'In Squad' },
  { value: 'Any', label: 'Any' },
];

const filterOptionsBoostUpcoming = [
  { value: 'No Epoch Trait Upcoming', label: 'No Epoch Trait Upcoming' },
  { value: 'Epoch Trait Upcoming', label: 'Epoch Trait Upcoming' },
  { value: 'Any', label: 'Any' },
];

const sortOrderStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: '#000', 
    color: '#efdbb8',
    outline: 'none',
    borderColor: '#444',
    boxShadow: 'none',
    borderRadius: '10px',
    "&:hover"  : {
      outline: 'none',
      borderColor: '#444',
      boxShadow: 'none',
    },
  }),
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none',
      "&:hover"  : {
        backgroundColor: '#222',
      },
    };
  },
  singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
  menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000',
      color: '#efdbb8',
      outline: 'none'
    };
  },
}


const SelectSquadUpgradeModal = (props) => {
  let {show, close, source, boxNumber, buttonLabel, raccoonNum, raccoonUpgradesInWallet, usedSquadUpgradeCallback, raccoonInfoLocal, includeConsumables, includeAttachables, includeSquadPotions, cellItems, getUpgradeStatusData, parentSetToastColor, refreshRaccoonInfo, refreshInventory} = props;

  const [tab, setTab] = useState('myInventory');
  const [messageToRaccoon, setMessageToRaccoon] = useState("");

  const [sortField, setSortField] = useState('Raccoon #');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [filterProfessionValue, setFilterProfessionValue] = useState('All Professions');
  const [filterTierValue, setFilterTierValue] = useState('All Tiers');
  const [filterAvailabilityValue, setFilterAvailabilityValue] = useState('Any');
  const [filterBoostUpcomingValue, setFilterBoostUpcomingValue] = useState('Any');

  const [inventoryItems, setInventoryItems] = useState([]);

  const [hideUseButton, setHideUseButton] = useState(false);

  const [toastColor, setToastColor] = useState('#e08705');

  const { state, walletSendConsumableUpgradeNFT, walletSendAttachableUpgradeNFT, walletSendConsumableUpgradeFromInventory, walletSendAttachableUpgradeFromInventory, currentEpoch, globalLucid } = useContext(GlobalStateContext);

  const [getInventory, { loading: loadingGetInventory, error: errorGetInventory, data: dataGetInventory, refetch: refetchGetInventory }] = useLazyQuery(GET_INVENTORY);

  useEffect(() => {
    if (dataGetInventory && dataGetInventory.inventory) {
      setInventoryItems(dataGetInventory.inventory);
    }
  }, [dataGetInventory])


  const [setReadMessagesTime, { data: dataSetReadMessagesTime, loading: loadingSetReadMessagesTime, error: errorSetReadMessagesTime }] = useMutation(SET_READ_MESSAGES_TIME, 
    {
      onCompleted: response => {
        if (response && response.setReadMessagesTime && response.setReadMessagesTime.result === 'success') {
          localRefreshWallet();
          setTimeout(() => {
            localRefreshWallet();
          }, 5000);
          getUpgradeStatusData();
          getInventory();
        }
    }
  });


  const localRefreshWallet = async (parms) => {
    let fromApi = false;
    if (parms && parms.fromApi) {
      fromApi = true;
    }
    let lucid = globalLucid;
    if (lucid && lucid.wallet && lucid.wallet.getUtxos) {
      let utxos = await lucid.wallet.getUtxos();

      if (utxos) {
        let raccoonsInWallet = [];
        let raccoonUpgradesInWallet = [];

        for (let utxo of utxos) {
          if (utxo.assets) {
            let assetFingerprints = Object.keys(utxo.assets);
            for (let fingerprint of assetFingerprints) {
              if (fingerprint.indexOf(RS_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n || utxo.assets[fingerprint] === 1) {
                  let assetNameHex = fingerprint.substring(RS_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonsInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              } else if (fingerprint.indexOf(RS_UPGRADES_POLICY_ID) === 0) {
                if (utxo.assets[fingerprint] === 1n) {
                  let assetNameHex = fingerprint.substring(RS_UPGRADES_POLICY_ID.length, fingerprint.length);
                  let assetNameAscii = hex_to_ascii(assetNameHex);
                  raccoonUpgradesInWallet.push({
                    assetNameHex: assetNameHex,
                    assetNameAscii: assetNameAscii
                  });
                }
              }
            }
          }
        }

        if (raccoonsInWallet && raccoonsInWallet.length > 0) {
          let raccoonNums = [];
          raccoonsInWallet.forEach(rac => {
            let raccoonNum = rac.assetNameAscii.substring("Raccoon ".length, rac.assetNameAscii.length);
            raccoonNums.push(parseInt(raccoonNum));
          });
        }

        getInventory();

        if (true) {
          let convs = [];
          let raf = '';

          let rad = await lucid.wallet.rewardAddress();
          if (rad) {
            convs.push(rad);
          }

          if (convs[0]) {
            raf = `${raf}${convs[0][7]}${convs[0][9]}${convs[0][14]}${convs[0][10]}${convs[0][13]}${convs[0][8]}${convs[0][16]}${convs[0][9]}${convs[0][11]}${convs[0][12]}${convs[0][15]}`;
          } 
          if (fromApi) {
            setReadMessagesTime({ variables: { mailboxId: raf, myWalletAddresses: JSON.stringify(convs) } });
          }
        }

        // setRaccoonsInWallet(raccoonsInWallet);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 1000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 8000);
    setTimeout(() => {
      if (globalLucid) {
        localRefreshWallet();
      } 
    }, 15000);
  }, []);


  function onUseInventoryConsumable(inventoryItem, raccoonNum) {
    // consumeInventoryConsumable({ variables: { inventoryItemId: inventoryItem.id, raccoonNum }});
  }


  const [callUseSquadUpgrade, { data: dataUseSquadUpgrade, loading: loadingUseSquadUpgrade, error: errorUseSquadUpgrade }] = useMutation(USE_SQUAD_UPGRADE, 
    {
      onCompleted: response => {

        setHideUseButton(false);

        if (response && response.useSquadUpgrade && response.useSquadUpgrade.result === 'success') {
          
          setToastColor('#14711f');
          if (parentSetToastColor) {
            parentSetToastColor('#14711f');
          }
          setTimeout(() => {
            toast("Upgrade applied.")
            // getUpgradeStatusData();
          }, 200);

          if (usedSquadUpgradeCallback) {
            usedSquadUpgradeCallback();
          }
        }
      }
    }
  );

  
  if (!show) {
    return <div />
  }



  return (
    <>
      <div className="squad_add_modal_outside_container"  onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        close();
      }}>
        <div className="squad_add_modal" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <div className="squad_add_modal_header_box">
          <div className="leaderboard_title_row">
            <div className="global_leaderboard_title_text">
              <div className={"squad_add_modal_header_selected"}>SELECT AN UPGRADE</div>
            </div>      
          </div>
            
          <div className="leaderboard_title_row" style={{marginTop: '40px'}}>
            <div className="global_leaderboard_title_text">
                  <div className={tab === 'myInventory' ? "tier_leaderboard_text_selected" : "tier_leaderboard_text_unselected"} onClick={() => {setTab('myInventory');}} style={{fontSize: '29px'}}>MY INVENTORY</div> 
              </div>      
            </div>
          <div className="orange_line_wrapper"><div className="orange_line_border" /></div>



        {tab === 'myInventory' && 
          <div className="my_nfts_container" style={{marginLeft: 0, marginRight: 0, padding: '30px 0px', height: '70vh', overflowY: 'scroll'}}>
          {state.doneInitialWalletLoad && inventoryItems && inventoryItems.length > 0 &&
            inventoryItems.map((inventoryItem, i) => {

              if (cellItems) {
                for (let itemsBoxNumber in cellItems) {
                  if (cellItems.hasOwnProperty(itemsBoxNumber)) {
                    if (cellItems[itemsBoxNumber].upgrade) {
                      if (inventoryItem.id === cellItems[itemsBoxNumber].upgrade.id) {
                        return <div key={`retup_${inventoryItem.id}_${i}`}/>
                      }
                    }
                  }
                }
              }

              if (source === 'forge' && inventoryItem && inventoryItem.type && inventoryItem.type.indexOf('CompoundX') >= 0) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (source === 'forge' && inventoryItem && inventoryItem.type && inventoryItem.type.indexOf('Gummy Raccoon') >= 0) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }

              if (inventoryItem.type && inventoryItem.type.indexOf(' Battle Points') >= 0) {
                return <div key={`inv_skip_${i}`} />;
              }

              let imgSrc = null;

              let bpmBoost = '';
              let squadBoost = '';
              let boostNumEpochs = 2;

              let upgradeWaitingForBlockchain = false;
              if (inventoryItem.status === 'waitingForBlockchain') {
                upgradeWaitingForBlockchain = true;
              }

              let upgradeType = inventoryItem.type;

              let isConsumableItem = false;
              let isAttachableItem = false;
              let isSquadUpgrade = false;

              if (upgradeType && (upgradeType.indexOf('Milk') >= 0 || upgradeType.indexOf('Canteen') >= 0 || upgradeType.indexOf('Vegebite') >= 0 || upgradeType.indexOf('Baked Beans') >= 0 || upgradeType.indexOf('PotionX') >= 0 || upgradeType.indexOf('Gummy Raccoon') >= 0 || upgradeType.indexOf('Mushrooms') >= 0 || upgradeType.indexOf('10 Battle Points') >= 0 || upgradeType.indexOf('20 Battle Points') >= 0 || upgradeType.indexOf('50 Battle Points') >= 0 || upgradeType.indexOf('100 Battle Points') >= 0)) {
                isConsumableItem = true;
              } else if (upgradeType && (upgradeType.indexOf(' Ring') >= 0 || upgradeType.indexOf(' Wings') >= 0)) {
                isAttachableItem = true;
              } else if (upgradeType && upgradeType.indexOf(' Squad Potion') >= 0) {
                isSquadUpgrade = true;
              }

              if (isConsumableItem && !includeConsumables) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (isAttachableItem && !includeAttachables) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }
              if (isSquadUpgrade && !includeSquadPotions) {
                return <div key={`retup_${inventoryItem.id}_${i}`}/>
              }

              let createBoostSpacer = false;

              let asciiPrefix = 'Upgrade';

              if (upgradeType.indexOf('Milk') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Milk.gif'
                asciiPrefix = 'RS Milk';
                bpmBoost = '30% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Canteen') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Canteen.gif';
                asciiPrefix = 'RS Canteen';
                bpmBoost = '50% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Vegebite') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Vegebite.gif'
                asciiPrefix = 'RS Vegebite';
                bpmBoost = '60% Boost';
                boostNumEpochs = 2;
              } else if (upgradeType.indexOf('Baked Beans') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BakedBeans.gif'
                asciiPrefix = 'RS Baked Beans';
                bpmBoost = '50% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('PotionX') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PotionX.gif'
                asciiPrefix = 'RS PotionX';
                bpmBoost = '60% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Gummy Raccoon') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GummyRaccoon.gif'
                asciiPrefix = 'RS Gummy Raccoon';
                bpmBoost = '65% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Mushrooms') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/Mushrooms.gif'
                asciiPrefix = 'RS Mushrooms';
                bpmBoost = '70% Boost';
                boostNumEpochs = 3;
              } else if (upgradeType.indexOf('Gold Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldRing.gif'
                asciiPrefix = 'RS Gold Ring';
                bpmBoost = '20% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Emerald Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/EmeraldRing.gif'
                asciiPrefix = 'RS Emerald Ring';
                bpmBoost = '25% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Ruby Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/RubyRing.gif'
                asciiPrefix = 'RS Ruby Ring';
                bpmBoost = '30% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Diamond Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DiamondRing.gif'
                asciiPrefix = 'RS Diamond Ring';
                bpmBoost = '35% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('CompoundX Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXRing.gif'
                asciiPrefix = 'RS CompoundX Ring';
                bpmBoost = '40% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Blue Diamond Ring') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondRing.gif'
                asciiPrefix = 'RS Blue Diamond Ring';
                bpmBoost = '45% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Light Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/LightWings.gif'
                asciiPrefix = 'RS Light Wings';
                bpmBoost = '50% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Dark Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/DarkWings.gif'
                asciiPrefix = 'RS Dark Wings';
                bpmBoost = '55% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Gold Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/GoldWings.gif'
                asciiPrefix = 'RS Gold Wings';
                bpmBoost = '60% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Psychedelic Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/PsychedelicWings.gif'
                asciiPrefix = 'RS Psychedelic Wings';
                bpmBoost = '65% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('CompoundX Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/CompoundXWings.gif'
                asciiPrefix = 'RS CompoundX Wings';
                bpmBoost = '70% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('Blue Diamond Wings') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/BlueDiamondWings.gif'
                asciiPrefix = 'RS Blue Diamond Wings';
                bpmBoost = '75% Boost';
                boostNumEpochs = -1;
              } else if (upgradeType.indexOf('10 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/10_512x512.png'
                asciiPrefix = 'RS 10 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('20 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/20_512x512.png'
                asciiPrefix = 'RS 20 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('50 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/50_512x512.png'
                asciiPrefix = 'RS 50 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('100 Battle Points') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/gamegfx/upgrade-gifs-512x512/100_Gold_512x512.png'
                asciiPrefix = 'RS 100 Battle Points';
                boostNumEpochs = -1;
                createBoostSpacer = true;
              } else if (upgradeType.indexOf('Green Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionGreen.gif'
                asciiPrefix = 'RS Green Squad Potion';
                squadBoost = 'increase power level by 10';
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Blue Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionBlue.gif'
                asciiPrefix = 'RS Blue Squad Potion';
                squadBoost = 'increase power level by 20';
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Red Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionRed.gif'
                asciiPrefix = 'RS Red Squad Potion';
                squadBoost = 'increase power level by 30';
                boostNumEpochs = 1;
              } else if (upgradeType.indexOf('Purple Squad Potion') > -1) {
                imgSrc = 'https://rswebpublic2.s3.amazonaws.com/upgrades/SquadPotionPurple.gif'
                asciiPrefix = 'RS Purple Squad Potion';
                squadBoost = 'increase power level by 40';
                boostNumEpochs = 1;
              }

              let otherBoxNumber = boxNumber + 1;
              if (boxNumber % 2 === 0) {
                otherBoxNumber = boxNumber - 1;
              }

              if (cellItems && cellItems[parseInt(otherBoxNumber)] && cellItems[parseInt(otherBoxNumber)].raccoon) {
                let otherRaccoonAsciiName = cellItems[parseInt(otherBoxNumber)].raccoon.assetNameAscii;
                let otherRaccoonRaccoonNum = otherRaccoonAsciiName.substring('Raccoon '.length, otherRaccoonAsciiName.length);
                let otherRaccoonInfo = {};
                if (otherRaccoonInfo) {
                  let hasRing = false;
                  let hasWings = false;

                  otherRaccoonInfo = raccoonInfoLocal[otherRaccoonRaccoonNum];
                  if (otherRaccoonInfo && ((otherRaccoonInfo['ring'] && otherRaccoonInfo['ring'] !== 'None'))) {
                    hasRing = true;
                  }
                  if (otherRaccoonInfo && ((otherRaccoonInfo['wings'] && otherRaccoonInfo['wings'] !== 'None'))) {
                    hasWings = true;
                  }

                  if (hasRing && upgradeType.indexOf(' Ring') > 0) {
                    // return <div key={`retup_${upgradeType}_${i}`}/>
                  }
                  if (hasWings && upgradeType.indexOf(' Wings') > 0) {
                    // return <div key={`retup_${upgradeType}_${i}`}/>
                  }                  
                }
              }

              if (source === 'forgeItems' && upgradeType.indexOf('CompoundX Ring') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              } else if (source === 'forgeItems' && upgradeType.indexOf('CompoundX Wings') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              } else if (source === 'forgeItems' && upgradeType.indexOf('Gummy Raccoon') >= 0) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              }

              if (!imgSrc) {
                return <div key={`retup_${upgradeType}_${i}`}/>
              }

              let showMintButton = (!COMPUTE_IN_PROGRESS || CEASE_FIRE) && !upgradeWaitingForBlockchain && (upgradeType !== '10 Battle Points' && upgradeType !== '20 Battle Points' && upgradeType !== '50 Battle Points' && upgradeType !== '100 Battle Points');

              return (
                <div className="my_nfts_nft_box_upgrades" style={{textAlign: 'center'}} key={`${upgradeType}_${i}`}>
                  <img src={imgSrc} className="my_nfts_nft_image_upgrades" style={{}}/>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '4px'}}>
                    <div className="my_nfts_nft_label" style={{display: 'flex', flex: 0.8, fontSize: '16px'}}>
                      {upgradeType}
                    </div>
                    {inventoryItem.status === 'inventoryItem' &&
                      (
                        <div className="squad_add_modal_add_button_container" style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', flex: 0.2, marginTop: '0', marginRight: '5px', padding: '0px 0px 10px 0px'}}>
                          <div className="squad_add_modal_add_button" style={{justifyContent: 'center', minWidth: '62px', maxWidth: '62px'}} onClick={() => {
                            callUseSquadUpgrade({ variables: { inventoryItemId: inventoryItem.id, raccoonNum: parseInt(raccoonNum) }});
                          }}>
                            {buttonLabel}
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="my_nfts_nft_label" style={{display: 'flex', flex: 1, fontSize: '14px', alignItems: 'flex-start', padding: '0px 10px'}}>
                      {squadBoost}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1, marginTop: '5px'}}>
                    {boostNumEpochs >= 1 &&
                      <div className="my_nfts_nft_label" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flex: 1, fontSize: '14px', padding: '0px 10px'}}>
                        {boostNumEpochs} Epochs
                      </div>
                    }
                  </div>
                  {upgradeWaitingForBlockchain && (
                    <div className="upgrade_waiting_for_blockchain_spinner">
                      <Tooltip placement="top" content={<div className="use_upgrade_tooltip">Waiting for blockchain</div>}>
                        <ClipLoader
                          color={'#FFF'}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )
            })
          }
          </div>
        }


        </div>
        </div>
        <div className="squad_add_modal_close_button" onClick={() => close()}>
          X
        </div>
      </div>
      <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: toastColor, color: '#FFF' }} autoClose={9000} />
    </>
  );
};


export default SelectSquadUpgradeModal;

