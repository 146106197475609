import React, {useState, useEffect} from 'react';
import {useLazyQuery, gql} from '@apollo/client';
import Link from './Link';
import dayjs from "dayjs";

const BattleRoundCountdown = (props) => {
  const {unixtime} = props;

  const [daysDisplay, setDaysDisplay] = useState(null);
  const [hoursDisplay, setHoursDisplay] = useState(null);
  const [minutesDisplay, setMinutesDisplay] = useState(null);
  const [secondsDisplay, setSecondsDisplay] = useState(null);

  useEffect(()=>{
    let interval = setInterval(() => {
      let nowDayJS = dayjs();
      let targetDyJS = dayjs.unix(unixtime);
    
      let diffDays = targetDyJS.diff(nowDayJS, 'days');
      let diffHours = targetDyJS.diff(nowDayJS, 'hour');
      let diffMinutes = targetDyJS.diff(nowDayJS, 'minute');
      let diffSeconds = targetDyJS.diff(nowDayJS, 'second');
    
      let diffDaysFormatted = diffDays;
      let diffHoursFormatted = diffHours - diffDays * 24;
      let diffMinutesFormatted = diffMinutes - diffHours * 60;
      let diffSecondsFormatted = diffSeconds - diffMinutes * 60;

      setDaysDisplay(`${diffDaysFormatted}`.padStart(2, '0'));
      setHoursDisplay(`${diffHoursFormatted}`.padStart(2, '0'));
      setMinutesDisplay(`${diffMinutesFormatted}`.padStart(2, '0'));
      setSecondsDisplay(`${diffSecondsFormatted}`.padStart(2, '0'));

    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (daysDisplay === null || hoursDisplay === null || minutesDisplay === null || secondsDisplay === null) {
    return <div />;
  }

  return (
    <>
      <div className="battle_round_countdown_container">
        <div className="battle_round_countdown_molecule">
          <div className="battle_round_countdown_number_row">
            <div className="home_battle_round_number">{daysDisplay[0]}</div>
            <div className="home_battle_round_number">{daysDisplay[1]}</div>
          </div>
          <div className="home_battle_round_time_text">DAYS</div>
        </div>
        <div className="battle_round_countdown_molecule">
          <div className="battle_round_countdown_number_row">
            <div className="home_battle_round_number">{hoursDisplay[0]}</div>
            <div className="home_battle_round_number">{hoursDisplay[1]}</div>
          </div>
          <div className="home_battle_round_time_text">HRS</div>
        </div>
        <div className="battle_round_countdown_molecule">
          <div className="battle_round_countdown_number_row">
            <div className="home_battle_round_number">{minutesDisplay[0]}</div>
            <div className="home_battle_round_number">{minutesDisplay[1]}</div>
          </div>
          <div className="home_battle_round_time_text">MIN</div>
        </div>        
        <div className="battle_round_countdown_molecule">
          <div className="battle_round_countdown_number_row">
            <div className="home_battle_round_number">{secondsDisplay[0]}</div>
            <div className="home_battle_round_number">{secondsDisplay[1]}</div>
          </div>
          <div className="home_battle_round_time_text">SEC</div>
        </div>          
      </div>
    </>
  );
};

export default BattleRoundCountdown;